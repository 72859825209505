export const USER_TYPES = {
  LOAD_USER: "LOAD_USER",
  GET_USERS: "GET_USERS",
  GET_USER: "GET_USER",
  UPDATE_USER_DATA: "UPDATE_USER_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  item: {
    email: "",
    phone: "",
    password: "",
    firstname: "",
    lastname: "",
    role: ""
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_TYPES.LOAD_USER:
      return {
        ...state,
        isLoading: action.payload
      };
    case USER_TYPES.GET_USERS:
      return {
        ...state,
        items: action.payload,
        item: initialState.item,
        isLoading: false
      };

    case USER_TYPES.GET_USER:
      return {
        ...state,
        item: {...state.item, ...action.payload},
        isLoading: false
      };

    case USER_TYPES.UPDATE_USER_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
