import { atom, useAtom } from "jotai";
import { userAccessEnum } from "../../config/accessEnum";
import useStore from "../../context/useStore";

const userPermissionsAtom = atom([
  userAccessEnum.CHERCHER_UTILISATEUR,
  userAccessEnum.CREER_UTILISATEUR,
  userAccessEnum.MODIFIER_UTILISATEUR,
  userAccessEnum.SUPPRIMER_UTILISATEUR,

  userAccessEnum.CHERCHER_GROUPE_UTILISATEUR,
  userAccessEnum.CREER_GROUPE_UTILISATEUR,
  userAccessEnum.MODIFIER_GROUPE_UTILISATEUR,

  userAccessEnum.CHERCHER_MARQUE_VEHICULE,
  userAccessEnum.CREER_MARQUE_VEHICULE,
  userAccessEnum.MODIFIER_MARQUE_VEHICULE,

  userAccessEnum.CHERCHER_MODELE_VEHICULE,
  userAccessEnum.CREER_MODELE_VEHICULE,
  userAccessEnum.MODIFIER_MODELE_VEHICULE,

  userAccessEnum.CHERCHER_FINITION_VEHICULE,
  userAccessEnum.CREER_FINITION_VEHICULE,
  userAccessEnum.MODIFIER_FINITION_VEHICULE,

  userAccessEnum.MODIFIER_CONSTANTE_DYNAMIQUE,

  //   userAccessEnum.CHERCHER_CONTRAT,

  userAccessEnum.CHERCHER_DOSSIER,
  userAccessEnum.CREER_DOSSIER,
  userAccessEnum.MODIFIER_DOSSIER,
  //   userAccessEnum.SUPPRIMER_DOSSIER,
  userAccessEnum.MODIFIER_STATUT_LOI_DE_ROULAGE,

  userAccessEnum.CHERCHER_TABLEAU_ENTRETIEN,
  userAccessEnum.CREER_TABLEAU_ENTRETIEN,
  userAccessEnum.MODIFIER_TABLEAU_ENTRETIEN,

  userAccessEnum.CHERCHER_VEHICULE_CATALOGUE,
  userAccessEnum.CREER_VEHICULE_CATALOGUE,
  userAccessEnum.MODIFIER_VEHICULE_CATALOGUE,
  //   userAccessEnum.SUPPRIMER_VEHICULE_CATALOGUE,
  //   userAccessEnum.MODIFIER_VISIBILITE_VEHICULE_CATALOGUE,

  userAccessEnum.CHERCHER_CATALOGUE,
  userAccessEnum.CREER_CATALOGUE,
  userAccessEnum.MODIFIER_CATALOGUE,

  userAccessEnum.CHERCHER_VALEUR_RACHAT,
  userAccessEnum.CREER_VALEUR_RACHAT,
  userAccessEnum.MODIFIER_VALEUR_RACHAT,
  userAccessEnum.AJOUTER_PERIODE_VALEUR_RACHAT,

  userAccessEnum.CHERCHER_PNEU,
  userAccessEnum.CREER_PNEU,
  userAccessEnum.MODIFIER_PNEU,
  //   userAccessEnum.SUPPRIMER_PNEU,

  userAccessEnum.CHERCHER_CLIENT,
  userAccessEnum.CREER_CLIENT,
  userAccessEnum.MODIFIER_CLIENT,
  // userAccessEnum.SUPPRIMER_CLIENT,

  userAccessEnum.CHERCHER_FLOTTE_VEHICULE,
  userAccessEnum.MODIFIER_FLOTTE_VEHICULE,
]);

const useAccessSettings = () => {
  const [state] = useStore();
  const userPermissions = state.auth.user.permissions;

  let authorizations = {};

  if (userPermissions) {
    Object.keys(userAccessEnum).forEach((access) => {
      let entry = userAccessEnum[access];
      authorizations[entry] = () => ({
        granted: userPermissions.includes(entry),
      });
    });
  }

  const accessActions = {
    canI: ({ action }) => {
      if (action === "*") {
        return { granted: true };
      }
      if (typeof action === "string") {
        if (authorizations[action]) return authorizations[action]();
      }
      if (typeof action === "object") {
        for (var key in action) {
          if (authorizations[action[key]]) {
            let res = authorizations[action[key]]();
            if (res.granted) {
              return { granted: true };
            }
          }
        }
        return { granted: false };
      }
      return { granted: false };
    },
  };

  return {
    accessActions,
  };
};

export default useAccessSettings;
