import React, { useState } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Loader from "../../common/Loader";

const isDev = process.env.NODE_ENV === "development";

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [credentials, setCredentials] = useState({
    login: isDev ? "joan@10positif.com" : "",
    password: isDev ? "Test1234!" : "",
  });

  function onInputChange(e) {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        actions.loginUser(credentials);
      }}
      className="card m-auto col-6 px-0"
      style={{
        maxWidth: 400,
        position: "relative",
        top: 150,
        boxShadow: "0px 3px 7px -2px grey",
      }}
    >
      <div className="text-center pl-4 pr-4 pt-4 pb-0">
        <img className="img-fluid" src="/logo.png" />
        <h2>MATCH!</h2>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            autoFocus
            className="form-control"
            name="login"
            id="email"
            value={credentials.login}
            onChange={onInputChange}
          />
          <div className="form-text text-danger mb-2">{state.error.login}</div>
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="password"
            value={credentials.password}
            onChange={onInputChange}
          />
          <div className="form-text text-danger mb-2">
            {state.error.password}
          </div>
        </div>
      </div>
      <div className="card-footer text-center">
        {state.error.message && (
          <div className="form-text text-danger mb-2">
            {state.error.message}
          </div>
        )}

        {state.auth.isLoading ? (
          <Loader />
        ) : (
          <button type="submit" className="btn btn-primary m-auto">
            Connexion
          </button>
        )}
      </div>
    </form>
  );
};

export default Login;
