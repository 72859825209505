import tools from "../../helpers/tools";
import { CONSTANT_TYPES } from "../reducers/constantReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { constantService } from "../../services/constantService";
import constantAdapters from "../../adapters/constantAdapters";
import { toast } from "react-toastify";

export const constantActions = (state, dispatch) => {
  function getConstants() {
    dispatch({ type: CONSTANT_TYPES.LOAD_CONSTANT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    constantService
      .getAllConstants()
      .then((res) => {
        const dynamicConstants = constantAdapters.transformDynamicForBO(
          res[0].data
        );
        const labels = constantAdapters.getLabelsFromDynamic(res[0].data);
        const staticConstants = constantAdapters.transformStaticForBO(
          res[1].data
        );
        const allConstants = { ...dynamicConstants, ...staticConstants };

        dispatch({
          type: CONSTANT_TYPES.GET_CONSTANTS,
          payload: { all: allConstants, dynamic: dynamicConstants, labels },
        });
      })
      .catch((err) => {
        toast.error(
          `Une erreur est survenue au chargement des constantes : ${err.response.data?.message}}`
        );
        tools.filterError(
          dispatch,
          err.response.data,
          CONSTANT_TYPES.LOAD_CONSTANT
        );
      });
  }
  function updateConstant(constantData) {
    dispatch({ type: CONSTANT_TYPES.LOAD_CONSTANT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    constantService
      .updateConstants(constantData)
      .then((res) => {
        getConstants();
        tools.filterAlert(
          "success",
          "Les constantes ont été correctement mis à jour."
        );
        window.location.reload();
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONSTANT_TYPES.LOAD_CONSTANT
        );
      });
  }

  return {
    getConstants,
    updateConstant,
  };
};
