import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { repurchaseActions } from "../../../context/actions/repurchaseActions";
import Loader from "../../common/Loader";
import SelectDateModal from "../Maintenance/SelectDateModal";
import { DateTime } from "luxon";
import CustomPagination from "../../common/CustomPagination";
import CustomList from "../../common/CustomList";
import RepurchaseSearch from "./RepurchaseSearch/RepurchaseSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import { useAtom } from "jotai";
import { repurchaseSearchAtom } from "./RepurchaseSearch/useRepurchaseSearch";

const RepurchaseList = (props) => {
  const [state, dispatch] = useStore();
  const [selectUuid, setSelectUuid] = useState(null);
  const [repurchaseSearch, setRepurchaseSearch] = useAtom(repurchaseSearchAtom);

  var repurchaseData = state.repurchase.items;

  var actions = repurchaseActions(state, dispatch, props);

  function createNewPeriod(date) {
    actions.addPeriodRepurchase(selectUuid, date);
  }

  useEffect(() => {
    actions.getRepurchases(repurchaseSearch);
  }, []);

  return (
    <CustomList getAction={actions.getRepurchases}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Valeurs de rachat</h1>
              {state.repurchase.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_VALEUR_RACHAT}>
                  <Link className="btn btn-primary" to="/repurchases/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un tableau
                  </Link>
                </Can>
              )}
              <RepurchaseSearch
                handlePageParamsChange={actions.getRepurchases}
              />
              <NbSearchResults total={state.repurchase.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèles</th>
                    <th>Carburants</th>
                    <th>Début validité</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {repurchaseData.map((repurchase, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>
                        {repurchase.brand ? (
                          repurchase.brand.name
                        ) : (
                          <span className="text-danger">
                            Erreur, la marque est absente{" "}
                          </span>
                        )}
                      </td>
                      <td>
                        {repurchase.brandModels.length > 0 ? (
                          repurchase.brandModels.map(
                            (bm, k) =>
                              bm.name +
                              (k == repurchase.brandModels.length - 1
                                ? ""
                                : ", ")
                          )
                        ) : (
                          <span className="text-danger">
                            Erreur, aucun modèle trouvé
                          </span>
                        )}
                      </td>
                      <td>
                        {repurchase.fuelTypes
                          .map((ft, k) => ft.name)
                          .join(", ")}
                      </td>
                      <td>
                        {DateTime.fromJSDate(
                          new Date(repurchase.validFrom)
                        ).toFormat("dd/MM/yyyy")}
                        {repurchase.validTo &&
                          repurchase.validTo != "" &&
                          ` - ${DateTime.fromJSDate(
                            new Date(repurchase.validTo)
                          ).toFormat("dd/MM/yyyy")}`}
                      </td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_VALEUR_RACHAT}>
                          <Link
                            className="btn btn-secondary mr-2"
                            to={"/repurchases/" + repurchase.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                        <Can I={userAccessEnum.AJOUTER_PERIODE_VALEUR_RACHAT}>
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            data-toggle="modal"
                            data-target="#selectDateModal"
                            onClick={() => setSelectUuid(repurchase.uuid)}
                          >
                            Ajouter une nouvelle période
                          </button>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.repurchase.total}
                activePage={repurchaseSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getRepurchases({ ...repurchaseSearch, ...data });
                  setRepurchaseSearch({ ...repurchaseSearch, ...data });
                }}
              />
            </div>
          </div>
          <SelectDateModal createNewPeriod={createNewPeriod} />
        </div>
      )}
    </CustomList>
  );
};

export default RepurchaseList;
