import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { catalogActions } from "../../../context/actions/catalogActions";

import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config/config";
import { toast } from "react-toastify";
import { CATALOG_TYPES } from "../../../context/reducers/catalogReducers";
import { brandActions } from "../../../context/actions/brandActions";
import CatalogSearch from "./CatalogSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import useTVA from "../../../hooks/useInit/useTVA";
import { useAtom } from "jotai";
import { catalogSearchAtom } from "./useCatalogSearch";

const CatalogList = (props) => {
  const [state, dispatch] = useStore();

  var actions = catalogActions(state, dispatch);
  var b_actions = brandActions(state, dispatch);
  const [catalogSearch, setCatalogSearch] = useAtom(catalogSearchAtom);

  function hideItem(item, isHidden) {
    var it = JSON.parse(JSON.stringify(item));
    it.isHidden = isHidden;

    Axios.patch(api_url + "catalogs-vehicles/" + it.uuid + "/visibility", {
      isHidden: isHidden,
    })
      .then((res) => {
        var cat = JSON.parse(JSON.stringify(state.catalog.items));
        cat = cat.map((c) => {
          if (c.uuid == it.uuid) {
            c.isHidden = isHidden;
          }
          return c;
        });

        dispatch({
          type: CATALOG_TYPES.GET_CATALOGS,
          payload: { data: cat, count: state.catalog.total },
        });
        //actions.getCatalogs();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur est survenue");
        if (err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
      });
  }

  const { getTVAByCatalog } = useTVA();

  function removeCatalog(id) {
    if (window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?"))
      Axios.delete(api_url + "catalogs-vehicles/" + id)
        .then((res) => {
          actions.getCatalogs();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            alert(err.response.data.message);
          }
        });
  }

  useEffect(() => {
    b_actions.getAllBrands();
    actions.getCatalogs(catalogSearch);
  }, []);

  return (
    <CustomList getAction={actions.getCatalogs}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Véhicules de catalogue</h1>}

              {state.catalog.isLoading ? (
                <Loader />
              ) : (
                !props.modal && (
                  <Can I={userAccessEnum.CREER_VEHICULE_CATALOGUE}>
                    <Link className="btn btn-primary" to="/catalogs/create">
                      <i className="fa fa-plus mr-2" /> Ajouter un véhicule au
                      catalogue
                    </Link>
                  </Can>
                )
              )}
              <CatalogSearch
                handlePageParamsChange={actions.getCatalogs}
                openPresearch={props.modal}
              />
              <NbSearchResults total={state.catalog.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Catalogue</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Type</th>
                    <th>Finition</th>
                    <th>Options</th>
                    <th>Prix public</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.catalog.items.map((catalog, k) => {
                    let displayPriceTTC = catalog.priceTTC;
                    const { TVA, TVA_INVERT } = getTVAByCatalog(
                      catalog.catalog.uuid
                    );
                    let displayPriceOptions =
                      catalog.optionsNotIncluded?.reduce(
                        (a, b) =>
                          a + b.priceHT * (b.isInclTVA ? TVA_INVERT : 1),
                        0
                      );
                    displayPriceOptions =
                      Math.round(displayPriceOptions * 100) / 100;

                    return props.modal &&
                      catalog.isHidden &&
                      !state.auth.user.role == "SUPER_ADMIN" ? (
                      <tr
                        key={"brand-row-" + k}
                        style={{ display: "none" }}
                      ></tr>
                    ) : (
                      <tr
                        key={"brand-row-" + k}
                        style={catalog.isHidden ? { opacity: "0.7" } : {}}
                      >
                        <td>{catalog.catalog.name}</td>
                        <td>
                          {catalog.brand.name}{" "}
                          {catalog.isHidden ? "(caché)" : ""}
                        </td>
                        <td>{catalog.brandModel?.name}</td>
                        <td>{catalog.type.name}</td>
                        <td>{catalog.brandModelFinish.name}</td>
                        <td>
                          {catalog.optionsNotIncluded?.map((o, k) => (
                            <span
                              key={"options_" + catalog.uuid + "_" + k}
                              className="mr-2 badge badge-primary"
                            >
                              {o.name}
                            </span>
                          ))}
                        </td>
                        <td>
                          {Math.round(displayPriceTTC * 100) / 100} € <br />
                          {catalog.optionsNotIncluded?.length > 0 &&
                            "Options: " + displayPriceOptions + " €"}
                         
                            <>
                              <br />
                              <span className={catalog.malusBonusEco > 0 ? "text-danger" : "text-success"}>
                                {catalog.malusBonusEco > 0 ? "Malus: " : "Bonus: "}
                                {catalog.malusBonusEco ? Math.abs(catalog.malusBonusEco):0} €
                              </span>
                            </>
                        </td>
                        <td>
                          {!props.modal ? (
                            <>
                              <Can
                                I={userAccessEnum.MODIFIER_VEHICULE_CATALOGUE}
                              >
                                <Link
                                  className="btn btn-secondary"
                                  to={"/catalogs/" + catalog.uuid}
                                >
                                  Modifier
                                </Link>
                              </Can>
                              <Can
                                I={
                                  userAccessEnum.MODIFIER_VISIBILITE_VEHICULE_CATALOGUE
                                }
                              >
                                <>
                                  {catalog.isHidden ? (
                                    <button
                                      className="btn btn-default btn-sm ml-2"
                                      onClick={() => hideItem(catalog, false)}
                                    >
                                      Visible
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-default btn-sm ml-2"
                                      onClick={() => hideItem(catalog, true)}
                                    >
                                      Masquer
                                    </button>
                                  )}
                                </>
                              </Can>
                              <Can
                                I={userAccessEnum.SUPPRIMER_VEHICULE_CATALOGUE}
                              >
                                <button
                                  title="Supprimer ce véhicule"
                                  className="btn btn-danger ml-2  btn-sm"
                                  onClick={() => removeCatalog(catalog.uuid)}
                                >
                                  <i className="fa fa-times" />
                                </button>
                              </Can>
                            </>
                          ) : (
                            <a
                              href="#"
                              className="btn btn-secondary"
                              onClick={() => props.selectCatalog(catalog)}
                            >
                              Choisir
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <CustomPagination
                total={state.catalog.total}
                activePage={catalogSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getCatalogs({ ...catalogSearch, ...data });
                  setCatalogSearch({ ...catalogSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default CatalogList;
