import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { FINANCE_TYPES } from "../reducers/financeReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { financeService } from "../../services/financeService";
import financeAdapters from "../../adapters/financeAdapters";

export const financeActions = (state, dispatch, props) => {
  function getFinances(search = { search: null }) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    financeService
      .getFinances(search)
      .then((res) => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }
  function getFinance(uuid) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financeService
      .getFinance(uuid)
      .then((res) => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: financeAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }
  function createFinance(financeData) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financeService
      .createFinance(financeData)
      .then((res) => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: financeAdapters.transformForBO(res.data),
        });
        tools.filterAlert("success", "Le financement a été créé.");
        props.history.push("/finances/" + res.data.uuid);
      })
      .catch((err) => {
        console.log(err);
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }

  function updateFinance(financeData) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financeService
      .updateFinance(financeData)
      .then((res) => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: financeAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          "Le financement a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }

  return {
    createFinance,
    updateFinance,
    getFinances,
    getFinance,
  };
};
