import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { CLIENT_TYPES } from "../reducers/clientReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import FileDownload from "js-file-download";
import { clientService } from "../../services/clientService";
import clientAdapters from "../../adapters/clientAdapters";

export const clientActions = (state, dispatch, props) => {
  function getClients(search = { search: null }) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.name,
      page: search.page,
    });

    clientService
      .getClients(search)
      .then((res) => {
        dispatch({ type: CLIENT_TYPES.GET_CLIENTS, payload: res.data });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response?.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function getClient(uuid) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    clientService
      .getClient(uuid)
      .then((res) => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: clientAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response?.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function createClient(clientData) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    clientService
      .createClient(clientData)
      .then((res) => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: clientAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          clientData.name + " a été correctement créé."
        );
        if (props.modal) {
          props.selectClient(res.data);
        } else {
          props.history.push("/clients/" + res.data.uuid);
        }
      })
      .catch((err) => {
        console.log("err", err);
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function updateClient(clientData) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    clientService
      .updateClient(clientData)
      .then((res) => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: clientAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          clientData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function getClientCGV(client) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios({
      url: api_url + "clients/" + client.uuid + "/cgv",
      method: "GET",
      responseType: "blob",
    })
      .then((res) => FileDownload(res.data, client.name + "_CGV.pdf"))
      .then((res) =>
        dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: false })
      )
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }

  return {
    createClient,
    updateClient,
    getClients,
    getClient,
    getClientCGV,
  };
};
