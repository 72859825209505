import React, { useState } from "react";
import Control from "./Control";

const ExcelCopyModal = ({ formatExcelData, showOptionTTC = false }) => {
  var [excelData, setExcelData] = useState("");
  var [orientation, setOrientation] = useState(1);
  var [isTTC, setIsTTC] = useState(false);

  return (
    <div
      className="modal fade"
      id="excelCopyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Copier un tableau de valeurs depuis excel
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="excelData">
                Collez le tableau excel ci-dessous
              </label>
              <span className="text-danger">
                Les valeurs des cellules du tableau doivent être des nombres
                sans espace (ex: 36000)
              </span>
              <textarea
                className="form-control"
                id="excelData"
                name="excelData"
                rows="6"
                value={excelData}
                onChange={(e) => setExcelData(e.target.value)}
              />
              <div className="form-group mt-2">
                <label className="mr-2">
                  Le nombre de kilomètres est représenté en :{" "}
                </label>
                <div className="btn-group">
                  <button
                    className={
                      "btn " + (orientation == 1 ? "btn-primary" : "btn-light")
                    }
                    onClick={() => setOrientation(1)}
                  >
                    colonne
                  </button>
                  <button
                    className={
                      "btn " + (orientation == 2 ? "btn-primary" : "btn-light")
                    }
                    onClick={() => setOrientation(2)}
                  >
                    ligne
                  </button>
                </div>
              </div>
              {showOptionTTC && (
                <Control
                  label="Les prix sont-il en TTC ?"
                  name="isTTC"
                  type="checkbox"
                  checked={isTTC}
                  change={() => setIsTTC(!isTTC)}
                />
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => formatExcelData(excelData, orientation, isTTC)}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelCopyModal;
