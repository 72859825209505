import axios from "axios";
import { api_url } from "../config/config";
import constantAdapters from "../adapters/constantAdapters";

export const constantService = {
  getAllConstants: () => {
    const promises = [
      axios.get(`${api_url}dynamics-constants/search?paginate=0`),
      axios.get(`${api_url}constants`),
    ];
    return axios.all(promises);
  },
  updateConstants: (data) => {
    return axios.put(
      `${api_url}dynamics-constants`,
      constantAdapters.transformForAPI(data)
    );
  },
};
