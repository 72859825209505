import axios from "axios";
import { api_url } from "../config/config";
import brandAdapters from "../adapters/brandAdapters";

export const brandService = {
  getAllBrands: () => {
    return axios.get(`${api_url}brands/search?paginate=0`);
  },
  getBrands: (search) => {
    return axios.get(`${api_url}brands/search${search}`);
  },
  getBrand: (uuid) => {
    return axios.get(`${api_url}brands/${uuid}`);
  },
  createBrand: (data) => {
    return axios.post(`${api_url}brands`, brandAdapters.transformForAPI(data));
  },
  updateBrand: (data) => {
    return axios.put(
      `${api_url}brands/${data.uuid}`,
      brandAdapters.transformForAPI(data)
    );
  },
};
