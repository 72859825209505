import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { financeActions } from "../../../context/actions/financeActions";
import { quotationActions } from "../../../context/actions/quotationActions";
import { FINANCE_TYPES } from "../../../context/reducers/financeReducers";
import financeTools from "../../../helpers/financeTools";
import Control from "../../common/Control";
import tools from "../../../helpers/tools";
import ValidQuotationsList from "./ValidQuotationsList";
import FinanceTab from "./FinanceTab";
import Loader from "../../common/Loader";
import PaymentTable from "./PaymentTable";
import { DateTime } from "luxon";
import { financierActions } from "../../../context/actions/financierActions";
import QuotationSearch from "../Quotation/QuotationSearch/QuotationSearch";
import CustomList from "../../common/CustomList";
import { QUOTATION_TYPES } from "../../../context/reducers/quotationReducers";
import StatusBadge from "../../common/StatusBadge/StatusBadge";

const FinanceDetail = (props) => {
  const [state, dispatch] = useStore();
  var [selectedTab, setSelectedTab] = useState(0);

  var f_actions = financeActions(state, dispatch, props);
  var q_actions = quotationActions(state, dispatch, props);
  const financier_actions = financierActions(state, dispatch, props);
  const [searchedQuotationUuids, setSearchedQuotationUuids] = useState([]);
  var financeData = state.finance.item;
  var quotationData = state.quotation.items;
  var constantData = state.constant.items;
  const financierData = state.financier.items;
  const isNew = props.match.params.uuid == "create";

  const quotationUuidsToSearch =
    financeData?.quotations.map((q) => q.quotation).filter((q) => q) || [];
  const quotationUuidsNotSearched =
    quotationUuidsToSearch.filter(
      (quuid) =>
        !quotationData
          .flatMap((item) => item.quotations.map((quotation) => quotation.uuid))
          .includes(quuid)
    ) || [];

  useEffect(() => {
    if (
      quotationUuidsNotSearched.length > 0 &&
      financeData?.uuid &&
      !state.quotation.isLoading
    ) {
      const sortedArr1 = searchedQuotationUuids.slice().sort(); // Create a sorted copy of arr1
      const sortedArr2 = quotationUuidsNotSearched.slice().sort();

      if (sortedArr1.toString() === sortedArr2.toString()) {
      } else {
        setSearchedQuotationUuids([
          ...searchedQuotationUuids,
          ...quotationUuidsNotSearched,
        ]);
        onSearchValidQuotations();
      }
    }
  }, [quotationUuidsNotSearched]);

  useEffect(() => {
    financier_actions.getAllFinanciers();
    dispatch({
      type: QUOTATION_TYPES.RESET_QUOTATION,
    });
    if (!isNew) {
      f_actions.getFinance(props.match.params.uuid);
    }
  }, []);

  function onSearchValidQuotations(search = {}) {
    if (!isNew) {
      search = { ...search, quotationDetails: quotationUuidsToSearch };
    }
    q_actions.getValidQuotations(search);
  }
  function inputChange(e) {
    tools.inputChange(e, FINANCE_TYPES.UPDATE_FINANCE_DATA, dispatch);
  }
  function changeQuotation(e, q) {
    var { quotations } = financeData;
    if (e.target.checked) {
      var newQuot = {
        quotation: q.uuid,
        reference: q.reference,
        periods: [
          {
            num: 1,
            dBegin: DateTime.local().toFormat("yyyy-MM-dd"),
            dEnd: DateTime.local()
              .plus({ months: q.dur })
              .toFormat("yyyy-MM-dd"),
            duration: q.dur,
            monthPayment: q.financeRent,
            monthPaymentReal: 0,
            vr: q.repurchaseValueFinal,
          },
        ],
        residualValue: 0,
      };
      quotations.push(newQuot);
    } else {
      quotations = quotations.filter((qs) => qs.quotation !== q.uuid);
    }
    var totalEnvelop = financeTools.calcEnvelop(quotations, quotationData);

    dispatch({
      type: FINANCE_TYPES.UPDATE_FINANCE_DATA,
      payload: {
        key: "totalEnvelop",
        value: totalEnvelop,
      },
    });
    dispatch({
      type: FINANCE_TYPES.UPDATE_FINANCE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function addPeriod(index) {
    var { quotations } = financeData;
    if (
      quotations[index].periods[quotations[index].periods.length - 1]
        .duration == ""
    ) {
      alert("Veuillez d'abord renseigner une durée pour la période précédente");
      return false;
    }
    var num =
      quotations[index].periods[quotations[index].periods.length - 1].num + 1;

    quotations[index].periods.push({
      num,
      dBegin:
        quotations[index].periods[quotations[index].periods.length - 1].dEnd,
      dEnd: "",
      duration: "",
      monthPayment: "",
      monthPaymentReal: "",
      residualValue: "0",
      vr: 0,
    });

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function removePeriod(index, k) {
    var { quotations } = financeData;

    quotations[index].periods = quotations[index].periods.filter(
      (p, pk) => pk !== k
    );
    var c = 1;
    quotations[index].periods = quotations[index].periods.map((p) => {
      p.num = c;
      c++;
      return p;
    });

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function inputPeriodChange(e, index, k) {
    var { quotations } = financeData;
    quotations[index].periods[k][e.target.name] = e.target.value;

    if (e.target.name == "dBegin" || e.target.name == "duration") {
      quotations[index].periods[k]["dEnd"] = financeTools.dateEndFromStart(
        quotations[index].periods[k].dBegin,
        quotations[index].periods[k].duration
      );

      var i = k == 0 ? 1 : k;
      while (quotations[index].periods[k + i]) {
        quotations[index].periods[k + i].dBegin =
          quotations[index].periods[i - 1 >= 0 ? i - 1 : 0]["dEnd"];
        quotations[index].periods[k + i]["dEnd"] =
          financeTools.dateEndFromStart(
            quotations[index].periods[k + i].dBegin,
            quotations[index].periods[k + i].duration
          );
        i++;
      }
    }

    quotations = financeTools.recalcPeriods(quotations, quotationData);

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function setStatusValid() {
    if (
      window.confirm(
        "Êtes-vous certain de vouloir passer ce financement en validé ?"
      )
    ) {
      var fin = state.finance.item;
      fin.status = 2;
      f_actions.updateFinance(fin);
    }
  }

  if (financierData.length == 0) return <Loader />;

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12">
            <h1>Édition d'un financement</h1>
          </div>
          <div className="col-12 mb-4">
            <div className="form-group">
              <label>Référence</label>
              <div>{financeData.reference}</div>
            </div>
            <div className="form-group">
              <label>Etat</label>

              <StatusBadge
                statusId={financeData.status.id}
                statusName={financeData.status.name}
              />

              {financeData.uuid && financeData.status == 1 && (
                <button
                  className=" ml-2 btn btn-sm btn-success"
                  onClick={setStatusValid}
                >
                  Passer en état validé
                </button>
              )}
            </div>

            <div className="form-group">
              <label>
                Financeur (total investissement = {financeData.totalEnvelop} €){" "}
                {financeData.financier &&
                financeData.financier != "" &&
                financeData.status != 2 &&
                financeData.totalEnvelop >
                  financierData.find((f) => f.uuid == financeData.financier)
                    .envelop ? (
                  <span>
                    <i
                      className="fa fa-exclamation-triangle mx-2"
                      style={{ color: "red" }}
                    ></i>
                    Supérieur à l'enveloppe disponible pour ce financeur
                  </span>
                ) : (
                  ""
                )}
              </label>
              <select
                className="form-control"
                name="financier"
                onChange={inputChange}
                value={financeData.financier}
                disabled={financeData.status == 2}
              >
                {financierData.map((fin, k) => (
                  <option
                    key={"fin" + k}
                    value={fin.uuid}
                    style={{
                      color:
                        financeData.totalEnvelop > fin.envelop ||
                        fin.envelop <= 0
                          ? "red"
                          : "green",
                    }}
                  >
                    {fin.name} - {fin.envelop} €
                  </option>
                ))}
              </select>
              <small className="form-text text-danger">
                {state.error.financier}
              </small>
            </div>
            <Control
              label="Type de crédit"
              type="select"
              name="financeType"
              value={financeData.financeType}
              change={inputChange}
              datas={constantData.FINANCE_TYPES}
              error={state.error}
              disabled={financeData.status == 2}
            />
            <CustomList getAction={onSearchValidQuotations} noFirstLoad>
              {(activePage, handlePageParamsChange, search) => {
                return (
                  <div className="container-fluid  bg-white py-3 border rounded">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h4 className="mb-0">Chercher des dossiers</h4>
                        <QuotationSearch
                          handlePageParamsChange={onSearchValidQuotations}
                          search={search}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <h4 className="mb-0">Sélectionner des devis</h4>
                        {state.quotation.isLoading && (
                          <div>
                            <Loader />
                          </div>
                        )}
                        <div className="py-3">
                          <ValidQuotationsList
                            changeQuotation={changeQuotation}
                            quotations={financeData.quotations}
                            quotationData={quotationData}
                            disabled={financeData.status == 2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </CustomList>
          </div>
          {quotationData.length > 0 && financeData.quotations.length > 0 && (
            <div className="col-12 mb-4">
              <ul className="nav nav-tabs">
                {financeData.quotations.map((q, k) => {
                  const financeObj = financeTools.getQuotationObj(
                    q.quotation,
                    quotationData
                  );
                  return (
                    <li className="nav-item" key={"tab" + k}>
                      <div
                        className={`d-flex align-items-center justify-content-start
                    nav-link  p-0 ${k == selectedTab ? "active" : ""}
                    `}
                      >
                        <a
                          className={
                            "nav-link border-0 " +
                            (k == selectedTab ? "active" : "")
                          }
                          id={"tab" + q.quotation}
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls={q.quotation}
                          aria-selected="true"
                          onClick={() => setSelectedTab(k)}
                          style={{ textTransform: "none" }}
                        >
                          {financeObj ? (
                            <>
                              {financeObj?.reference} (x{financeObj.quantity})
                            </>
                          ) : (
                            <span className="text-danger">
                              Erreur : Ce devis n'existe plus
                            </span>
                          )}
                        </a>
                        <button
                          className="btn text-danger border-0 btn-sm"
                          onClick={() => {
                            changeQuotation(
                              { target: { checked: false } },
                              { ...q, uuid: q.quotation }
                            );
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div
                className="tab-content p-3"
                style={{
                  background: "white",
                  border: "1px solid #dee2e6",
                  borderTop: 0,
                }}
              >
                {financeData.quotations.map((q, k) => (
                  <FinanceTab
                    quotation={q}
                    key={"tq" + q.uuid}
                    selectedTab={selectedTab}
                    index={k}
                    inputPeriodChange={inputPeriodChange}
                    addPeriod={addPeriod}
                    removePeriod={removePeriod}
                    disabled={financeData.status == 2}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="col-12 mb-4">
            <PaymentTable quotations={quotationData} finances={financeData} />
          </div>
          <div className="col-12">
            {state.quotation.isLoading ? (
              <Loader />
            ) : props.match.params.uuid == "create" ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => f_actions.createFinance(state.finance.item)}
              >
                Créer
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => f_actions.updateFinance(state.finance.item)}
              >
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(FinanceDetail);
