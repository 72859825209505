import { useHistory, useLocation } from "react-router-dom";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import useStore from "../../../context/useStore";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { brandActions } from "../../../context/actions/brandActions";
import { finishActions } from "../../../context/actions/finishActions";
import tools from "../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const catalogSearchAtom = atom({
  catalogs: [],
  search: "",
  brandModel: "",
  brand: "",
  brandModelFinish: "",
  page: 1,
});

const useCatalogSearch = ({ handlePageParamsChange }) => {
  const [state, dispatch] = useStore();
  const brand_actions = brandActions(state, dispatch);
  const brand_model_actions = brandModelActions(state, dispatch);
  const finish_actions = finishActions(state, dispatch);
  const location = useLocation();
  const history = useHistory();

  const { catalogs, search, brandModel, brand, brandModelFinish, page } =
    queryString.parse(location.search, { arrayFormat: "bracket" }) || {};

  const [catalogSearch, setCatalogSearch] = useAtom(catalogSearchAtom);

  useEffect(() => {
    brand_actions.getAllBrands();
    brand_model_actions.getAllBrandModels();

    if (brandModel) {
      finish_actions.getFinishesByBrandModel(brandModel);
    }
  }, []);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(catalogSearch);
    },

    onResetSearch: () => {
      setCatalogSearch({
        catalogs: [],
        search: "",
        brand: "",
        brandModel: "",
        brandModelFinish: "",
        page: 1,
      });
      const newSearchParams = {
        catalogs: [],
        search: "",
        brand: "",
        brandModel: "",
        brandModelFinish: "",
        page: 1,
      };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      const newSearch = { ...catalogSearch, [name]: value, page: 1 };

      if (name === "brand") {
        newSearch.brandModel = "";
        newSearch.brandModelFinish = "";
      }
      if (name === "brandModel") {
        if (value) {
          finish_actions.getFinishesByBrandModel(value);
        }
        newSearch.brandModelFinish = "";
      }

      setCatalogSearch(newSearch);
      actions.onTriggerSearch(newSearch);
    },

    onChangePage: (newPage) => {
      const newSearch = { ...catalogSearch, page: newPage };
      setCatalogSearch(newSearch);
      actions.onTriggerSearch(newSearch);
    },
  };

  return {
    state,
    catalogSearch,
    actions,
  };
};

export default useCatalogSearch;
