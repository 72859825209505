import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { catalogStoreActions } from "../../../context/actions/catalogStoreActions";
import { CATALOG_STORE_TYPES } from "../../../context/reducers/catalogStoreReducers";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
const CatalogStoreDetail = (props) => {
  const [state, dispatch] = useStore();

  var actions = catalogStoreActions(state, dispatch, props);

  useEffect(() => {
    if (props.match.params.uuid != "create") {
      actions.getCatalogStore(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(
      e,
      CATALOG_STORE_TYPES.UPDATE_CATALOG_STORE_DATA,
      dispatch
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un catalogue</h1>
          <Control
            label="Nom"
            type="text"
            name="name"
            value={state.catalogStore.item.name}
            change={inputChange}
            error={state.error}
          />
          <Control
            label="Zone géographique"
            type="select"
            name="geographicZone"
            value={state.catalogStore.item.geographicZone}
            change={inputChange}
            error={state.error}
            datas={[
              { id: 1, name: "Réunion" },
              { id: 2, name: "Mayotte" },
            ]}
          />
          <div className="my-3">
            <Control
              label="Est actif ?"
              type="checkbox"
              name="isActive"
              checked={state.catalogStore.item.isActive}
              change={inputChange}
              error={state.error}
            />
            <Control
              label="Est récupéré d'une API externe ?"
              type="checkbox"
              name="updatedByApi"
              checked={state.catalogStore.item.updatedByApi}
              change={inputChange}
              error={state.error}
            />
          </div>
          {state.catalogStore.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.saveCatalogStore(state.catalogStore.item)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CatalogStoreDetail);
