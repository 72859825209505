import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { AUTH_TYPES } from "../reducers/authReducers";
import { loginAdapters } from "../../adapters/loginAdapters";

export const authActions = (state, dispatch) => {
  function loginUser(userData) {
    dispatch({ type: AUTH_TYPES.LOAD_CURRENT_USER, payload: true });

    axios
      .post(`${api_url}login`, loginAdapters.transformForApi(userData))
      .then((res) => {
        // Save to localStorage
        const { token } = res.data;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        tools.setAuthToken(token);
        // Set current user
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { token },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          AUTH_TYPES.LOAD_CURRENT_USER
        );
      });
  }

  async function logoutUser(withReload = false, logoutFromApi = false) {
    if (logoutFromApi) {
      await axios.post(`${api_url}logout`).catch((err) => {
        console.log("err", err);
      });
    }

    // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    tools.setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch({ type: AUTH_TYPES.DELETE_CURRENT_USER, payload: {} });
    //tools.filterAlert("info", "Vous avez été correctement déconnecté.");
    if (withReload) {
      window.location.reload();
    }
  }

  return {
    loginUser,
    logoutUser,
  };
};
