export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  RESPONSABLE_COMMERCIAL: "RESPONSABLE_COMMERCIAL",
  COMMERCIAL_SENIOR: "COMMERCIAL_SENIOR",
  COMMERCIAL_JUNIOR: "COMMERCIAL_JUNIOR",
};

export const userAccessEnum = {
  CHERCHER_UTILISATEUR: "CHERCHER_UTILISATEUR",
  VISUALISER_UTILISATEUR: "VISUALISER_UTILISATEUR",
  CREER_UTILISATEUR: "CREER_UTILISATEUR",
  MODIFIER_UTILISATEUR: "MODIFIER_UTILISATEUR",
  SUPPRIMER_UTILISATEUR: "SUPPRIMER_UTILISATEUR",

  CHERCHER_GROUPE_UTILISATEUR: "CHERCHER_GROUPE_UTILISATEUR",
  VISUALISER_GROUPE_UTILISATEUR: "VISUALISER_GROUPE_UTILISATEUR",
  CREER_GROUPE_UTILISATEUR: "CREER_GROUPE_UTILISATEUR",
  MODIFIER_GROUPE_UTILISATEUR: "MODIFIER_GROUPE_UTILISATEUR",

  CHERCHER_MARQUE_VEHICULE: "CHERCHER_MARQUE_VEHICULE",
  VISUALISER_MARQUE_VEHICULE: "VISUALISER_MARQUE_VEHICULE",
  CREER_MARQUE_VEHICULE: "CREER_MARQUE_VEHICULE",
  MODIFIER_MARQUE_VEHICULE: "MODIFIER_MARQUE_VEHICULE",
  SUPPRIMER_MARQUE_VEHICULE: "SUPPRIMER_MARQUE_VEHICULE",

  CHERCHER_MODELE_VEHICULE: "CHERCHER_MODELE_VEHICULE",
  VISUALISER_MODELE_VEHICULE: "VISUALISER_MODELE_VEHICULE",
  CREER_MODELE_VEHICULE: "CREER_MODELE_VEHICULE",
  MODIFIER_MODELE_VEHICULE: "MODIFIER_MODELE_VEHICULE",
  SUPPRIMER_MODELE_VEHICULE: "SUPPRIMER_MODELE_VEHICULE",

  CHERCHER_FINITION_VEHICULE: "CHERCHER_FINITION_VEHICULE",
  VISUALISER_FINITION_VEHICULE: "VISUALISER_FINITION_VEHICULE",
  CREER_FINITION_VEHICULE: "CREER_FINITION_VEHICULE",
  MODIFIER_FINITION_VEHICULE: "MODIFIER_FINITION_VEHICULE",

  MODIFIER_CONSTANTE_DYNAMIQUE: "MODIFIER_CONSTANTE_DYNAMIQUE",
  CHERCHER_CONSTANTE_DYNAMIQUE: "CHERCHER_CONSTANTE_DYNAMIQUE",

  CHERCHER_CONTRAT: "CHERCHER_CONTRAT",
  VISUALISER_CONTRAT: "VISUALISER_CONTRAT",
  CREER_CONTRAT: "CREER_CONTRAT",
  MODIFIER_CONTRAT: "MODIFIER_CONTRAT",
  MODIFIER_STATUT_CONTRAT: "MODIFIER_STATUT_CONTRAT",

  CHERCHER_DOSSIER: "CHERCHER_DOSSIER",
  VISUALISER_DOSSIER: "VISUALISER_DOSSIER",
  CREER_DOSSIER: "CREER_DOSSIER",
  MODIFIER_DOSSIER: "MODIFIER_DOSSIER",
  SUPPRIMER_DOSSIER: "SUPPRIMER_DOSSIER",
  MODIFIER_STATUT_LOI_DE_ROULAGE: "MODIFIER_STATUT_LOI_DE_ROULAGE",
  CHERCHER_LOI_DE_ROULAGE_VALIDE: "CHERCHER_LOI_DE_ROULAGE_VALIDE",

  CHERCHER_TABLEAU_ENTRETIEN: "CHERCHER_TABLEAU_ENTRETIEN",
  VISUALISER_TABLEAU_ENTRETIEN: "VISUALISER_TABLEAU_ENTRETIEN",
  CREER_TABLEAU_ENTRETIEN: "CREER_TABLEAU_ENTRETIEN",
  MODIFIER_TABLEAU_ENTRETIEN: "MODIFIER_TABLEAU_ENTRETIEN",
  SUPPRIMER_TABLEAU_ENTRETIEN: "SUPPRIMER_TABLEAU_ENTRETIEN",

  CHERCHER_VEHICULE_CATALOGUE: "CHERCHER_VEHICULE_CATALOGUE",
  VISUALISER_VEHICULE_CATALOGUE: "VISUALISER_VEHICULE_CATALOGUE",
  CREER_VEHICULE_CATALOGUE: "CREER_VEHICULE_CATALOGUE",
  MODIFIER_VEHICULE_CATALOGUE: "MODIFIER_VEHICULE_CATALOGUE",
  SUPPRIMER_VEHICULE_CATALOGUE: "SUPPRIMER_VEHICULE_CATALOGUE",
  MODIFIER_VISIBILITE_VEHICULE_CATALOGUE:
    "MODIFIER_VISIBILITE_VEHICULE_CATALOGUE",
  VISUALISER_ENTRETIEN_VEHICULE_CATALOGUE:
    "VISUALISER_ENTRETIEN_VEHICULE_CATALOGUE",

  CHERCHER_CATALOGUE: "CHERCHER_CATALOGUE",
  VISUALISER_CATALOGUE: "VISUALISER_CATALOGUE",
  CREER_CATALOGUE: "CREER_CATALOGUE",
  MODIFIER_CATALOGUE: "MODIFIER_CATALOGUE",

  CHERCHER_VALEUR_RACHAT: "CHERCHER_VALEUR_RACHAT",
  VISUALISER_VALEUR_RACHAT: "VISUALISER_VALEUR_RACHAT",
  CREER_VALEUR_RACHAT: "CREER_VALEUR_RACHAT",
  MODIFIER_VALEUR_RACHAT: "MODIFIER_VALEUR_RACHAT",
  SUPPRIMER_VALEUR_RACHAT: "SUPPRIMER_VALEUR_RACHAT",
  AJOUTER_PERIODE_VALEUR_RACHAT: "AJOUTER_PERIODE_VALEUR_RACHAT",

  CHERCHER_PNEU: "CHERCHER_PNEU",
  VISUALISER_PNEU: "VISUALISER_PNEU",
  CREER_PNEU: "CREER_PNEU",
  MODIFIER_PNEU: "MODIFIER_PNEU",
  SUPPRIMER_PNEU: "SUPPRIMER_PNEU",

  CHERCHER_CLIENT: "CHERCHER_CLIENT",
  VISUALISER_CLIENT: "VISUALISER_CLIENT",
  CREER_CLIENT: "CREER_CLIENT",
  MODIFIER_CLIENT: "MODIFIER_CLIENT",
  SUPPRIMER_CLIENT: "SUPPRIMER_CLIENT",
  VISUALISER_CGV_CLIENT: "VISUALISER_CGV_CLIENT",

  CHERCHER_FLOTTE_VEHICULE: "CHERCHER_FLOTTE_VEHICULE",
  VISUALISER_FLOTTE_VEHICULE: "VISUALISER_FLOTTE_VEHICULE",
  CREER_FLOTTE_VEHICULE: "CREER_FLOTTE_VEHICULE",
  MODIFIER_FLOTTE_VEHICULE: "MODIFIER_FLOTTE_VEHICULE",
  SUPPRIMER_FLOTTE_VEHICULE: "SUPPRIMER_FLOTTE_VEHICULE",

  CHERCHER_FINANCEMENT: "CHERCHER_FINANCEMENT",
  VISUALISER_FINANCEMENT: "VISUALISER_FINANCEMENT",
  CREER_FINANCEMENT: "CREER_FINANCEMENT",
  MODIFIER_FINANCEMENT: "MODIFIER_FINANCEMENT",
  SUPPRIMER_FINANCEMENT: "SUPPRIMER_FINANCEMENT",

  CHERCHER_FINANCIER: "CHERCHER_FINANCIER",
  VISUALISER_FINANCIER: "VISUALISER_FINANCIER",
  CREER_FINANCIER: "CREER_FINANCIER",
  MODIFIER_FINANCIER: "MODIFIER_FINANCIER",
  SUPPRIMER_FINANCIER: "SUPPRIMER_FINANCIER",

  // CHERCHER_ENTRETIEN: "CHERCHER_ENTRETIEN",
  // VISUALISER_ENTRETIEN: "VISUALISER_ENTRETIEN",
  // CREER_ENTRETIEN: "CREER_ENTRETIEN",
  // MODIFIER_ENTRETIEN: "MODIFIER_ENTRETIEN",

  REASSIGNER_CLIENT: "REASSIGNER_CLIENT",
};
