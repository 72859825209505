import { useHistory, useLocation } from "react-router-dom";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import useStore from "../../../context/useStore";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { brandActions } from "../../../context/actions/brandActions";
import { finishActions } from "../../../context/actions/finishActions";
import tools from "../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const vehicleSearchAtom = atom({
  search: "",
  brandModel: "",
  brand: "",
  brandModelFinish: "",
  immat: "",
});

const useVehicleSearch = ({ handlePageParamsChange }) => {
  const [state, dispatch] = useStore();
  const brand_actions = brandActions(state, dispatch);
  const brand_model_actions = brandModelActions(state, dispatch);
  const finish_actions = finishActions(state, dispatch);
  const location = useLocation();
  const history = useHistory();

  const [vehicleSearch, setVehicleSearch] = useAtom(vehicleSearchAtom);

  useEffect(() => {
    brand_actions.getAllBrands();
    brand_model_actions.getAllBrandModels();

    const { brandModel } =
      queryString.parse(location.search, { arrayFormat: "bracket" }) || {};

    if (brandModel) {
      finish_actions.getFinishesByBrandModel(brandModel);
    }
  }, []);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(vehicleSearch);
    },

    onResetSearch: () => {
      setVehicleSearch({
        search: "",
        brand: "",
        brandModel: "",
        brandModelFinish: "",
        immat: "",
      });
      const newSearchParams = {
        search: "",
        brand: "",
        brandModel: "",
        brandModelFinish: "",
        immat: "",
      };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      const newSearchParams = { ...vehicleSearch, [name]: value };
      if (name === "brand") {
        newSearchParams.brandModel = "";
        newSearchParams.brandModelFinish = "";
      }
      if (name === "brandModel") {
        if (value) {
          finish_actions.getFinishesByBrandModel(value);
        }
        newSearchParams.brandModelFinish = "";
      }
      setVehicleSearch({ ...newSearchParams, page: "1" });
    },
  };

  return {
    state,
    vehicleSearch,
    actions,
  };
};

export default useVehicleSearch;
