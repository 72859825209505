import useStore from "../../../../context/useStore";

const useRoleStyle = () => {
  const [state] = useStore();

  if (
    ["COMMERCIAL_JUNIOR", "COMMERCIAL_SENIOR"].includes(state.auth.user.role)
  ) {
    const hiddenElements = document.querySelectorAll(
      "[data-priv=invisible-commerciaux]"
    );
    hiddenElements.forEach((element) => {
      element.style.display = "none";
    });
  }

  return {
    doesToggleProtectedFields: [
      "COMMERCIAL_JUNIOR",
      "COMMERCIAL_SENIOR",
    ].includes(state.auth.user.role)
      ? true
      : false,
  };
};

export default useRoleStyle;
