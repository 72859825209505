import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { groupActions } from "../../../context/actions/groupActions";
import Loader from "../../common/Loader";
import { GROUP_TYPES } from "../../../context/reducers/groupReducers";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import CatalogSelector from "../../common/CatalogSelector";

const GroupDetail = (props) => {
  const [state, dispatch] = useStore();

  var actions = groupActions(state, dispatch, props);

  useEffect(() => {
    if (props.match.params.uuid != "create") {
      actions.getGroup(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, GROUP_TYPES.UPDATE_GROUP_DATA, dispatch);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un groupe d'utilisateurs</h1>

          <Control
            label="Nom"
            type="text"
            name="name"
            value={state.group.item.name}
            change={inputChange}
            error={state.error}
            required
          />
          <CatalogSelector
            name="catalogs"
            label="Catalogues"
            value={state.group.item.catalogs}
            change={inputChange}
            required
          />
          <Control
            label="Est le groupe administrateur (les utilisateurs de ce groupe peuvent voir les dossiers des autres groupes, un seul groupe administrateur possible)"
            type="checkbox"
            name="isAdmin"
            checked={state.group.item.isAdmin}
            change={inputChange}
            error={state.error}
          />

          {state.group.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success mt-3"
              onClick={() => actions.saveGroup(state.group.item)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(GroupDetail);
