import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";

const GenerateContractModal = ({ quotation, setQuotationStatus, index }) => {
  const [quantity, setQuantity] = useState(0);
  const [caracs, setCaracs] = useState([]);

  var nbLeft = 0;
  if (quotation) {
    nbLeft = parseInt(quotation.quantity) - quotation.contracts.length;
  }

  useEffect(() => {
    setQuantity(nbLeft);
  }, [quotation]);
  useEffect(() => {
    if (quantity > nbLeft) {
      setQuantity(quantity - 1);
    } else if (quantity <= 0) {
      setQuantity(1);
    } else {
      var im = [...Array(parseInt(quantity)).keys()].map((x) => ({
        immat: "",
        serialNumber: "",
        inroadDate: DateTime.local().toFormat("yyyy-MM-dd"),
        dBegin: DateTime.local().toFormat("yyyy-MM-dd"),
      }));
      setCaracs(im);
    }
  }, [quantity]);

  function changeCarac(e, k) {
    var im = [...caracs];
    im[k][e.target.name] = e.target.value;
    setCaracs(im);
  }

  return (
    <>
      <div
        className="modal fade"
        id="genContractModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contractModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "90%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="contractModalLabel">
                Générer des contrats
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Control
                label={"Nombre de contrats à générer (max " + nbLeft + ")"}
                name="quantity"
                type="number"
                value={quantity}
                change={(e) => setQuantity(e.target.value)}
              />
              <ul className="list-group">
                {caracs.map((c, k) => {
                  return (
                    <li className="list-group-item">
                      <span className="badge badge-primary">{k + 1}</span>
                      <div className="input-group">
                        <Control
                          label={"Immatriculation véhicule"}
                          name="immat"
                          value={c.immat}
                          k={k}
                          change={changeCarac}
                        />
                        <span className="mr-2" />
                        <Control
                          label={"Numéro de série"}
                          name="serialNumber"
                          value={c.serialNumber}
                          k={k}
                          change={changeCarac}
                        />
                        <span className="mr-2" />
                        <Control
                          label={"Date de mise en circulation"}
                          name="inroadDate"
                          type="date"
                          value={c.inroadDate}
                          k={k}
                          change={changeCarac}
                        />
                        <span className="mr-2" />
                        <Control
                          label={"Date de début contrat"}
                          name="dBegin"
                          type="date"
                          value={c.dBegin}
                          k={k}
                          change={changeCarac}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                id="close-client-modal"
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={() => setQuotationStatus(index, 3, quantity, caracs)}
              >
                Créer les {quantity} contrats
              </button>
              <button
                id="close-client-modal"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateContractModal;
