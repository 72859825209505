import React from "react";
import { withRouter, Link } from "react-router-dom";
import { catalogStoreActions } from "../../../context/actions/catalogStoreActions";
import CustomList from "../../common/CustomList";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import CustomSearch from "../../common/CustomSearch";
import CustomPagination from "../../common/CustomPagination";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";

const Badge = ({ isTrue }) => {
  return (
    <div className={`badge badge-${isTrue ? "success" : "danger"}`}>
      {isTrue ? "OUI" : "NON"}
    </div>
  );
};

const CatalogStoreList = (props) => {
  const [state, dispatch] = useStore();

  const actions = catalogStoreActions(state, dispatch, props);

  return (
    <CustomList getAction={actions.getCatalogStores}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Catalogues</h1>
              {state.catalogStore.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_CATALOGUE}>
                  <Link className="btn btn-primary" to="/catalogstores/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un catalogue
                  </Link>
                </Can>
              )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />
              <NbSearchResults total={state.catalogStore.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Zone géo.</th>
                    <th>API externe ?</th>
                    <th>Est actif ?</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.catalogStore.items.map((catalogStore, k) => (
                    <tr key={"catalogStore-row-" + k}>
                      <td>{catalogStore.name}</td>
                      <td>{catalogStore.geographicZone.name}</td>
                      <td>
                        <Badge isTrue={catalogStore.updatedByApi} />
                      </td>
                      <td>
                        <Badge isTrue={catalogStore.isActive} />
                      </td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_CATALOGUE}>
                          <Link
                            className="btn btn-secondary"
                            to={"/catalogstores/" + catalogStore.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <CustomPagination
                total={state.catalogStore.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default withRouter(CatalogStoreList);
