import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { finishActions } from "../../../context/actions/finishActions";
import Loader from "../../common/Loader";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import FinishSearch from "./FinishSearch/FinishSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import { useAtom } from "jotai";
import { finishSearchAtom } from "./FinishSearch/useFinishSearch";

const FinishList = () => {
  const [state, dispatch] = useStore();
  const [finishSearch, setFinishSearch] = useAtom(finishSearchAtom);

  var actions = finishActions(state, dispatch);

  useEffect(() => {
    actions.getFinishes(finishSearch);
  }, []);

  return (
    <CustomList getAction={actions.getFinishes}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Finitions</h1>
              {state.finish.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_FINITION_VEHICULE}>
                  <Link className="btn btn-primary" to="/finishes/create">
                    <i className="fa fa-plus mr-2" /> Ajouter une finition
                  </Link>
                </Can>
              )}
              <FinishSearch handlePageParamsChange={actions.getFinishes} />
              <NbSearchResults total={state.finish.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Catalogue</th>
                    <th>Finition</th>
                    <th>Modèle</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.finish.items.map((finish, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{finish.catalog?.name}</td>
                      <td>{finish.name}</td>
                      <td>{finish.brandModel.name}</td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_FINITION_VEHICULE}>
                          <Link
                            className="btn btn-secondary"
                            to={"/finishes/" + finish.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.finish.total}
                activePage={finishSearch.page || 1}
                handlePageParamsChange={(data) => {
                  actions.getFinishes({
                    ...finishSearch,
                    ...data,
                  });
                  setFinishSearch({
                    ...finishSearch,
                    ...data,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default FinishList;
