import React, { useEffect } from "react";
import useStore from "../../context/useStore";
import { repurchaseActions } from "../../context/actions/repurchaseActions";
import vehicleTools from "../../helpers/vehicleTools";
import Control from "./Control";
import InputGroupWithResetValue from "./InputGroupWithResetValue";
import useTVA from "../../hooks/useInit/useTVA";

const CalcVRBox = ({ quotation, rlChange, num, disabled }) => {
  const [state, dispatch] = useStore();

  var {
    vehicle,
    dur,
    km,
    repurchase,
    repurchase_data,
    rateVR,
    repurchaseValueFinal,
    kmPrice,
  } = quotation;

  var actions = repurchaseActions(state, dispatch);
  const { getTVAByCatalog } = useTVA();
  const { TVA_INVERT } = getTVAByCatalog(vehicle.catalog.uuid);

  useEffect(() => {
    if (repurchase_data.length == 0 && !state.repurchase.isLoading) {
      actions.getRepurchasesFromVehicle(
        vehicle.brand.uuid,
        vehicle.brandModel.uuid,
        vehicle.brandModelFinish.fuelType.id
      );
    }
  }, [vehicle.uuid]);

  var repurchaseValueFinalCalc = "0";
  var rateVRCalc = "0";
  var rateVRInfo = "";

  if (
    repurchase_data.length == 0 &&
    !state.repurchase.isLoading &&
    state.repurchase.items.find(
      (r) =>
        r.brand.uuid == vehicle.brand.uuid &&
        r.brandModels.find((b) => b.uuid == vehicle.brandModel.uuid)
    )
  ) {
    rlChange({
      target: {
        name: "repurchase_data",
        value: JSON.parse(JSON.stringify(state.repurchase.items)),
      },
    });
  }

  if (repurchase) {
    const repurchaseRef = repurchase_data.find((r) => r.uuid === repurchase);
    var rvr = vehicleTools.getRateVR(dur, km, repurchaseRef.tableValues, num);
    rateVRCalc = rvr.value;
    rateVRInfo = rvr.info;
  } else {
    if (repurchase_data.length > 0) {
      var rdActive = repurchase_data.find((r) => !r.validTo)
        ? repurchase_data.find((r) => !r.validTo)
        : repurchase_data[0];
      rlChange({ target: { name: "repurchase", value: rdActive.uuid } });
    }
  }
  if (rateVR > 0) {
    var optionsCost = 0;
    vehicle.optionsNotIncluded.forEach((v) => {
      if (v.isInclVR) {
        optionsCost += parseInt(v.priceHT) * (v.isInclTVA ? TVA_INVERT : 1);
      }
    });
    repurchaseValueFinalCalc =
      Math.round(
        (((vehicle.pricePublicTTC + optionsCost) * parseFloat(rateVR)) / 100) *
          100
      ) / 100;
  }

  if (repurchase && rateVR === "0") {
    rlChange({ target: { name: "rateVR", value: rateVRCalc } });
  }
  if (repurchase && repurchaseValueFinal === "0") {
    rlChange({
      target: {
        name: "repurchaseValueFinal",
        value: repurchaseValueFinalCalc,
      },
    });
  }
  useEffect(() => {
    if (
      repurchase_data.find(
        (r) => (r.uuid == repurchase && kmPrice == "-1") || kmPrice == ""
      )
    ) {
      rlChange({
        target: {
          name: "kmPrice",
          value: repurchase_data.find((r) => r.uuid == repurchase).kmPrice,
        },
      });
    }
  }, [kmPrice, repurchase]);

  return (
    <>
      <Control
        label={rateVRInfo || "VR choisie"}
        type="select"
        name="repurchase"
        dataIndex="uuid"
        dataLabel="name"
        value={repurchase}
        change={rlChange}
        datas={repurchase_data}
        required={true}
        disabled={disabled}
      />
      {/* <p className="text-orange" style={{ fontSize: 12 }}>
        {rateVRInfo}
      </p> */}
      <>
        <InputGroupWithResetValue
          props={{
            name: "rateVR",
            symbol: "%",
            label: "Taux VR",
            defaultValue: rateVRCalc,
            required: true,
          }}
          data={{ rateVR }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
          disabled={disabled}
        />

        <InputGroupWithResetValue
          props={{
            name: "repurchaseValueFinal",
            symbol: "€",
            label: "Valeur de rachat TTC",
            defaultValue: repurchaseValueFinalCalc,
            required: true,
          }}
          data={{ repurchaseValueFinal }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
          disabled={disabled}
        />
        <Control
          label="Prix du KM supplémentaire"
          type="text"
          suffix={"€"}
          value={kmPrice}
          change={(e) =>
            rlChange({
              target: {
                name: "kmPrice",
                value: e.target.value,
              },
            })
          }
          disabled={disabled}
        />
      </>
    </>
  );
};

export default CalcVRBox;
