import tools from "../../helpers/tools";
import { MAINTENANCE_TYPES } from "../reducers/maintenanceReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { maintenanceService } from "../../services/maintenanceService";
import maintenanceAdapters from "../../adapters/maintenanceAdapters";

export const maintenanceActions = (state, dispatch, props) => {
  function getMaintenances(search = { search: null }) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      catalogs: search.catalogs,
      brand: search.brand,
      brandModel: search.brandModel,
      page: search.page,
    });

    maintenanceService
      .getMaintenances(search)
      .then((res) => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function getMaintenancesFromVehicle(brandUuid, brandModelUuid, fuelTypeId) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    maintenanceService
      .getMaintenancesFromVehicle(brandUuid, brandModelUuid, fuelTypeId)
      .then((res) => {
        if (res.data.length == 0) {
          tools.filterError(
            dispatch,
            { other: "Aucun tableau de maintenance pour ce véhicule" },
            MAINTENANCE_TYPES.LOAD_MAINTENANCE
          );
        } else {
          dispatch({
            type: MAINTENANCE_TYPES.GET_MAINTENANCES_VEHICLE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function getMaintenance(uuid) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    maintenanceService
      .getMaintenance(uuid)
      .then((res) => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: maintenanceAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function saveMaintenance(maintenanceData) {
    if (maintenanceData.uuid) {
      updateMaintenance(maintenanceData);
    } else {
      createMaintenance(maintenanceData);
    }
  }
  function createMaintenance(maintenanceData) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    maintenanceService
      .createMaintenance(maintenanceData)
      .then((res) => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: maintenanceAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          "Le tableau de maintenance a été correctement créé."
        );
        props.history.push("/maintenances/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function updateMaintenance(maintenanceData) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    maintenanceService
      .updateMaintenance(maintenanceData)
      .then((res) => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: maintenanceAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          maintenanceData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }

  return {
    saveMaintenance,
    createMaintenance,
    updateMaintenance,
    getMaintenances,
    getMaintenance,
    getMaintenancesFromVehicle,
  };
};
