export const UPKEEP_TYPES = {
  LOAD_UPKEEP: "LOAD_UPKEEP",
  GET_UPKEEPS: "GET_UPKEEPS",
  GET_UPKEEP: "GET_UPKEEP",
  UPDATE_UPKEEP_DATA: "UPDATE_UPKEEP_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  search: "",
  item: {
    name: ""
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPKEEP_TYPES.LOAD_UPKEEP:
      return {
        ...state,
        isLoading: action.payload
      };
    case UPKEEP_TYPES.GET_UPKEEPS:
      return {
        ...state,
        items: action.payload,
        item: initialState.item,
        isLoading: false
      };

    case UPKEEP_TYPES.GET_UPKEEP:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case UPKEEP_TYPES.UPDATE_UPKEEP_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
