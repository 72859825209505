export const BRAND_TYPES = {
  LOAD_BRAND: "LOAD_BRAND",
  GET_BRANDS: "GET_BRANDS",
  GET_BRAND: "GET_BRAND",
  UPDATE_BRAND_DATA: "UPDATE_BRAND_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    name: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BRAND_TYPES.LOAD_BRAND:
      return {
        ...state,
        isLoading: action.payload,
      };
    case BRAND_TYPES.GET_BRANDS:
      return {
        ...state,
        items: action.payload.data,
        item: initialState.item,
        total: action.payload.meta.total,
        isLoading: false,
      };

    case BRAND_TYPES.GET_BRAND:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case BRAND_TYPES.UPDATE_BRAND_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
