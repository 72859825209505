import { useEffect } from "react";
import { brandActions } from "../../../../context/actions/brandActions";
import { brandModelActions } from "../../../../context/actions/brandModelActions";
import { repurchaseActions } from "../../../../context/actions/repurchaseActions";
import useStore from "../../../../context/useStore";
import { REPURCHASE_TYPES } from "../../../../context/reducers/repurchaseReducers";
import tools from "../../../../helpers/tools";
import { BRANDMODEL_TYPES } from "../../../../context/reducers/brandModelReducers";

const useRepurchaseDetail = (props) => {
  const [state, dispatch] = useStore();

  var repurchaseData = state.repurchase.item;
  var brandModelData = state.brandModel.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;
  var userRole = state.auth.user.role;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var r_actions = repurchaseActions(state, dispatch, props);

  useEffect(() => {
    b_actions.getAllBrands();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
        meta: { total: 0 },
      },
    });
    if (props.match.params.uuid != "create") {
      r_actions.getRepurchase(props.match.params.uuid);
    }
  }, []);

  useEffect(() => {
    if (repurchaseData?.brand) {
      bm_actions.getBrandModelByBrand(repurchaseData?.brand);
    }
  }, [repurchaseData?.brand]);

  const repurchaseDetailActions = {
    inputChange: (e) => {
      if (e.target.name == "brand") {
        dispatch({
          type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
          payload: {
            key: "brandModels",
            value: [],
          },
        });
        bm_actions.getBrandModelByBrand(e.target.value);
      }
      tools.inputChange(e, REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA, dispatch);
    },
    toggleBrandModels: (e, uuid) => {
      var { brandModels } = repurchaseData;
      if (e.target.checked) {
        brandModels.push(uuid);
      } else {
        var index = brandModels.indexOf(uuid);
        brandModels.splice(index, 1);
      }
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "brandModels",
          value: brandModels,
        },
      });
    },
    toggleFuelTypes: (e, id) => {
      var { fuelTypes } = repurchaseData;
      if (e.target.checked) {
        fuelTypes.push(id);
      } else {
        var index = fuelTypes.indexOf(id);
        fuelTypes.splice(index, 1);
      }
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "fuelTypes",
          value: fuelTypes,
        },
      });
    },
    updateTable: (excelData) => {
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "tableValues",
          value: excelData,
        },
      });
    },
    tableValueChange: (e, col, row) => {
      var { tableValues } = repurchaseData;
      tableValues.values[col][row] = e.target.value;
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    addColumn: (value) => {
      var { tableValues } = repurchaseData;
      tableValues.km.push(value);
      repurchaseDetailActions.pushTableValues(tableValues);
    },
    addRow: (value) => {
      var { tableValues } = repurchaseData;
      tableValues.dur.push(value);
      repurchaseDetailActions.pushTableValues(tableValues);
    },
    pushTableValues: (tableValues) => {
      !tableValues.values && (tableValues.values = {});
      tableValues.dur.forEach((dur) => {
        !tableValues.values[dur] && (tableValues.values[dur] = []);
        tableValues.km.forEach((km, k) => {
          !tableValues.values[dur][k] && (tableValues.values[dur][k] = "");
        });
      });
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    deleteRow: (key) => {
      var { tableValues } = repurchaseData;
      delete tableValues.values[key];
      tableValues.dur = tableValues.dur.filter((d) => d !== key);
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    deleteCol: (key) => {
      var { tableValues } = repurchaseData;

      for (var k in tableValues.values) {
        tableValues.values[k] = tableValues.values[k].filter(
          (entry, k) => k !== key
        );
      }
      tableValues.km = tableValues.km.filter((d, k) => k !== key);
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },

    formatExcelData: (excelData, orientation) => {
      var table = {
        dur: [],
        km: [],
        values: {},
      };
      var ex = excelData.split("\n");
      var row = "km";
      var col = "dur";
      if (orientation == 2) {
        row = "dur";
        col = "km";
      }
      table[row] = ex[0].split("	");
      table[row].shift();

      for (var key in ex) {
        if (key == 0) continue;
        var colvals = ex[key].split("	");
        table[col].push(colvals[0]);

        table.values[colvals[0]] = colvals.slice(1);
      }

      if (orientation == 2) {
        var newTableValues = {};
        table[row].forEach((c, k) => {
          newTableValues[k] = [];
        });

        for (var key in table.values) {
          table.values[key].forEach((tv, keyy) => {
            newTableValues[keyy].push(tv);
          });
        }
        table.values = {};
        table[row].forEach((c, k) => {
          table.values[c] = newTableValues[k];
        });
      }

      repurchaseDetailActions.updateTable(table);
    },
  };

  // !state.brandModel.isLoading &&
  //   !brandModelData.length == 0 &&
  //   !state.brand.isLoading &&
  //   !brandData.length == 0 &&
  //   !repurchaseData.brand == "" &&
  //   !state.repurchase.isLoading &&
  //   !state.constant.isLoading &&
  //   repurchaseDetailActions.parseRepurchaseName();

  return {
    repurchaseData,
    brandModelData,
    brandData,
    constantData,
    userRole,
    b_actions,
    bm_actions,
    r_actions,
    repurchaseDetailActions,
  };
};

export default useRepurchaseDetail;
