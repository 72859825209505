import React, { useEffect } from "react";
import useStore from "../context/useStore";
import Loader from "../components/common/Loader";
import { catalogStoreActions } from "../context/actions/catalogStoreActions";

const CatalogStoreMiddleware = ({ children }) => {
  const [state, dispatch] = useStore();
  var catalog_store_actions = catalogStoreActions(state, dispatch);
  const catalogStoresLoaded = state.catalogStore?.items?.length > 0;
  const shouldLoadCatalogStores =
    state.auth.isAuthenticated && !catalogStoresLoaded;
  useEffect(() => {
    if (shouldLoadCatalogStores) catalog_store_actions.getAllCatalogStores();
  }, []);

  if (!shouldLoadCatalogStores) return children;

  if (!catalogStoresLoaded) {
    return <Loader text="Chargement des catalogues" />;
  }
};

export default CatalogStoreMiddleware;
