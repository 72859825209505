import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "car-makes-icons/dist/style.css";
import Header from "./components/common/layout/Header";
import BreadCrumb from "./components/common/layout/BreadCrumb";
import AppRoutes from "./AppRoutes";
import useConsoleLog from "./hooks/useInit/useConsoleLog";

const App = () => {
  useConsoleLog();
  return (
    <Router>
      <Header />
      <BreadCrumb />
      <ToastContainer position="bottom-right" />
      <main role="main" className=" pb-5" style={{ minHeight: "100%" }}>
        <AppRoutes />
      </main>
    </Router>
  );
};

export default App;
