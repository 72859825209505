import React from "react";
import useBrandModelSearch from "./useBrandModelSearch";
import Control from "../../common/Control";

const BrandModelSearch = ({ handlePageParamsChange }) => {
  const { state, brandModelSearch, actions } = useBrandModelSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col">
          <Control
            label="Par nom de modèle"
            name="name"
            id="name"
            type="text"
            value={brandModelSearch.name}
            change={actions.onChangeSearch}
          />
        </div>
        <div className="col">
          <Control
            label="Rechercher par marque"
            id="brand"
            name="brand"
            type="select"
            value={brandModelSearch.brand}
            change={actions.onChangeSearch}
            datas={state.brand.items}
            dataIndex="uuid"
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default BrandModelSearch;
