import React from "react";
import useStore from "../../context/useStore";

const CatalogSelector = ({ name, required, label, value, change }) => {
  const [state] = useStore();
  const catalogStores = state.catalogStore.items;

  const onSelectItem = (catalogStore) => {
    var newValue = [];
    if (value.includes(catalogStore.uuid)) {
      newValue = value.filter((uuid) => uuid !== catalogStore.uuid);
    } else {
      newValue = [...value, catalogStore.uuid];
    }

    change({
      target: {
        name,
        value: newValue,
      },
    });
  };

  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span className="text-danger ml-1">*</span>}
      </label>
      {catalogStores.map((catalogStore, k) => {
        const isSelected = value.includes(catalogStore.uuid);
        return (
          <button
            key={`catalog${catalogStore.uuid}`}
            type="button"
            className={`btn mr-1 btn-sm ${
              isSelected ? "btn-primary" : "btn-default"
            }`}
            onClick={() => onSelectItem(catalogStore)}
          >
            {catalogStore.name}
          </button>
        );
      })}
      <small className="form-text text-danger">{state.error[name]}</small>
    </div>
  );
};

export default CatalogSelector;
