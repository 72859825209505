import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";
import vehicleTools from "../../../helpers/vehicleTools";
import contractTools from "../../../helpers/contractTools";
import tools from "../../../helpers/tools";
import CalcVRBox from "../../common/CalcVRBox";
import CalcMaintenanceBox from "../../common/CalcMaintenanceBox";
import InputGroupWithResetValue from "../../common/InputGroupWithResetValue";
import Loader from "../../common/Loader";
import { withRouter } from "react-router-dom";
import GenerateContractModal from "./GenerateContractModal";
import ItemList from "../../common/ItemList";
import DynamicList from "../../common/DynamicList";
import { toast } from "react-toastify";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import useTVA from "../../../hooks/useInit/useTVA";
import useRoleStyle from "./hooks/useRoleStyle";

const ContractFinalValues = ({
  quotation,
  rlVehicleChange,
  rlChange,
  index,
  setQuotationStatus,
  deleteQuotation,
  match,
  setQuotationOpened,
  toggleOverlay,
  overlayOpened,
  navQuotation,
  totalQuotation,
  hideKeys,
  openPdf,
  setQuotationSelected,
  isCreate,
  duplicateQuotation,
  changeLineOrder,
  tableWidth,
}) => {
  const [state, dispatch] = useStore();
  const [firstLoad, setFirstLoad] = useState(true);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const [mentionSel, setMentionSel] = useState("");
  const { doesToggleProtectedFields } = useRoleStyle();

  let showDetail = overlayOpened == index || false;
  var tireData = state.tire.items;

  var constantData = state.constant.items;
  var { vehicle } = quotation;
  const [locks, setLocks] = useState({
    insuranceHT: quotation.uuid ? true : false,
    insuranceFinLoss: quotation.uuid ? true : false,
  });
  let parameters = [];
  let funding = [
    {
      name: "financeRate",
      symbol: "%",
      label: "Taux de financement",
      defaultValue: constantData.FINANCE_RATE_DEFAULT,
    },
    { name: "firstRentInput", label: "Apport 1er Loyer" },
  ];
  const { getTVAByCatalog } = useTVA();
  const { TVA, TVA_INVERT } = getTVAByCatalog(quotation.vehicle.catalog.uuid);
  var basePrices = vehicleTools.calcVehiclePrices(
    vehicle,
    TVA,
    TVA_INVERT,
    true
  );
  var pricePublicTTC = vehicle.priceTTC;
  var investAmtHT = quotation.investAmtHT
    ? quotation.investAmtHT
    : vehicleTools.calcInvestAmtHT(quotation, TVA, TVA_INVERT);

  var priceNetTTC = basePrices.priceTotalTTC;
  var financeRentCalc = vehicleTools.calcFinanceRent(quotation, TVA_INVERT);
  var insuranceHTCalc = vehicleTools.calcInsuranceHT(quotation);
  var insuranceFinLossCalc = vehicleTools.calcInsuranceFinLoss(quotation);
  var totalRentHTCalc = vehicleTools.calcTotalRentHTCalc(quotation);
  var totalRentTTCCalc = vehicleTools.calcPriceTTC(quotation.totalRentHT, TVA);
  var applicationFeeCalc = vehicleTools.calcApplicationFee(
    investAmtHT,
    constantData.APPLICATION_FEES
  );
  var baseRentHT =
    financeRentCalc +
    parseFloat(quotation.maintenanceValueFinal) +
    parseFloat(quotation.marginTTC);
  baseRentHT = isNaN(baseRentHT) ? 0 : Math.round(baseRentHT * 100) / 100;
  var totalBenefits = 0;
  quotation.benefits.forEach((b) => {
    totalBenefits += parseFloat(b.priceHT);
  });

  useEffect(() => {
    if (quotation.uuid) {
      setLocks({ insuranceHT: true, insuranceFinLoss: true });
    } else {
      setLocks({ insuranceHT: false, insuranceFinLoss: false });
    }
  }, [quotation]);
  useEffect(() => {
    if (
      (quotation.financeRent === "0" ||
        quotation.financeRent != financeRentCalc) &&
      !firstLoad
    ) {
      rlChange({ target: { name: "financeRent", value: financeRentCalc } });
    }
  }, [financeRentCalc]);
  useEffect(() => {
    if (
      (quotation.insuranceHT === "0" ||
        quotation.insuranceHT != insuranceHTCalc) &&
      !locks.insuranceHT &&
      !firstLoad
    ) {
      rlChange({ target: { name: "insuranceHT", value: insuranceHTCalc } });
    }
  }, [insuranceHTCalc]);
  useEffect(() => {
    if (
      (quotation.insuranceFinLoss === "0" ||
        quotation.insuranceFinLoss != insuranceFinLossCalc) &&
      !locks.insuranceFinLoss &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "insuranceFinLoss", value: insuranceFinLossCalc },
      });
    }
  }, [insuranceFinLossCalc]);
  useEffect(() => {
    if (
      (quotation.totalRentHT === "0" ||
        quotation.totalRentHT != totalRentHTCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "totalRentHT", value: totalRentHTCalc },
      });
    }
  }, [totalRentHTCalc]);
  useEffect(() => {
    if (
      (quotation.totalRentTTC === "0" ||
        quotation.totalRentTTC != totalRentTTCCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "totalRentTTC", value: totalRentTTCCalc },
      });
    }
  }, [totalRentTTCCalc]);
  useEffect(() => {
    if (
      (quotation.applicationFee === "0" ||
        quotation.applicationFee != applicationFeeCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "applicationFee", value: applicationFeeCalc },
      });
    }
  }, [applicationFeeCalc]);

  useEffect(() => {
    if (
      (quotation.baseRentHT === "0" || quotation.baseRentHT != baseRentHT) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "baseRentHT", value: baseRentHT },
      });
    }
  }, [baseRentHT]);

  if (quotation.vehicle.pricePublicTTC === "0") {
    rlVehicleChange({
      target: { name: "pricePublicTTC", value: pricePublicTTC },
    });
  }
  if (
    quotation.vehicle.investAmtHT === "0" ||
    quotation.vehicle.investAmtHT != investAmtHT
  ) {
    rlVehicleChange({ target: { name: "investAmtHT", value: investAmtHT } });
  }
  if (
    quotation.vehicle.priceNetTTC === "0" ||
    quotation.vehicle.priceNetTTC != priceNetTTC
  ) {
    rlVehicleChange({ target: { name: "priceNetTTC", value: priceNetTTC } });
  }
  if (quotation.baseRentHT === "0" || quotation.baseRentHT != baseRentHT) {
    rlChange({ target: { name: "baseRentHT", value: baseRentHT } });
  }
  if (quotation.marginTTCRate > 0 && quotation.marginTTC == 0) {
    rlChange({
      target: { name: "marginTTCRate", value: quotation.marginTTCRate },
    });
  }

  useEffect(() => {
    setFirstLoad(false);
    setOverlayHeight(document.getElementById("trrl" + index).clientHeight);
  });

  function resetDefault(v, d) {
    rlChange({ target: { name: v, value: d } });
  }
  function addMention() {
    quotation.mentions.push({ name: mentionSel });
    rlChange({ target: { name: "mentions", value: quotation.mentions } });
  }
  function updateLocks(name) {
    var ulocks = JSON.parse(JSON.stringify(locks));
    ulocks[name] = !ulocks[name];
    setLocks(ulocks);
  }

  const onToggleDetails = () => {
    toggleOverlay(index, !showDetail);
  };

  var shouldShowOverlay = overlayOpened == index && overlayOpened != null;
  var shouldHideRow = overlayOpened != null && overlayOpened != index;

  var style = { position: "relative" };
  var overOverlay = { position: "relative", zIndex: 10 };
  hideKeys.includes(index)
    ? (style.display = "none")
    : (style.display = "table-row");


  return (
    <tr
      id={"trrl" + index}
      style={style}
      className={"big-table   " + (shouldShowOverlay ? "tr-front" : "tr-mask ")}
    >
      <td colSpan="13" className="border-0 px-0">
        <div className="bg-white border rounded shadow-sm">
          <table className="inline-table w-100">
            <tbody>
              <tr>
                <td className="border-0" style={{ ...overOverlay, width: 40 }}>
                  <button
                    className="btn btn-default btn-sm"
                    title="Remonter dans la liste"
                    onClick={() => changeLineOrder("down", index)}
                  >
                    <i className="fa fa-arrow-up" />
                  </button>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setQuotationSelected((x) => [...x, quotation]);
                        } else {
                          setQuotationSelected((x) =>
                            x.filter((xx) => xx.uuid !== quotation.uuid)
                          );
                        }
                      }}
                    />
                    <span className="badge badge-light shadow-sm">
                      {index + 1}
                    </span>
                  </div>
                  <button
                    className="btn btn-default btn-sm"
                    title="Descendre dans la liste"
                    onClick={() => changeLineOrder("up", index)}
                  >
                    <i className="fa fa-arrow-down" />
                  </button>
                </td>
                <td className="border-0">
                  <div className="d-flex flex-column">
                    <button
                      style={{ position: "relative", transform: "none" }}
                      className="btn text-left border-0 mb-0 px-0 py-0 text-primary"
                      onClick={onToggleDetails}
                    >
                      <span
                        className={`badge badge-${tools.getQuotationStatusColor(
                          quotation.status.id
                        )}  text-uppercase font-weight-bold badge-pill mr-2 p-2`}
                        style={{ fontSize: 14 }}
                      >
                        {
                          tools.findIn(
                            state.constant.items.QUOTATION_STATUS,
                            "id",
                            quotation.status.id
                          ).name
                        }
                      </span>
                      {quotation.reference}
                      <i className="fa fa-external-link-alt ml-2" />
                    </button>

                    <div className="d-flex align-items-center mt-2">
                      <span className="badge badge-light mr-2">
                        {quotation.dur} mois / {quotation.km} km
                      </span>
                      <span
                        className="badge badge-light"
                        title={quotation.vehicle.optionsNotIncluded
                          .map(
                            (qopt) =>
                              qopt.name +
                              " - " +
                              qopt.priceHT +
                              "€ " +
                              (qopt.isInclVR ? " (*VR)" : "")
                          )
                          .join("\n")}
                      >
                        {quotation.vehicle.optionsNotIncluded.length}{" "}
                        {tools.getStringPlural(
                          "option",
                          quotation.vehicle.optionsNotIncluded.length > 1
                        )}
                      </span>
                      {quotation.comment && quotation.comment != "" && (
                        <div className="ml-2">
                          <span
                            className="badge badge-light"
                            onClick={(e) => {
                              e.stopPropagation();
                              toast.info(quotation.comment, {
                                autoClose: 15000,
                              });
                            }}
                          >
                            <i
                              className="fa fa-comment"
                              title={quotation.comment}
                            ></i>{" "}
                            commentaire
                          </span>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </td>
                <td className="border-0 pl-0" style={{ ...overOverlay }}>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: 5 }}
                  >
                    {match.params.uuid != "create" && quotation.uuid ? (
                      <>
                        {quotation.status.id != 2 &&
                        quotation.status.id != 3 ? (
                          <>
                            <Can
                              I={userAccessEnum.MODIFIER_STATUT_LOI_DE_ROULAGE}
                            >
                              <button
                                className="btn btn-primary btn-sm "
                                onClick={() => setQuotationStatus(index, 2)}
                              >
                                Valider ce devis
                              </button>
                            </Can>
                            <button
                              className="btn btn-success btn-sm "
                              onClick={openPdf}
                            >
                              Voir les CP
                            </button>
                            <button
                              title="dupliquer ce devis"
                              className="btn bt-default btn-sm "
                              onClick={() => duplicateQuotation(index)}
                            >
                              <i className="fa fa-copy" />
                            </button>
                            <button
                              className="btn btn-default btn-sm  "
                              onClick={deleteQuotation}
                            >
                              <i className="fa fa-times" />
                            </button>
                          </>
                        ) : quotation.status.id == 2 ? (
                          <>
                            <Can
                              I={userAccessEnum.MODIFIER_STATUT_LOI_DE_ROULAGE}
                            >
                              <button
                                className="btn btn-default btn-sm "
                                onClick={() => setQuotationStatus(index, 1)}
                              >
                                Repasser en brouillon
                              </button>
                            </Can>
                            <button
                              className="btn btn-success btn-sm "
                              onClick={openPdf}
                            >
                              Voir les CP
                            </button>
                            <Can
                              I={userAccessEnum.MODIFIER_STATUT_LOI_DE_ROULAGE}
                            >
                              <button
                                type="button"
                                className="btn btn-success btn-sm "
                                data-toggle="modal"
                                data-target="#genContractModal"
                                onClick={setQuotationOpened}
                              >
                                Générer des contrats{" "}
                                <span className="badge badge-primary">
                                  {parseInt(quotation.quantity) -
                                    quotation.contracts.length}
                                </span>
                              </button>
                            </Can>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ fontSize: 14 }}
                              className="text-danger py-1"
                            >
                              <span className="badge badge-danger">
                                {quotation.contracts.length} Contrats
                              </span>
                            </span>
                            {quotation.contracts.length <
                              quotation.quantity && (
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                data-toggle="modal"
                                data-target="#genContractModal"
                                onClick={setQuotationOpened}
                              >
                                Générer des contrats{" "}
                                <span className="badge badge-primary">
                                  {parseInt(quotation.quantity) -
                                    quotation.contracts.length}
                                </span>
                              </button>
                            )}
                            {/* <Link
                  style={{ zIndex: 2 }}
                  to={"/contracts/" + quotation.contract}
                  className="btn btn-success btn-sm"
                  onClick={() => setQuotationStatus(index, 1)}
                >
                  Voir les CP
                </Link> */}
                            <button
                              className="btn btn-success btn-sm"
                              onClick={openPdf}
                            >
                              Voir les CP
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className="badge badge-danger mb-2"
                          title="Cliquez sur créer ou enregistrer le devis pour sauvegarder cette nouvelle ligne"
                          onClick={() =>
                            alert(
                              "Cliquez sur créer ou enregistrer le devis pour sauvegarder cette nouvelle ligne"
                            )
                          }
                          style={{ fontSize: 13, cursor: "pointer" }}
                        >
                          Cette ligne n'est pas encore enregistrée
                        </span>
                        <button
                          className="btn btn-default btn-sm"
                          onClick={deleteQuotation}
                        >
                          <i className="fa fa-times" />
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="quotation-table">
            <thead className="head-sm bg-transparent ">
              <tr style={{ fontSize: 12 }}>
                {/* <th>Sélect.</th>
                        <th>Véhicule</th>
                        <th>Actions</th> */}
                <th>Qté</th>
                <th>Prix pub. TTC (hors CG/BM)</th>
                <th>Remise sur prix TTC</th>
                <th>Prix net TTC</th>
                <th>Montant invest HT</th>
                <th data-priv="invisible-commerciaux">Marge TTC</th>
                <th>Loyer de base HT</th>
                <th>
                  Véhicule relais{" "}
                  {doesToggleProtectedFields && (
                    <input
                      type="checkbox"
                      checked={quotation.replacementVehicleCost > 0}
                      onChange={(e) =>
                        rlChange({
                          target: {
                            name: "replacementVehicleCost",
                            value: !e.target.checked
                              ? 0
                              : parseFloat(
                                  constantData.REPLACEMENT_VEHICULE_COST_DEFAULT
                                ),
                          },
                        })
                      }
                    />
                  )}
                </th>
                <th>
                  Assurance Prévoyance{" "}
                  {doesToggleProtectedFields && (
                    <input
                      type="checkbox"
                      checked={quotation.insuranceHT > 0}
                      onChange={(e) =>
                        rlChange({
                          target: {
                            name: "insuranceHT",
                            value: !e.target.checked ? 0 : insuranceHTCalc,
                          },
                        })
                      }
                    />
                  )}
                </th>
                <th>
                  Option Sérénité{" "}
                  {doesToggleProtectedFields && (
                    <input
                      type="checkbox"
                      checked={quotation.insuranceFinLoss > 0}
                      onChange={(e) =>
                        rlChange({
                          target: {
                            name: "insuranceFinLoss",
                            value: !e.target.checked ? 0 : insuranceFinLossCalc,
                          },
                        })
                      }
                    />
                  )}
                </th>
                <th>Autres prestations</th>
                <th>Total loyer HT</th>
                <th>Total loyer TTC</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Control
                    type="number"
                    name="quantity"
                    value={quotation.quantity}
                    change={rlChange}
                  />
                </td>
                <td>
                  <Control
                    size="small"
                    disabled={true}
                    name="priceHT"
                    value={Math.round(pricePublicTTC * 100) / 100}
                    change={rlVehicleChange}
                    suffix="€"
                    inputClassName="text-right"
                  />
               
                    <div>
                      <span
                        className={
                          quotation.vehicle.malusBonusEco > 0
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {quotation.vehicle.malusBonusEco > 0
                          ? "Malus: "
                          : "Bonus: "}
                        {quotation.vehicle.malusBonusEco ? Math.abs(quotation.vehicle.malusBonusEco):0} €
                      </span>
                    </div>
                  {vehicle.optionsNotIncluded.length > 0 && (
                    <span style={{ color: "#9a9a9a" }}>
                      Options :
                      {Math.round(
                        vehicle.optionsNotIncluded.reduce(
                          (a, b) =>
                            a + b.priceHT * (b.isInclTVA ? TVA_INVERT : 1),
                          0
                        ) * 100
                      ) / 100}
                      €
                    </span>
                  )}
                </td>
                <td>
                  <div className="input-group">
                    <input
                      className="form-control text-center"
                      type="text"
                      name="discountPct"
                      value={vehicle.discountPct}
                      onChange={rlVehicleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control text-center"
                      type="text"
                      name="discount"
                      value={vehicle.discount}
                      onChange={rlVehicleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">€</span>
                    </div>
                  </div>
                </td>
                <td>
                  <Control
                    value={
                      priceNetTTC ? Math.round(priceNetTTC * 100) / 100 : 0
                    }
                    disabled={true}
                    suffix="€"
                    inputClassName="text-right"
                  />
                </td>
                <td>
                  <Control
                    value={investAmtHT}
                    disabled={true}
                    suffix="€"
                    inputClassName="text-right"
                  />
                </td>
                <td data-priv="invisible-commerciaux">
                  {/* <InputGroupWithResetValue
          props={{
            name: "marginTTCRate",
            symbol: "%",
            inputClassName: "text-center"
          }}
          data={quotation}
          resetDefault={resetDefault}
          inputChange={rlChange}
          error={state.error["marginTTCRate"]}
        /> */}
                  <Control
                    name="marginTTCRate"
                    value={quotation.marginTTCRate}
                    type="number"
                    change={rlChange}
                    error={state.error}
                    defferChange={true}
                    suffix={"%"}
                  />
                  <Control
                    name="marginTTC"
                    value={quotation.marginTTC}
                    type="number"
                    change={rlChange}
                    error={state.error}
                    defferChange={true}
                    suffix={"€"}
                  />
                  {/* <InputGroupWithResetValue
          props={{
            name: "marginTTC",
            symbol: "€",
            inputClassName: "text-center"
          }}
          data={quotation}
          resetDefault={resetDefault}
          inputChange={rlChange}
          error={state.error["marginTTC"]}
        /> */}
                </td>
                <td>
                  <Control
                    value={baseRentHT}
                    disabled={true}
                    suffix="€"
                    inputClassName="text-right"
                  />
                  {/* <InputGroupWithResetValue
          props={{
            name: "financeRent",
            defaultValue: financeRentCalc
          }}
          data={quotation}
          disabled={true}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          inputChange={rlChange}
        /> */}
                </td>
                <td>
                  <Control
                    name="replacementVehicleCost"
                    suffix={"€"}
                    change={rlChange}
                    value={quotation.replacementVehicleCost}
                    disabled={doesToggleProtectedFields}
                  />
                </td>
                <td style={{ position: "relative" }}>
                  {/* <InputGroupWithResetValue
          props={{
            name: "insuranceHT",
            defaultValue: insuranceHTCalc,
            symbol: "€"
          }}
          data={quotation}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          noBtn={true}
          inputChange={rlChange}
        /> */}
                  <Control
                    name="insuranceHT"
                    value={quotation.insuranceHT}
                    change={rlChange}
                    suffix="€"
                    inputClassName="text-center"
                    disabled={doesToggleProtectedFields}
                  />
                  {locks.insuranceHT ? (
                    <i
                      title="Dévérouiller cette valeur pour permettre son calcul automatique"
                      className="fa fa-lock input-lock"
                      style={{ color: "#ff401b" }}
                      onClick={() => updateLocks("insuranceHT")}
                    ></i>
                  ) : (
                    <i
                      title="Vérouiller cette valeur pour empêcher son calcul automatique"
                      className="fa fa-lock-open input-lock"
                      style={{ color: "#4f79ff" }}
                      onClick={() => updateLocks("insuranceHT")}
                    ></i>
                  )}
                </td>
                <td style={{ position: "relative" }}>
                  {/* <InputGroupWithResetValue
          props={{
            name: "insuranceFinLoss",
            defaultValue: insuranceFinLossCalc,
            symbol: "€"
          }}
          data={quotation}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          noBtn={true}
          inputChange={rlChange}
        /> */}
                  <Control
                    name="insuranceFinLoss"
                    value={quotation.insuranceFinLoss}
                    change={rlChange}
                    suffix="€"
                    inputClassName="text-center"
                    disabled={doesToggleProtectedFields}
                  />
                  {locks.insuranceFinLoss ? (
                    <i
                      title="Dévérouiller cette valeur pour permettre son calcul automatique"
                      className="fa fa-lock input-lock"
                      style={{ color: "#ff401b" }}
                      onClick={() => updateLocks("insuranceFinLoss")}
                    ></i>
                  ) : (
                    <i
                      title="Vérouiller cette valeur pour empêcher son calcul automatique"
                      className="fa fa-lock-open input-lock"
                      style={{ color: "#4f79ff" }}
                      onClick={() => updateLocks("insuranceFinLoss")}
                    ></i>
                  )}
                </td>
                <td>
                  <Control
                    name="totalBenefits"
                    disabled={true}
                    value={totalBenefits}
                    suffix="€"
                    inputClassName="text-right"
                  />
                </td>
                <td>
                  <Control
                    inputClassName="text-right"
                    name="totalRentHT"
                    value={totalRentHTCalc}
                    disabled={true}
                    change={rlChange}
                    suffix="€"
                  />
                </td>
                <td>
                  <Control
                    inputClassName="text-right"
                    name="totalRentTTC"
                    disabled={true}
                    value={totalRentTTCCalc}
                    suffix="€"
                  />
                </td>
                <td
                  style={{ height: "100%", width: tableWidth + "%" }}
                  className={
                    "backdrop " +
                    (shouldHideRow ||
                    (quotation.contracts &&
                      quotation.contracts.length >= quotation.quantity)
                      ? "d-block"
                      : "d-none")
                  }
                />
              </tr>
            </tbody>
          </table>
          <ToggleDetailButton
            onToggleDetails={onToggleDetails}
            showDetail={showDetail}
            bgColor={tools.getQuotationStatusColor(quotation.status.id)}
          />
          {showDetail && (
            <table
              className=" detail-table mt-2"
              style={{
                background: "linear-gradient(357deg, #f5f8ff 73%, transparent)",
              }}
            >
              <tbody>
                <tr>
                  <td colSpan="13" className=" py-0">
                    <div className="p-2">
                      <div className="row simulation">
                        <div
                          className="col-3 p-2"
                          data-priv="invisible-commerciaux"
                        >
                          <h4>Financement</h4>
                          {constantData.isLoading ? (
                            <Loader />
                          ) : (
                            funding.map((x, k) => (
                              <InputGroupWithResetValue
                                key={k}
                                props={x}
                                data={quotation}
                                resetDefault={resetDefault}
                                inputChange={rlChange}
                                error={state.error[x.name]}
                              />
                            ))
                          )}
                          <InputGroupWithResetValue
                            props={{
                              name: "applicationFee",
                              defaultValue: applicationFeeCalc,
                              label: "Frais de dossier",
                              symbol: "€",
                            }}
                            data={quotation}
                            resetDefault={(name, value) =>
                              rlChange({
                                target: {
                                  name,
                                  value,
                                },
                              })
                            }
                            inputChange={rlChange}
                          />
                          {constantData.isLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <Control
                                label="Loyer financier"
                                disabled={true}
                                name="financeRent"
                                value={financeRentCalc}
                                suffix="€"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-3 p-2">
                          <h4>Valeur de rachat</h4>
                          <CalcVRBox
                            quotation={quotation}
                            rlChange={rlChange}
                            num={index + 1}
                            disabled={doesToggleProtectedFields}
                          />
                        </div>
                        <div className="col-3 p-2">
                          <h4>Coût de maintenance</h4>
                          <CalcMaintenanceBox
                            quotation={quotation}
                            rlChange={rlChange}
                            num={index + 1}
                            disabled={doesToggleProtectedFields}
                          />
                        </div>
                        <div className="col-3 p-2">
                          <h4>Prestations</h4>
                          <Control
                            label="Nombre de pneus"
                            name="nbTires"
                            type="number"
                            change={rlChange}
                            value={quotation.nbTires}
                          />
                          <Control
                            label={
                              "Prix d'un pneu (par défaut : " +
                              (tireData.find(
                                (t) => t.uuid == quotation.vehicle.tire.uuid
                              )
                                ? tireData.find(
                                    (t) => t.uuid == quotation.vehicle.tire.uuid
                                  ).price
                                : 0) +
                              "€)"
                            }
                            name="customTirePrice"
                            type="number"
                            change={rlChange}
                            value={quotation.customTirePrice}
                            disabled={doesToggleProtectedFields}
                          />
                          <Control
                            label="Catégorie du véhicule"
                            name="vehicleCategory"
                            value={quotation.vehicleCategory}
                            change={rlChange}
                          />
                          <Control
                            label="Franchise dommages, vol, incendie"
                            value={quotation.franchiseDommage}
                            change={rlChange}
                            name="franchiseDommage"
                            suffix={"€"}
                            disabled={doesToggleProtectedFields}
                          />
                          <DynamicList
                            uniqueKey={quotation.uuid}
                            items={quotation.benefits}
                            element="benefits"
                            fields={[
                              { name: "name", type: "text", label: "Nature" },
                              {
                                name: "priceHT",
                                type: "number",
                                label: "Prix HT",
                                suffix: "€",
                              },
                            ]}
                            change={rlChange}
                            btnLabel="Ajouter une prestation"
                          />
                        </div>
                        <div className="col-12 p-2">
                          <h4 className="mt-3">Mentions</h4>
                          <DynamicList
                            uniqueKey={quotation.uuid}
                            items={quotation.mentions}
                            element="mentions"
                            change={rlChange}
                            fields={[{ name: "name", type: "text", label: "" }]}
                          />
                          <div className="d-flex align-items-center">
                            <Control
                              type="select"
                              style={{ maxWidth: 200 }}
                              datas={constantData.QUOTATION_MENTIONS}
                              name="mentionSel"
                              dataIndex="name"
                              dataLabel="name"
                              value={mentionSel}
                              change={(e) => setMentionSel(e.target.value)}
                            />

                            <button
                              style={{
                                height: 30,
                                padding: "0 10px",
                              }}
                              className="btn btn-outline-info ml-2"
                              onClick={addMention}
                            >
                              <i className="fa fa-plus mr-2"></i>Ajouter une
                              mention
                            </button>
                          </div>
                        </div>

                        <div className="col-12 p-2">
                          <Control
                            label="Commentaire"
                            value={quotation.comment}
                            change={rlChange}
                            name="comment"
                            type="textarea"
                          />
                        </div>
                      </div>
                    </div>
                    <ToggleDetailButton
                      onToggleDetails={onToggleDetails}
                      showDetail={showDetail}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </td>
    </tr>
  );
};

const ToggleDetailButton = ({ onToggleDetails, showDetail, bgColor }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center position-relative `}
      style={{
        height: 25,
      }}
    >
      <div
        // className={`bg-${bgColor}`}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "1",
          // background: "linear-gradient(357deg, #f6f8ff 50%, transparent 50%)",
        }}
      ></div>
      <button
        onClick={onToggleDetails}
        className="btn  border rounded-pill btn-light btn-primary mb-0 btn-md"
        style={{
          position: "absolute",
          left: "50%",
          top: "35%",
          transform: "translateX(-50%)",
          zIndex: "9",
        }}
      >
        <i className={"fa fa-angle-" + (showDetail ? "up" : "down")}></i>
      </button>
    </div>
  );
};

export default withRouter(ContractFinalValues);
