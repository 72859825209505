import React from "react";
import useMaintenanceSearch from "./useMaintenanceSearch";
import Control from "../../../common/Control";
import CatalogSelector from "../../../common/CatalogSelector";

const MaintenanceSearch = ({ handlePageParamsChange }) => {
  const { state, maintenanceSearch, actions } = useMaintenanceSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col-12">
          {" "}
          <CatalogSelector
            label="Rechercher par catalogue"
            name="catalogs"
            value={maintenanceSearch.catalogs}
            change={actions.onChangeSearch}
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Rechercher par marque"
            type="select"
            name="brand"
            value={maintenanceSearch.brand}
            change={actions.onChangeSearch}
            datas={state.brand.items}
            dataIndex="uuid"
            dataLabel="name"
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Rechercher par modèle"
            type="select"
            name="brandModel"
            value={maintenanceSearch.brandModel}
            change={actions.onChangeSearch}
            datas={state.brandModel.items.filter((i) => {
              if (maintenanceSearch.brand == "") return true;
              return i.brand.uuid == maintenanceSearch.brand;
            })}
            dataIndex="uuid"
            dataLabel="name"
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default MaintenanceSearch;
