import { useHistory, useLocation } from "react-router-dom";
import useStore from "../../../context/useStore";
import { useState } from "react";
import queryString from "query-string";
import tools from "../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const contractSearchAtom = atom({
  search: "",
  name: "",
});

const useContractSearch = ({ handlePageParamsChange }) => {
  const [state] = useStore();
  const location = useLocation();
  const history = useHistory();

  const [contractSearch, setContractSearch] = useAtom(contractSearchAtom);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(contractSearch);
    },

    onResetSearch: () => {
      setContractSearch({
        search: "",
        name: "",
      });
      const newSearchParams = {
        search: "",
        name: "",
      };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      const newSearchParams = { ...contractSearch, [name]: value };

      setContractSearch({ ...newSearchParams, page: "1" });
    },
  };

  return {
    state,
    contractSearch,
    actions,
  };
};

export default useContractSearch;
