const financierAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return data;
  },
};

export default financierAdapters;
