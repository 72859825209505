import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

const CustomPagination = (props) => {
  const { total, activePage, handlePageParamsChange } = props;

  return (
    <div className="col-12 pt-3">
      <nav aria-label="Page navigation">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={15}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={(page) => handlePageParamsChange({ page: page })}
          innerClass="pagination justify-content-center"
          itemClass="page-item"
          linkClass="page-link"
          activeLinkClass="active"
          prevPageText={<i className="fa fa-arrow-left"></i>}
          nextPageText={<i className="fa fa-arrow-right"></i>}
          hideDisabled={true}
        />
      </nav>
    </div>
  );
};

export default withRouter(CustomPagination);
