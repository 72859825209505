import React, { useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Axios from "axios";
import tools from "../../../helpers/tools";
import {
  navConfig,
  routeCategories,
  routeCategoriesNames,
} from "../../../config/routeConfig";
import Can from "../Can/Can";
import useAccessSettings from "../../../hooks/useInit/useAccessSettings";

const Header = () => {
  const [state, dispatch] = useStore();
  /*   const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(false); */
  var authData = state.auth;
  const actions = authActions(state, dispatch);
  const { accessActions } = useAccessSettings();

  /*   useEffect(() => {
    if (state.auth.isAuthenticated) {
      getAlerts();
    }
  }, [state.auth.isAuthenticated]);

  function getAlerts() {
    if (alertsLoading) {
      return;
    }
    setAlertsLoading(true);

    Axios.get(api_url + "alerts")
      .then((res) => {
        setAlerts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setAlertsLoading(false);
      });
  } */

  return state.auth.isAuthenticated ? (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <img height="30" src="/logo.png" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto" style={{ paddingTop: 13 }}>
          {navConfig.map((category) => {
            if (category.category == routeCategories.ROOT) {
              return category.items.map((item) => {
                return (
                  <Can I={item.requiredPermissions}>
                    <li className="nav-item" key={item.path}>
                      <NavLink to={item.path} className="nav-link">
                        {item.title}
                      </NavLink>
                    </li>
                  </Can>
                );
              });
            }

            const nbAllowedItemsInCategory = category.items.filter((item) => {
              return accessActions.canI({ action: item.requiredPermissions })
                .granted;
            });
            return nbAllowedItemsInCategory == 0 ? null : (
              <li className="nav-item dropdown" key={category.category}>
                <span
                  className="nav-link dropdown-toggle"
                  id={category.category + "NavbarDropdown"}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  {routeCategoriesNames[category.category]}
                </span>
                <div
                  className="dropdown-menu py-0"
                  aria-labelledby={category.category + "NavbarDropdown"}
                >
                  {category.items.map((item) => {
                    return (
                      <Can I={item.requiredPermissions}>
                        <NavLink
                          to={item.path}
                          className="dropdown-item"
                          key={item.path}
                        >
                          {item.title}
                        </NavLink>
                      </Can>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="form-inline">
          {/* {isAdmin && <Alerts alerts={alerts} />} */}
          <div className="d-flex flex-wrap flex-column text-right text-black-50 mr-2">
            <span className=" " style={{ fontSize: 12 }}>
              {authData.user.firstname + " " + authData.user.lastname}
            </span>
            <span
              className=" "
              style={{
                fontSize: 10,
                fontWeight: "400",
              }}
            >
              {
                tools.findIn(
                  state.constant?.items?.ROLES,
                  "id",
                  authData.user?.role
                ).name
              }{" "}
              {authData.user?.userGroup?.name}
            </span>
          </div>
          <button
            className="btn btn-md bg-white"
            onClick={() => actions.logoutUser(true, true)}
          >
            <i className="fa fa-power-off" />
          </button>
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default withRouter(Header);
