import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const CustomSearch = (props) => {
  const { handlePageParamsChange, search } = props;

  const [searchString, setSearchString] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    handlePageParamsChange({ search: searchString });
  }

  return (
    <form onSubmit={onSubmit} className="col-12 pt-3">
      <div className="form-inline">
        <div className="form-group mb-2">
          <label htmlFor="search" className="mr-2">
            Rechercher
          </label>
          <input
            type="text"
            className="form-control"
            name="search"
            id="search"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
          />
          <button
            className="btn btn-primary ml-2"
            type="submit"
            style={{
              maxHeight: "38px",
              padding: "0 20px",
            }}
          >
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(CustomSearch);
