import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import Loader from "../../common/Loader";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import BrandModelSearch from "./BrandModelSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import { useAtom } from "jotai";
import { brandModelSearchAtom } from "./useBrandModelSearch";

const BrandModelList = () => {
  const [state, dispatch] = useStore();

  var actions = brandModelActions(state, dispatch);
  const [brandModelSearch, setBrandModelSearch] = useAtom(brandModelSearchAtom);

  useEffect(() => {
    actions.getBrandModels(brandModelSearch);
  }, []);

  return (
    <CustomList getAction={actions.getBrandModels}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Modèles</h1>
              {state.brandModel.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_MODELE_VEHICULE}>
                  <Link className="btn btn-primary" to="/brandmodels/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un modèle
                  </Link>
                </Can>
              )}
              <BrandModelSearch
                handlePageParamsChange={actions.getBrandModels}
              />
              <NbSearchResults total={state.brandModel.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.brandModel.items.map((brandModel, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{brandModel.brand.name}</td>
                      <td>{brandModel.name}</td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_MODELE_VEHICULE}>
                          <Link
                            className="btn btn-secondary"
                            to={"/brandmodels/" + brandModel.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.brandModel.total}
                activePage={brandModelSearch.page}
                handlePageParamsChange={(data) => {
                  actions.getBrandModels({
                    ...brandModelSearch,
                    ...data,
                  });
                  setBrandModelSearch({
                    ...brandModelSearch,
                    ...data,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default BrandModelList;
