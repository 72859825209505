const finishAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return {
      ...data,
      brandModel: data.brandModel.uuid,
      fuelType: data.fuelType.id,
      catalog: data.catalog?.uuid,
    };
  },
};

export default finishAdapters;
