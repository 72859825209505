export const BRAND_LOGOS = {
  "ALFA ROMEO": "alfa-romeo",
  AUDI: "audi",
  BMW: "bmw",
  CITROEN: "citroen",
  DACIA: "dacia",
  FIAT: "fiat",
  FORD: "ford",
  HYUNDAI: "hyundai",
  JAGUAR: "jaguar",
  JEEP: "jeep",
  KIA: "kia",
};

export function getBrandLogoByName(brandName) {
  const brandLogoKeys = Object.keys(BRAND_LOGOS);
  //find if brandName is in the keys
  //even if there are spaces in the brandName
  const brandLogoKey = brandLogoKeys.find((key) => {
    return (
      key.toLowerCase().replace(/\s/g, "") ===
      brandName.toLowerCase().replace(/\s/g, "")
    );
  });
  return brandLogoKey ? BRAND_LOGOS[brandLogoKey] : null;
}
