import React, { useState } from "react";

const AddColumnModal = (props) => {
  var [value, setValue] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    props.addColumn(value);
    setValue("");
  };

  return (
    <div
      className="modal fade"
      id="addColumnModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <form className="modal-content" onSubmit={onSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Ajouter une colonne
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="periodDate">
                Entrez le nombre de kilomètres de la colonne
              </label>
              <span className="text-danger">
                Entrez uniquement un nombre sans espace (ex : 75000)
              </span>
              <input
                type="number"
                className="form-control"
                name="colValue"
                id="colValue"
                value={value}
                onChange={(e) => setValue(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button type="submit" className="btn btn-success">
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddColumnModal;
