import React, { useEffect } from "react";
import useStore from "../../../context/useStore";
import { finishActions } from "../../../context/actions/finishActions";
import { brandActions } from "../../../context/actions/brandActions";
import FinishForm from "./FinishForm/FinishForm";
import finishAdapters from "../../../adapters/finishAdapters";
import { finishService } from "../../../services/finishService";
import { FINISH_TYPES } from "../../../context/reducers/finishReducers";
import { toast } from "react-toastify";
import tools from "../../../helpers/tools";

const BrandModelFinishes = React.memo(({ brandModelUuid }) => {
  const [state, dispatch] = useStore();
  var fin_actions = finishActions(state, dispatch);
  var brand_actions = brandActions(state, dispatch);
  var finishData = state.finish.items;

  useEffect(() => {
    brand_actions.getAllBrands();
  }, []);

  useEffect(() => {
    fin_actions.getFinishesByBrandModel(brandModelUuid);
  }, [brandModelUuid]);

  const onSaveFinish = async (data) => {
    if (data.uuid) {
      try {
        const result = await finishService.updateFinish(data);
        toast.success("Finition enregistrée");
      } catch (err) {
        toast.error(
          "Erreur lors de la mise à jour de la finition, veuillez vérifier vos saisies"
        );
      }
    } else {
      try {
        const newFinish = await finishService.createFinish(data);
        dispatch({
          type: FINISH_TYPES.UPDATE_FINISHES,
          payload: {
            data: [...finishData, newFinish.data],
          },
        });
        toast.success("Finition enregistrée");
      } catch (err) {
        toast.error(
          "Erreur lors de la création de la finition, veuillez vérifier vos saisies"
        );
      }
    }
  };

  return (
    <div className="mt-4">
      <h4>Finitions liées au modèle</h4>
      {finishData.map((finish, k) => {
        return (
          <FinishForm
            key={finish.uuid}
            finishInitialData={finishAdapters.transformForBO(finish)}
            onSaveFinish={onSaveFinish}
          />
        );
      })}
      <div className="my-4"></div>
      <h5>Ajouter une finition à ce modèle</h5>
      <FinishForm
        finishInitialData={{ brandModel: brandModelUuid }}
        onSaveFinish={onSaveFinish}
      />
    </div>
  );
});

export default BrandModelFinishes;
