import React from "react";
import Control from "./Control";

const DynamicList = ({
  uniqueKey,
  element,
  fields,
  items,
  btnLabel,
  change,
}) => {
  function addItem() {
    var newItem = {};
    fields.forEach((f) => {
      newItem[f.name] = "";
    });
    items.push(newItem);
    change({ target: { name: element, value: items } });
  }

  function changeItem(e, k) {
    items[k][e.target.name] = e.target.value;
    change({ target: { name: element, value: items } });
  }
  function removeItem(kk) {
    items = items.filter((i, k) => k !== kk);
    change({ target: { name: element, value: items } });
  }

  return (
    <>
      <ul className="list-group list-unstyled">
        {items.map((it, k) => (
          <li key={"dyn" + element + uniqueKey + "_" + k}>
            <div className="d-flex">
              {fields.map((fi, kk) => (
                <Control
                  className="mr-2 flex-fill"
                  k={k}
                  key={"dynlistinp" + kk}
                  label={fi.label}
                  name={fi.name}
                  type={fi.type}
                  value={it[fi.name]}
                  change={changeItem}
                  suffix={fi.suffix ? fi.suffix : null}
                />
              ))}
              <button
                className="btn btn-default btn-sm shadow-none border-0"
                onClick={() => removeItem(k)}
              >
                <i className="fa fa-times text-danger" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      {btnLabel && (
        <button className="btn btn-default btn-sm mt-2" onClick={addItem}>
          <i className="fa fa-plus mr-2" />
          {btnLabel}
        </button>
      )}
    </>
  );
};

export default DynamicList;
