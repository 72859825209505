const catalogStoreAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return { ...data, geographicZone: data.geographicZone.id };
  },
};

export default catalogStoreAdapters;
