import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { CATALOG_TYPES } from "../reducers/catalogReducers";
import { catalogService } from "../../services/catalogService";
import catalogAdapters from "../../adapters/catalogAdapters";

export const catalogActions = (state, dispatch, props) => {
  function getCatalogs(search = { search: null }) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      brand: search.brand,
      brandModel: search.brandModel,
      brandModelFinish: search.brandModelFinish,
      catalogs: search.catalogs,
      page: search.page,
    });

    catalogService
      .getCatalogs(search)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOGS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }
  function getCatalog(uuid) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogService
      .getCatalog(uuid)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: catalogAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }
  function createCatalog(catalogData, isDuplicate = false) {
    if (isDuplicate) {
      if (
        !window.confirm(
          "Êtes-vous certain(e) de vouloir dupliquer ce véhicule ?"
        )
      ) {
        return false;
      }
      delete catalogData.uuid;
      catalogData.optionsNotIncluded = catalogData.optionsNotIncluded.map(
        (opt) => ({ ...opt, uuid: null })
      );
      //virer les uuid des options
      catalogData.immat = "Copie de " + catalogData.immat;
    }
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogService
      .createCatalog(catalogData)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: catalogAdapters.transformForBO(res.data),
        });
        tools.filterAlert("success", "Ce véhicule a été correctement créé.");
        if (props.modal) {
          props.selectCatalog(res.data);
        } else {
          props.history.push("/catalogs/" + res.data.uuid);
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }

  function updateCatalog(catalogData) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogService
      .updateCatalog(catalogData)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: catalogAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          "Ce véhicule a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }

  return {
    createCatalog,
    updateCatalog,
    getCatalogs,
    getCatalog,
  };
};
