import React from "react";
import useFinishSearch from "./useFinishSearch";
import CatalogSelector from "../../../common/CatalogSelector";

const FinishSearch = ({ handlePageParamsChange }) => {
  const { state, finishSearch, actions } = useFinishSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col-12">
          <CatalogSelector
            label="Rechercher dans les catalogues"
            name="catalogs"
            change={actions.onChangeSearch}
            value={finishSearch.catalogs}
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group mb-2">
            <label htmlFor="search" className="mr-2">
              Rechercher par finition
            </label>
            <input
              type="text"
              className="form-control"
              name="search"
              id="search"
              onChange={actions.onChangeSearch}
              value={finishSearch.search}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label htmlFor="brandModel" className="mr-2">
            Rechercher par modèle
          </label>
          <select
            className="form-control"
            name="brandModel"
            id="brandModel"
            onChange={actions.onChangeSearch}
            value={finishSearch.brandModel}
          >
            <option value="">...</option>
            {state.brandModel.items.map((brandModel, k) => (
              <option
                key={"brandModel-" + brandModel.uuid}
                value={brandModel.uuid}
              >
                {brandModel.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default FinishSearch;
