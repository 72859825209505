import axios from "axios";
import { api_url } from "../config/config";
import financierAdapters from "../adapters/financierAdapters";

export const financierService = {
  getAllFinanciers: () => {
    return axios.get(`${api_url}financiers/search?paginate=0`);
  },
  getFinanciers: (search) => {
    return axios.get(`${api_url}financiers/search${search}`);
  },
  getFinancier: (uuid) => {
    return axios.get(`${api_url}financiers/${uuid}`);
  },
  createFinancier: (data) => {
    return axios.post(
      `${api_url}financiers`,
      financierAdapters.transformForAPI(data)
    );
  },
  updateFinancier: (data) => {
    return axios.put(
      `${api_url}financiers/${data.uuid}`,
      financierAdapters.transformForAPI(data)
    );
  },
  deleteFinancier: (uuid) => {
    return axios.delete(`${api_url}financiers/${uuid}`);
  },
};
