import React, { useEffect, useState } from "react";
import Loader from "../components/common/Loader";
import useStore from "../context/useStore";
import { api_url } from "../config/config";
import tools from "../helpers/tools";
import axios from "axios";
import { AUTH_TYPES } from "../context/reducers/authReducers";
import { authActions } from "../context/actions/authActions";

const AuthMiddleware = ({ children }) => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);
  const [isUserChecked, setIsUserChecked] = useState(false);

  useEffect(() => {
    checkUserConnected();
  }, []);

  const checkUserConnected = async () => {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      tools.setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      // Set user and isAuthenticated
      try {
        const result = await axios.get(`${api_url}user`);

        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: result.data,
        });
        setIsUserChecked(true);
        return;
      } catch (err) {}
    }
    auth_actions.logoutUser();
    setIsUserChecked(true);
  };

  if (!isUserChecked) return <Loader />;

  return children;
};

export default AuthMiddleware;
