import axios from "axios";
import { api_url } from "../config/config";
import userAdapters from "../adapters/userAdapters";

export const userService = {
  getAllUsers: () => {
    return axios.get(`${api_url}users/search?paginate=0`);
  },
  getUsers: (search) => {
    return axios.get(`${api_url}users/search${search}`);
  },
  getUser: (uuid) => {
    return axios.get(`${api_url}users/${uuid}`);
  },
  createUser: (data) => {
    return axios.post(`${api_url}users`, userAdapters.transformForAPI(data));
  },
  updateUser: (data) => {
    return axios.put(
      `${api_url}users/${data.uuid}`,
      userAdapters.transformForAPI(data)
    );
  },
  deleteUser: (uuid) => {
    return axios.delete(`${api_url}users/${uuid}`);
  },
};
