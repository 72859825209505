import React from "react";
import { withRouter, Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { metas } from "../../../config/metas";

const BreadCrumb = (props) => {
  const [state] = useStore();

  function getMetas() {
    var tags = [];
    var routes = props.location.pathname.split("/");

    routes.forEach((route, k) => {
      if (metas.find((meta) => meta.pathname == route)) {
        var rte = metas.find((meta) => meta.pathname == route);
        if (k + 1 < routes.length) {
          tags.push(
            <li key={"bc-" + k} className="breadcrumb-item">
              <Link to={"/" + rte.pathname}>{rte.title}</Link>
            </li>
          );
        } else {
          tags.push(
            <li key={"bc-" + k} className="breadcrumb-item active">
              {rte.title}
            </li>
          );
        }
      } else {
        if (route != "") {
          var rte = metas.find((meta) => meta.pathname == routes[k - 1]);
          if (rte) {
            tags.push(
              <li key={"bc-" + k} className="breadcrumb-item active">
                {rte.child}
              </li>
            );
          }
        }
      }
    });
    return tags;
  }

  const breadcrumbItems = getMetas();
  return (
    state.auth.isAuthenticated && (
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center mb-3">
              {breadcrumbItems.length > 1 && (
                <Link to="." className="px-3 py-1 btn-primary btn mr-2 mb-0">
                  <i className="fa fa-arrow-left" />
                </Link>
              )}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 py-0 mt-0 pl-0">
                  {breadcrumbItems}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default withRouter(BreadCrumb);
