import React from "react";
import QuotationList from "../Quotation/QuotationList";

const Dashboard = () => {
  return (
    <div className="container">
      <div className="row mb-4">
        <QuotationList />
      </div>
    </div>
  );
};

export default Dashboard;
