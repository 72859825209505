import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { userActions } from "../../../context/actions/userActions";
import Loader from "../../common/Loader";
import { USER_TYPES } from "../../../context/reducers/userReducers";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import { groupActions } from "../../../context/actions/groupActions";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  var userData = state.user.item;
  var constantData = state.constant.items;

  var actions = userActions(state, dispatch, props);
  const group_actions = groupActions(state, dispatch, props);

  useEffect(() => {
    if (props.match.params.uuid != "create") {
      actions.getUser(props.match.params.uuid);
    }
    group_actions.getAllGroups();
  }, []);

  function inputChange(e) {
    if (e.target.name == "userGroup") {
      tools.inputChange(
        {
          target: {
            name: "role",
            value: "",
          },
        },
        USER_TYPES.UPDATE_USER_DATA,
        dispatch
      );
    }
    tools.inputChange(e, USER_TYPES.UPDATE_USER_DATA, dispatch);
  }

  let allowedRoles = [];
  if (userData.userGroup) {
    const userGroupRef = state.group.items.find(
      (g) => g.uuid == userData.userGroup
    );
    allowedRoles = !userGroupRef?.isAdmin
      ? constantData.ROLES.filter(
          (r) => !["SUPER_ADMIN", "ADMIN"].includes(r.id)
        )
      : constantData.ROLES;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un utilisateur</h1>
          <Control
            label="Groupe :"
            name="userGroup"
            type="select"
            datas={state.group.items}
            dataIndex="uuid"
            dataLabel="name"
            value={userData.userGroup}
            change={inputChange}
            error={state.error}
            required
          />
          <Control
            label="Nom :"
            name="lastname"
            type="text"
            value={userData.lastname}
            change={inputChange}
            error={state.error}
            disabled={!userData.userGroup}
            required
          />
          <Control
            label="Prénom :"
            name="firstname"
            type="text"
            value={userData.firstname}
            change={inputChange}
            error={state.error}
            disabled={!userData.userGroup}
            required
          />
          <Control
            label="Téléphone :"
            name="phone"
            type="tel"
            pattern="[0-9]{10}"
            value={userData.phone}
            change={inputChange}
            error={state.error}
            disabled={!userData.userGroup}
          />
          <Control
            label="Email :"
            name="email"
            type="email"
            value={userData.email}
            change={inputChange}
            error={state.error}
            required
            disabled={!userData.userGroup}
          />
          <Control
            label="Mot de passe (laissez vide pour ne pas le modifier) :"
            name="password"
            type="password"
            value={userData.password}
            change={inputChange}
            error={state.error}
            required
            disabled={!userData.userGroup}
          />

          <Control
            label="Rôle :"
            name="role"
            type="select"
            value={userData.role}
            change={inputChange}
            error={state.error}
            datas={allowedRoles}
            dataIndex="id"
            dataLabel="name"
            required
            disabled={!userData.userGroup}
          />

          {state.user.isLoading ? (
            <Loader />
          ) : props.match.params.uuid == "create" ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.createUser(userData)}
              disabled={!userData.userGroup}
            >
              Créer
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.updateUser(userData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserDetail);
