import ClientModal from "../components/common/ClientModal";
import VehicleModal from "../components/common/VehicleModal";
import BrandDetail from "../components/pages/Brand/BrandDetail";
import BrandList from "../components/pages/Brand/BrandList";
import BrandModelDetail from "../components/pages/BrandModel/BrandModelDetail";
import BrandModelList from "../components/pages/BrandModel/BrandModelList";
import CatalogDetail from "../components/pages/Catalog/CatalogDetail";
import CatalogList from "../components/pages/Catalog/CatalogList";
import CatalogStoreDetail from "../components/pages/CatalogStore/CatalogStoreDetail";
import CatalogStoreList from "../components/pages/CatalogStore/CatalogStoreList";
import ClientList from "../components/pages/Client/CilentList";
import ClientDetail from "../components/pages/Client/ClientDetail";
import ConstantDynamic from "../components/pages/ConstantDynamic/ConstantDynamic";
import ContractList from "../components/pages/Contract/ContractList";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import FinanceDetail from "../components/pages/Finance/FinanceDetail";
import FinanceList from "../components/pages/Finance/FinanceList";
import FinancierDetail from "../components/pages/Financiers/FinancierDetail";
import FinancierList from "../components/pages/Financiers/FinancierList";
import FinishDetail from "../components/pages/Finish/FinishDetail";
import FinishList from "../components/pages/Finish/FinishList";
import GroupDetail from "../components/pages/Group/GroupDetail";
import GroupList from "../components/pages/Group/GroupList";
import Login from "../components/pages/Login/Login";
import MaintenanceDetail from "../components/pages/Maintenance/MaintenanceDetail";
import MaintenanceList from "../components/pages/Maintenance/MaintenanceList";
import QuotationDetail from "../components/pages/Quotation/QuotationDetail";
import QuotationList from "../components/pages/Quotation/QuotationList";
import RepurchaseDetail from "../components/pages/Repurchase/RepurchaseDetail";
import RepurchaseList from "../components/pages/Repurchase/RepurchaseList";
import TireDetail from "../components/pages/Tire/TireDetail";
import UserDetail from "../components/pages/User/UserDetail";
import UserList from "../components/pages/User/UserList";
import VehicleDetail from "../components/pages/Vehicle/VehicleDetail";
import VehicleList from "../components/pages/Vehicle/VehicleList";
import { userAccessEnum } from "./accessEnum";

export const routeCategories = {
  ROOT: "ROOT",
  VEHICLES: "VEHICLES",
  USERS: "USERS",
  CONSTANTS: "CONSTANTS",
};

export const routeCategoriesNames = {
  ROOT: "Accueil",
  VEHICLES: "Véhicules",
  USERS: "Utilisateurs",
  CONSTANTS: "Paramètres",
};

const constantsRoutes = [
  {
    path: "/catalogstores",
    component: CatalogStoreList,
    exact: true,
    title: "Catalogues",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_CATALOGUE],
  },
  {
    path: "/catalogstores/:uuid",
    component: CatalogStoreDetail,
    exact: true,
    title: "Édition d'un catalogue",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_CATALOGUE],
  },
  {
    path: "/brands",
    component: BrandList,
    exact: true,
    title: "Marques",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_MARQUE_VEHICULE],
  },
  {
    path: "/brands/:uuid",
    component: BrandDetail,
    exact: true,
    title: "Édition d'une marque",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_MARQUE_VEHICULE],
  },
  {
    path: "/brandmodels",
    component: BrandModelList,
    exact: true,
    title: "Modèles",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_MODELE_VEHICULE],
  },
  {
    path: "/brandmodels/:uuid",
    component: BrandModelDetail,
    exact: true,
    title: "Édition d'un modèle",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_MODELE_VEHICULE],
  },
  {
    path: "/finishes",
    component: FinishList,
    exact: true,
    title: "Finitions",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_FINITION_VEHICULE],
  },
  {
    path: "/finishes/:uuid",
    component: FinishDetail,
    exact: true,
    title: "Édition d'une finition",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
  },
  {
    path: "/tires",
    component: TireDetail,
    exact: true,
    title: "Pneumatiques",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_PNEU],
  },
  {
    path: "/repurchases",
    component: RepurchaseList,
    exact: true,
    title: "Valeurs de rachat",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_VALEUR_RACHAT],
  },
  {
    path: "/repurchases/:uuid",
    component: RepurchaseDetail,
    exact: true,
    title: "Édition d'une valeur de rachat",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_VALEUR_RACHAT],
  },
  {
    path: "/maintenances",
    component: MaintenanceList,
    exact: true,
    title: "Entretiens",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_TABLEAU_ENTRETIEN],
  },
  {
    path: "/maintenances/:uuid",
    component: MaintenanceDetail,
    exact: true,
    title: "Édition d'un entretien",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_TABLEAU_ENTRETIEN],
  },
  {
    path: "/constants",
    component: ConstantDynamic,
    exact: true,
    title: "Constantes dynamiques",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.MODIFIER_CONSTANTE_DYNAMIQUE],
  },
  {
    path: "/financiers",
    component: FinancierList,
    exact: true,
    title: "Financiers",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CHERCHER_FINANCIER],
  },
  {
    path: "/financiers/:uuid",
    component: FinancierDetail,
    exact: true,
    title: "Édition d'un financier",
    private: true,
    category: routeCategories.CONSTANTS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.CREER_FINANCIER],
  },
];

const vehiclesRoutes = [
  {
    path: "/vehicles",
    component: VehicleList,
    exact: true,
    title: "Véhicules de flotte",
    private: true,
    category: routeCategories.VEHICLES,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CHERCHER_FLOTTE_VEHICULE],
  },
  {
    path: "/vehicles/:uuid",
    component: VehicleDetail,
    exact: true,
    title: "Édition d'un véhicule de flotte",
    private: true,
    category: routeCategories.VEHICLES,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_FLOTTE_VEHICULE],
  },
  {
    path: "/catalogs",
    component: CatalogList,
    exact: true,
    title: "Véhicules de catalogue",
    private: true,
    category: routeCategories.VEHICLES,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_VEHICULE_CATALOGUE],
  },
  {
    path: "/catalogs/:uuid",
    component: CatalogDetail,
    exact: true,
    title: "Édition d'un véhicule de catalogue",
    private: true,
    category: routeCategories.VEHICLES,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_VEHICULE_CATALOGUE],
  },
];

const userRoutes = [
  {
    path: "/groups",
    component: GroupList,
    exact: true,
    title: "Groupes",
    private: true,
    category: routeCategories.USERS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_GROUPE_UTILISATEUR],
  },
  {
    path: "/groups/:uuid",
    component: GroupDetail,
    exact: true,
    title: "Édition d'un groupe",
    private: true,
    category: routeCategories.USERS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_GROUPE_UTILISATEUR],
  },
  {
    path: "/users",
    component: UserList,
    exact: true,
    title: "Utilisateurs",
    private: true,
    category: routeCategories.USERS,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_UTILISATEUR],
  },
  {
    path: "/users/:uuid",
    component: UserDetail,
    exact: true,
    title: "Édition d'un utilisateur",
    private: true,
    category: routeCategories.USERS,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_UTILISATEUR],
  },
];

export const routeConfig = [
  {
    path: "/",
    component: Dashboard,
    exact: true,
    title: "Accueil",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
    requiredPermissions: "*",
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    title: "Login",
    private: false,
    category: routeCategories.ROOT,
    inNavbar: false,
    requiredPermissions: "*",
  },

  {
    path: "/clients",
    component: ClientList,
    exact: true,
    title: "Clients",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_CLIENT],
  },
  {
    path: "/clients/:uuid",
    component: ClientDetail,
    exact: true,
    title: "Édition d'un client",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_CLIENT],
  },
  {
    path: "/quotations",
    component: QuotationList,
    exact: true,
    title: "Devis",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CHERCHER_DOSSIER],
  },
  {
    path: "/quotations/:uuid",
    component: QuotationDetail,
    exact: true,
    title: "Édition d'un devis",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_DOSSIER],
  },
  {
    path: "/contracts",
    component: ContractList,
    exact: true,
    title: "Contrats",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CHERCHER_CONTRAT],
  },

  {
    path: "/vehiclemodal",
    component: VehicleModal,
    exact: true,
    title: "Ajout d'un véhicule",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
  },
  {
    path: "/clientmodal",
    component: ClientModal,
    exact: true,
    title: "Ajout d'un client",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
  },
  {
    path: "/finances",
    component: FinanceList,
    exact: true,
    title: "Financements",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: true,
    requiredPermissions: [userAccessEnum.CREER_FINANCEMENT],
  },
  {
    path: "/finances/:uuid",
    component: FinanceDetail,
    exact: true,
    title: "Édition d'un financement",
    private: true,
    category: routeCategories.ROOT,
    inNavbar: false,
    requiredPermissions: [userAccessEnum.MODIFIER_FINANCEMENT],
  },
  ...constantsRoutes,
  ...vehiclesRoutes,
  ...userRoutes,
];

export const navConfig = [
  {
    category: routeCategories.ROOT,
    items: [
      ...routeConfig.filter(
        (route) => route.inNavbar && route.category === routeCategories.ROOT
      ),
    ],
  },

  {
    category: routeCategories.VEHICLES,
    items: [
      ...routeConfig.filter(
        (route) => route.inNavbar && route.category === routeCategories.VEHICLES
      ),
    ],
  },
  {
    category: routeCategories.USERS,
    items: [
      ...routeConfig.filter(
        (route) => route.inNavbar && route.category === routeCategories.USERS
      ),
    ],
  },

  {
    category: routeCategories.CONSTANTS,
    items: [
      ...routeConfig.filter(
        (route) =>
          route.inNavbar && route.category === routeCategories.CONSTANTS
      ),
    ],
  },
];
