import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { clientActions } from "../../../context/actions/clientActions";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import { CLIENT_TYPES } from "../../../context/reducers/clientReducers";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";
import OverlayContent from "../../common/OverlayContent";
import ClientCgvPdf from "./ClientCgvPdf";
import { RoleBadge } from "../Quotation/QuotationList";
import UserModal from "../../common/UserModal";
import { clientService } from "../../../services/clientService";
import { toast } from "react-toastify";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";

const ClientDetail = (props) => {
  const [state, dispatch] = useStore();
  const [cgvOpen, setCgvOpen] = useState(false);

  var actions = clientActions(state, dispatch, props);
  var constantDatas = state.constant.items;

  useEffect(() => {
    if (props.match.params.uuid != "create" && !props.modal) {
      actions.getClient(props.match.params.uuid);
    }
  }, []);

  const onChangeUserAssigned = async (user) => {
    try {
      const res = clientService.reassignClient(
        state.client.item.uuid,
        user.uuid
      );
      toast.success("Ce client a été rattaché à un nouvel utilisateur");
      actions.getClient(props.match.params.uuid);
    } catch (err) {
      console.log(err);
    }
  };

  function inputChange(e) {
    tools.inputChange(e, CLIENT_TYPES.UPDATE_CLIENT_DATA, dispatch);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {!props.modal && <h1>Edition d'un client</h1>}
        </div>
      </div>
      <div className="row">
        <Can I={userAccessEnum.REASSIGNER_CLIENT}>
          {props.match.params.uuid != "create" && (
            <div className="col-12 mb-2">
              Ce client est rattaché à :{" "}
              <UserModal
                selectedUser={state.client.item.user}
                selectUser={onChangeUserAssigned}
              />
            </div>
          )}
        </Can>
        <div className="col-12 col-md-6">
          <Control
            label="Raison sociale"
            type="text"
            name="name"
            required={true}
            value={state.client.item.name}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Ville"
            type="text"
            name="city"
            value={state.client.item.city}
            change={inputChange}
            error={state.error}
          />
          <small className="form-text text-danger">
            {state.error["address.city"]}
          </small>
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Code postal"
            type="text"
            name="postalCode"
            value={state.client.item.postalCode}
            required
            change={inputChange}
            error={state.error}
          />
          <small className="form-text text-danger">
            {state.error["address.postalCode"]}
          </small>
        </div>

        <div className="col-12 col-md-6">
          <Control
            label="Adresse 1"
            type="text"
            name="address"
            value={state.client.item.address}
            change={inputChange}
            error={state.error}
          />
          <small className="form-text text-danger">
            {state.error["address.address"]}
          </small>
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Adresse 2"
            type="text"
            name="address2"
            value={state.client.item.address2}
            change={inputChange}
            error={state.error}
          />
          <small className="form-text text-danger">
            {state.error["address.address2"]}
          </small>
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Adresse 3"
            type="text"
            name="address3"
            value={state.client.item.address3}
            change={inputChange}
            error={state.error}
          />
          <small className="form-text text-danger">
            {state.error["address.address3"]}
          </small>
        </div>

        <div className="col-12 col-md-6">
          <Control
            label="Forme juridique"
            type="select"
            name="legalForm"
            value={state.client.item.legalForm}
            change={inputChange}
            datas={constantDatas.LEGAL_FORMS || []}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="SIREN / SIRET"
            type="text"
            name="siret"
            value={state.client.item.siret}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Nom du contact"
            type="text"
            name="lastname"
            value={state.client.item.lastname}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Prénom du contact"
            type="text"
            name="firstname"
            value={state.client.item.firstname}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Email"
            type="text"
            name="email"
            value={state.client.item.email}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Téléphone"
            type="tel"
            name="phone"
            value={state.client.item.phone}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Décision Euler Hermes"
            type="text"
            name="eulerHermes"
            value={state.client.item.eulerHermes}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Date de décision Euler Hermes"
            type="date"
            name="eulerHermesDate"
            value={DateTime.fromISO(state.client.item.eulerHermesDate).toFormat(
              "yyyy-MM-dd"
            )}
            change={inputChange}
            error={state.error}
          />
        </div>
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="comment">Commentaires</label>
            <textarea
              className="form-control"
              name="comment"
              id="comment"
              onChange={inputChange}
              value={state.client.item.comment}
            />
            <small className="form-text text-danger">
              {state.error.comment}
            </small>
          </div>
        </div>
      </div>

      {state.client.isLoading ? (
        <Loader />
      ) : props.match.params.uuid == "create" || props.modal ? (
        <button
          type="button"
          className="btn btn-success"
          onClick={() => actions.createClient(state.client.item)}
        >
          Créer {props.modal && "et choisir"}
        </button>
      ) : (
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.updateClient(state.client.item)}
            >
              Enregistrer
            </button>
          </div>
          <div className="col-6 text-right">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => actions.getClientCGV(state.client.item)}
            >
              <i className="fa  fa-file-pdf mr-2"></i>Télécharger les CGV
            </button>
          </div>
        </div>
      )}
      {cgvOpen && (
        <OverlayContent close={() => setCgvOpen(false)}>
          <ClientCgvPdf client={state.client.item} />
        </OverlayContent>
      )}
    </div>
  );
};

export default withRouter(ClientDetail);
