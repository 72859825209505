import React, { useEffect } from "react";
import useStore from "../../../context/useStore";
import { withRouter } from "react-router-dom";
import { financierActions } from "../../../context/actions/financierActions";
import tools from "../../../helpers/tools";
import { FINANCIER_TYPES } from "../../../context/reducers/financierReducers";
import Loader from "../../common/Loader";
import Control from "../../common/Control";

const FinancierDetail = (props) => {
  const [state, dispatch] = useStore();

  var actions = financierActions(state, dispatch, props);
  const { item: financierData, isLoading } = state.financier;
  useEffect(() => {
    if (props.match.params.uuid !== "create") {
      actions.getFinancier(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, FINANCIER_TYPES.UPDATE_FINANCIER_DATA, dispatch);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un financeur</h1>

          <Control
            label="Nom"
            type="text"
            name="name"
            value={financierData.name}
            change={inputChange}
            error={state.error}
          />
          <Control
            label="Enveloppe"
            type="number"
            name="envelop"
            suffix="€"
            value={financierData.envelop}
            change={inputChange}
            error={state.error}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.saveFinancier(financierData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FinancierDetail);
