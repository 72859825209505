import { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import { tireActions } from "../../../../context/actions/tireActions";
import tools from "../../../../helpers/tools";
import { QUOTATION_TYPES } from "../../../../context/reducers/quotationReducers";
import vehicleTools from "../../../../helpers/vehicleTools";
import { quotationActions } from "../../../../context/actions/quotationActions";
import { api_url } from "../../../../config/config";
import { quotationService } from "../../../../services/quotationService";
import { clientService } from "../../../../services/clientService";
import { toast } from "react-toastify";
import useTVA from "../../../../hooks/useInit/useTVA";
import { atom, useAtom } from "jotai";
import useRoleStyle from "./useRoleStyle";

const tableWidthAtom = atom(100);
const isParametersOpenAtom = atom(false);

const useQuotationDetail = (props) => {
  const [state, dispatch] = useStore();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [dur, setDur] = useState("0");
  const [km, setKm] = useState("0");
  const [quotationOpened, setQuotationOpened] = useState({ q: null, i: null });
  const [overlayOpened, setOverlayOpened] = useState(null);
  const [sort, setSort] = useState([]);
  const [filters, setFilters] = useState({
    brand: "",
    brandModel: "",
    duration: "",
    km: "",
    status: "",
    finish: "",
  });
  const [hideKeys, setHideKeys] = useState([]);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [recapOpen, setRecapOpen] = useState(false);
  const [quotationSelected, setQuotationSelected] = useState([]);
  const [tableWidth, setTableWidth] = useAtom(tableWidthAtom);
  const [openClientQuotationModal, setOpenClientQuotationModal] =
    useState(false);
  const [clientQuotations, setClientQuotations] = useState([]);
  const [isParametersOpen, setIsParametersOpen] = useAtom(isParametersOpenAtom);

  var authData = state.auth;
  var quotationData = state.quotation.item;
  var constantData = state.constant.items;

  const [clientData, setClientData] = useState([]);
  var tireData = state.tire.items;
  var actions = quotationActions(state, dispatch, props);
  var t_actions = tireActions(state, dispatch, props);
  const { getTVAByCatalog } = useTVA();

  const roleStyle = useRoleStyle();

  useEffect(() => {
    if (quotationData.client) {
      if (!selectedClient) {
        setSelectedClient(
          clientData.find((c) => quotationData.client.uuid == c.uuid)
        );
      }
    }
  }, [quotationData, clientData]);
  useEffect(() => {
    if (!selectedUser) {
      setSelectedUser(quotationData.assignedTo);
    }
  }, [quotationData]);
  useEffect(() => {
    if (selectedClient && props.match.params.uuid == "create") {
      quotationService
        .getClientQuotations(selectedClient.uuid)
        .then((res) => {
          if (res.data.length > 0) {
            setOpenClientQuotationModal(true);
            setClientQuotations(res.data);
            window.$("#clientQuotationModal").modal("show");
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) console.log(err.response.data);
        });
    }
  }, [selectedClient]);
  useEffect(() => {
    var sortQuotations = tools.sortQuotations(sort, quotationData.quotations);
    quotationDetailActions.inputChange({
      target: { name: "quotations", value: sortQuotations },
    });
  }, [sort]);
  useEffect(() => {
    setHideKeys(tools.filterQuotations(filters, quotationData.quotations));
  }, [filters]);
  useEffect(() => {
    dispatch({
      type: QUOTATION_TYPES.RESET_QUOTATION,
    });
    if (props.match.params.uuid != "create") {
      actions.getQuotation(props.match.params.uuid);
    }
    t_actions.getTires();
    clientService
      .getAllClients()
      .then((res) => {
        setClientData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (selectedClient && quotationData.client !== selectedClient.uuid) {
      quotationDetailActions.inputChange({
        target: { name: "client", value: selectedClient.uuid },
      });
    }

    if (
      selectedVehicle &&
      (!quotationData.vehicle ||
        quotationData.vehicle.uuid !== selectedVehicle.uuid)
    ) {
      quotationDetailActions.inputChange({
        target: { name: "vehicle", value: selectedVehicle },
      });
    }
  }, [selectedClient, selectedVehicle]);
  useEffect(() => {
    if (selectedUser && quotationData.client !== selectedUser.uuid) {
      quotationDetailActions.inputChange({
        target: { name: "assignedTo", value: selectedUser.uuid },
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (overlayOpened != null) {
      document.getElementById("trrl" + overlayOpened).scrollIntoView();
    }
  }, [overlayOpened]);

  const quotationDetailActions = {
    inputChange: (e) => {
      tools.inputChange(e, QUOTATION_TYPES.UPDATE_QUOTATION_DATA, dispatch);
    },
    rlChange: (e, k) => {
      var { quotations } = quotationData;
      if (
        (e.target.name == "repurchaseValueFinal" ||
          e.target.name == "baseRentHT") &&
        e.target.value == 0
      ) {
        return false;
      }
      if (e.target.name == "marginTTCRate") {
        if (
          parseFloat(e.target.value) <
            parseFloat(constantData.MARGIN_RATE_MIN) &&
          authData.user.role != "SUPER_ADMIN"
        ) {
          e.target.value = constantData.MARGIN_RATE_MIN;
        }
        quotations[k]["marginTTC"] = vehicleTools.calcMargin(
          quotations[k].vehicle["investAmtHT"],
          e.target.value
        );
      }
      if (e.target.name == "marginTTC") {
        var marginRate = vehicleTools.calcMarginRate(
          quotations[k].vehicle["investAmtHT"],
          e.target.value
        );

        if (parseFloat(marginRate) < parseFloat(constantData.MARGIN_RATE_MIN)) {
          if (authData.user.role != "SUPER_ADMIN") {
            e.target.value = vehicleTools.calcMargin(
              quotations[k].vehicle["investAmtHT"],
              quotations[k].marginTTCRate
            );
          } else {
            quotations[k]["marginTTCRate"] = marginRate;
          }
        } else {
          quotations[k]["marginTTCRate"] = marginRate;
        }
      }
      if (
        e.target.name == "replacementVehicleCost" &&
        parseFloat(e.target.value) < 0
      ) {
        e.target.value = 0;
      }
      if (
        e.target.name == "financeRate" &&
        parseFloat(e.target.value) < parseFloat(constantData.FINANCE_RATE_MIN)
      ) {
        e.target.value = constantData.FINANCE_RATE_MIN;
      }
      var { quotations } = quotationData;
      quotations[k][e.target.name] = e.target.value;
      if (e.target.name == "customTirePrice") {
        var tirePrice =
          Math.round(
            ((parseFloat(e.target.value) * parseFloat(quotations[k].nbTires)) /
              parseFloat(quotations[k].dur)) *
              100
          ) / 100;
        if (quotations[k].benefits.find((bn) => bn.name == "Pneus")) {
          quotations[k].benefits.find((bn) => bn.name == "Pneus").priceHT =
            tirePrice;
        } else {
          quotations[k].benefits.push({ name: "Pneus", priceHT: tirePrice });
        }
      }
      if (e.target.name == "nbTires") {
        const tireRef = tireData.find(
          (t) => t.uuid == quotations[k].vehicle.tire.uuid
        );
        if (tireRef) {
          var tirePrice = tireRef.price;
          quotations[k].customTirePrice = tirePrice;
          tirePrice =
            Math.round(
              ((parseFloat(tirePrice) * parseFloat(e.target.value)) /
                parseFloat(quotations[k].dur)) *
                100
            ) / 100;
          if (quotations[k].benefits.find((bn) => bn.name == "Pneus")) {
            quotations[k].benefits.find((bn) => bn.name == "Pneus").priceHT =
              tirePrice;
          } else {
            quotations[k].benefits.push({ name: "Pneus", priceHT: tirePrice });
          }
        } else {
          quotations[k][e.target.name] = 0;
        }
      }

      quotationDetailActions.inputChange({
        target: { name: "quotations", value: quotations },
      });
    },
    rlVehicleChange: (e, k) => {
      var { quotations } = quotationData;
      const { TVA, TVA_INVERT } = getTVAByCatalog(
        quotations[k].vehicle.catalog.uuid
      );
      quotations[k].vehicle[e.target.name] =
        e.target.value != "" ? e.target.value : 0;
      var rmOptions = quotations[k].vehicle.optionsNotIncluded.reduce(
        (a, b) => {
          if (b.isInclRM) {
            return a + parseFloat(b.priceHT) * TVA_INVERT;
          } else {
            return a;
          }
        },
        0
      );
      if (e.target.name == "discount") {
        quotations[k].vehicle["discountPct"] =
          Math.round(
            (parseFloat(e.target.value) /
              (quotations[k].vehicle.pricePublicTTC + rmOptions)) *
              100 *
              100
          ) / 100;
      }
      if (e.target.name == "discountPct") {
        quotations[k].vehicle["discount"] =
          Math.round(
            ((parseFloat(e.target.value) *
              (quotations[k].vehicle.pricePublicTTC + rmOptions)) /
              100) *
              100
          ) / 100;
      }
      if (e.target.name == "priceNetTTC" && isNaN(e.target)) {
        return false;
      }
      quotationDetailActions.inputChange({
        target: { name: "quotations", value: quotations },
      });
    },
    setQuotationStatus: (k, status, quantity = 0, caracs = []) => {
      var sid = props.match.params.uuid;
      var qid = quotationData.quotations[k].uuid;

      if (status == 3) {
        if (!parseInt(quantity) > 0) return false;
      }

      actions.updateQuotationStatus(
        sid,
        qid,
        status,
        parseInt(quantity),
        caracs
      );
    },
    deleteQuotation: (k) => {
      if (
        window.confirm(
          "Êtes-vous sûr de vouloir supprimer cette entrée ? Pour que la suppression soit définitive, cliquez sur OK puis sur le bouton enregistrer"
        )
      ) {
        var { quotations } = quotationData;
        quotations = quotations.filter((q, i) => k !== i);
        setOverlayOpened(null);
        quotationDetailActions.inputChange({
          target: { name: "quotations", value: quotations },
        });
      }
    },
    addRollingLaw: () => {
      var { quotations } = quotationData;
      for (const key in selectedVehicle) {
        if (selectedVehicle.hasOwnProperty(key)) {
          let sv = selectedVehicle[key];
          const { TVA, TVA_INVERT } = getTVAByCatalog(sv.catalog.uuid);

          let quotationVehicle = {
            ...sv,
            pricePublicTTC: "0",
            investAmtHT: "0",
            priceNetTTC: "0",
          };
          // quotationVehicle.discountHT = quotationVehicle.discount
          if (quotationVehicle.type == 1 || quotationVehicle.type?.id == 1) {
            quotationVehicle.discount =
              Math.round(quotationVehicle.discount * TVA_INVERT * 100) / 100;
          }
          console.log("quotationVehicle", quotationVehicle);
          var newQuotation = {
            vehicle: quotationVehicle,
            quantity: 1,
            reference:
              sv.brand.name +
              " " +
              sv.brandModel.name +
              " " +
              (sv.type.id == 1 ? "VP" : "VU") +
              " " +
              sv.brandModelFinish.name +
              " " +
              dur +
              "/" +
              km,
            status: 1,
            insuranceRate: "0",
            firstRentInput: "0",
            vehicleCategory: "B",
            franchiseDommage: "457",
            nbTires: "0",
            customTirePrice: "0",
            baseRentHT: "0",
            replacementVehicleCost: parseFloat(
              constantData.REPLACEMENT_VEHICULE_COST_DEFAULT
            ),
            marginTTCRate: parseFloat(constantData.MARGIN_RATE_DEFAULT),
            marginTTC: "0",
            discount: "0",
            financeRate: null,
            applicationFee: null,
            state: "1",
            dur: dur,
            km: km,
            rateVR: "0",
            repurchase: null,
            repurchase_data: [],
            kmPrice: "-1",
            repurchaseValueFinal: "0",
            maintenance: null,
            rateMaintenance: "0",
            discountMaintenance: "0",
            maintenanceValueFinal: "-1",
            maintenance_data: [],
            financeRent: "0",
            insuranceHT: "0",
            insuranceFinLoss: "0",
            totalRentHT: "0",
            totalRentTTC: "0",
            benefits: [],
            mentions: constantData.QUOTATION_MENTIONS.map((q) => {
              return {
                name: q.name,
              };
            }),
            comment: "",
          };

          quotations.push(newQuotation);
          setOverlayOpened((o) => quotations.length - 1);
          quotationDetailActions.inputChange({
            target: { name: "quotations", value: quotations },
          });
        }
      }
    },
    toggleOverlay: (index, toggle) => {
      if (toggle) {
        setOverlayOpened(index);
      } else {
        setOverlayOpened(null);
      }
    },
    navQuotation: (direction) => {
      setOverlayOpened(overlayOpened + (direction ? 1 : -1));
    },
    checkClientSelected: () => {
      if (!state.quotation.item.client) {
        alert("Veuillez sélectionner un client");
        document.querySelector("[data-target='#clientModal']").click();

        return false;
      }
      return true;
    },
    checkAssignedToSelected: () => {
      if (!state.quotation.item.assignedTo) {
        alert("Veuillez assigner le dossier à un utilisateur");
        document.querySelector("[data-target='#userModal']").click();
        return false;
      }
      return true;
    },
    save: (type) => {
      if (quotationDetailActions.missingData()) return false;
      if (
        !quotationDetailActions.checkClientSelected() ||
        !quotationDetailActions.checkAssignedToSelected()
      ) {
        return false;
      }
      if (type == "create") {
        actions.createQuotation(state.quotation.item);
      }
      if (type == "update") {
        actions.updateQuotation(state.quotation.item);
      }
    },
    missingData: () => {
      var isError = false;
      for (var k in state.quotation.item.quotations) {
        const q = state.quotation.item.quotations[k];
        if (parseFloat(q.repurchaseValueFinal) <= 0) {
          toast.error(
            "La valeur de rachat est manquante pour le dossier N°" +
              (k + 1) +
              " - " +
              q.reference
          );
          setOverlayOpened(k);
          return true;
        }
        if (
          parseFloat(q.maintenanceValueFinal) < 0 ||
          q.maintenanceValueFinal + "" === ""
        ) {
          toast.error(
            "La valeur de maintenance est manquante (" +
              q.maintenanceValueFinal +
              ") pour le dossier N°" +
              (k + 1) +
              " - " +
              q.reference
          );
          setOverlayOpened(k);
          return true;
        }
        if (parseInt(q.totalRentHT) <= 0) {
          toast.error(
            "Le loyer semble erroné pour le dossier N°" +
              (k + 1) +
              " - " +
              q.reference
          );
          setOverlayOpened(k);
          return true;
        }
      }

      return isError;
    },
    openPdf: (quotation) => {
      setPdfOpen(quotation);
    },
    openPdfQuotationList: () => {
      var qs = [];
      quotationData.quotations.forEach((q) => {
        if (quotationSelected.find((qq) => qq.uuid == q.uuid)) {
          qs.push(q);
        }
      });

      setRecapOpen(qs);
    },
    duplicateQuotation: (index) => {
      var { quotations } = quotationData;
      var copy = JSON.parse(JSON.stringify(quotations[index]));
      copy.id = null;
      copy.status = 1;
      quotations.push(copy);
      quotationDetailActions.inputChange({
        target: { name: "quotations", value: quotations },
      });
    },
    changeLineOrder: (dir, index) => {
      var quots = JSON.parse(JSON.stringify(quotationData));
      var newIndex = dir == "up" ? index + 1 : index - 1;
      newIndex =
        newIndex > quotationData.quotations.length - 1
          ? quotationData.quotations.length - 1
          : newIndex;
      newIndex = newIndex < 0 ? 0 : newIndex;
      quots.quotations = quotationDetailActions.array_move(
        quots.quotations,
        index,
        newIndex
      );

      quotationDetailActions.inputChange({
        target: { name: "quotations", value: quots.quotations },
      });
    },
    array_move: (arr, old_index, new_index) => {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
  };
  return {
    actions,
    state,
    selectedClient,
    setSelectedClient,
    selectedUser,
    setSelectedUser,
    selectedVehicle,
    setSelectedVehicle,
    dur,
    setDur,
    km,
    setKm,
    quotationOpened,
    setQuotationOpened,
    overlayOpened,
    setOverlayOpened,
    sort,
    setSort,
    filters,
    setFilters,
    hideKeys,
    setHideKeys,
    pdfOpen,
    setPdfOpen,
    recapOpen,
    setRecapOpen,
    quotationSelected,
    setQuotationSelected,
    tableWidth,
    setTableWidth,
    openClientQuotationModal,
    setOpenClientQuotationModal,
    clientQuotations,
    setClientQuotations,
    authData,
    quotationData,
    constantData,
    clientData,
    tireData,
    t_actions,
    quotationDetailActions,
    isParametersOpen,
    setIsParametersOpen,
  };
};

export default useQuotationDetail;
