import { useHistory, useLocation } from "react-router-dom";
import { brandModelActions } from "../../../../context/actions/brandModelActions";
import useStore from "../../../../context/useStore";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { brandActions } from "../../../../context/actions/brandActions";
import tools from "../../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const repurchaseSearchAtom = atom({
  search: "",
  brandModel: "",
  brand: "",
});

const useRepurchaseSearch = ({ handlePageParamsChange }) => {
  const [state, dispatch] = useStore();
  const brand_actions = brandActions(state, dispatch);
  const brand_model_actions = brandModelActions(state, dispatch);
  const location = useLocation();
  const history = useHistory();

  const [repurchaseSearch, setRepurchaseSearch] = useAtom(repurchaseSearchAtom);

  useEffect(() => {
    brand_actions.getAllBrands();
    brand_model_actions.getAllBrandModels();
  }, []);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(repurchaseSearch);
    },

    onResetSearch: () => {
      setRepurchaseSearch({ search: "", brand: "", brandModel: "" });
      const newSearchParams = { search: "", brand: "", brandModel: "" };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      setRepurchaseSearch({ ...repurchaseSearch, [name]: value, page: "1" });
    },
  };

  return {
    state,
    repurchaseSearch,
    actions,
  };
};

export default useRepurchaseSearch;
