import React, { useEffect } from "react";
import useStore from "../context/useStore";
import Loader from "../components/common/Loader";
import { groupActions } from "../context/actions/groupActions";

const GroupMiddleware = ({ children }) => {
  const [state, dispatch] = useStore();
  var group_actions = groupActions(state, dispatch);
  const groupsLoaded = state.group?.items?.length > 0;
  const shouldLoadGroups = state.auth.isAuthenticated && !groupsLoaded;
  useEffect(() => {
    if (shouldLoadGroups) group_actions.getAllGroups();
  }, []);

  if (!shouldLoadGroups) return children;

  if (!groupsLoaded) {
    return <Loader text="Chargement des groupes" />;
  }
};

export default GroupMiddleware;
