import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { maintenanceActions } from "../../../context/actions/maintenanceActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import CustomList from "../../common/CustomList";
import MaintenanceSearch from "./MaintenanceSearch/MaintenanceSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import { useAtom } from "jotai";
import { maintenanceSearchAtom } from "./MaintenanceSearch/useMaintenanceSearch";

const MaintenanceList = (props) => {
  const [state, dispatch] = useStore();
  const [maintenanceSearch, setMaintenanceSearch] = useAtom(
    maintenanceSearchAtom
  );
  var maintenanceData = state.maintenance.items;
  var constantData = state.constant.items;

  var actions = maintenanceActions(state, dispatch, props);

  useEffect(() => {
    actions.getMaintenances(maintenanceSearch);
  }, []);

  return (
    <CustomList getAction={actions.getMaintenances}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Tableau d'entretien</h1>
              {state.maintenance.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_TABLEAU_ENTRETIEN}>
                  <Link className="btn btn-primary" to="/maintenances/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un tableau
                  </Link>
                </Can>
              )}
              <MaintenanceSearch
                handlePageParamsChange={actions.getMaintenances}
              />
              <NbSearchResults total={state.maintenance.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Catalogue</th>
                    <th>Marque</th>
                    <th>Modèles</th>
                    <th>Carburants</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {maintenanceData.map((maintenance, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{maintenance.catalog && maintenance.catalog.name}</td>
                      <td>{maintenance.brand && maintenance.brand.name}</td>
                      <td>
                        {maintenance.brandModels.map(
                          (bm, k) =>
                            bm.name +
                            (k == maintenance.brandModels.length - 1
                              ? ""
                              : ", ")
                        )}
                      </td>
                      <td>
                        {maintenance.fuelTypes
                          .map((ft, k) => ft.name)
                          .join(", ")}
                      </td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_TABLEAU_ENTRETIEN}>
                          <Link
                            className="btn btn-secondary mr-2"
                            to={"/maintenances/" + maintenance.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.maintenance.total}
                activePage={maintenanceSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  setMaintenanceSearch({ ...maintenanceSearch, ...data });
                  actions.getMaintenances({ ...maintenanceSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default MaintenanceList;
