import errorReducers from "./reducers/errorReducers";
import authReducer from "./reducers/authReducers";
import brandReducer from "./reducers/brandReducers";
import brandModelReducers from "./reducers/brandModelReducers";
import repurchaseReducers from "./reducers/repurchaseReducers";
import maintenanceReducers from "./reducers/maintenanceReducers";
import constantReducers from "./reducers/constantReducers";
import userReducers from "./reducers/userReducers";
import vehicleReducers from "./reducers/vehicleReducers";
import catalogReducers from "./reducers/catalogReducers";
import financeReducers from "./reducers/financeReducers";
import quotationReducers from "./reducers/quotationReducers";
import contractReducers from "./reducers/contractReducers";
import clientReducers from "./reducers/clientReducers";
import upkeepReducers from "./reducers/upkeepReducers";
import tireReducers from "./reducers/tireReducers";
import finishReducers from "./reducers/finishReducers";
import financierReducers from "./reducers/financierReducers";
import catalogStoreReducers from "./reducers/catalogStoreReducers";
import groupReducers from "./reducers/groupReducers";

function combineReducers(reducers) {
  // First get an array with all the keys of the reducers (the reducer names)
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    // This is the object we are going to return.
    const nextState = {};

    // Loop through all the reducer keys
    for (let i = 0; i < reducerKeys.length; i++) {
      // Get the current key name
      const key = reducerKeys[i];
      // Get the current reducer
      const reducer = reducers[key];
      // Get the the previous state
      const previousStateForKey = state[key];
      // Get the next state by running the reducer
      const nextStateForKey = reducer(previousStateForKey, action);
      // Update the new state for the current reducer
      nextState[key] = nextStateForKey;
    }
    return nextState;
  };
}

export default combineReducers({
  error: errorReducers,
  auth: authReducer,
  brand: brandReducer,
  brandModel: brandModelReducers,
  finish: finishReducers,
  repurchase: repurchaseReducers,
  maintenance: maintenanceReducers,
  constant: constantReducers,
  user: userReducers,
  vehicle: vehicleReducers,
  finance: financeReducers,
  quotation: quotationReducers,
  contract: contractReducers,
  client: clientReducers,
  upkeep: upkeepReducers,
  catalog: catalogReducers,
  tire: tireReducers,
  financier: financierReducers,
  catalogStore: catalogStoreReducers,
  group: groupReducers,
});
