import useStore from "../../../context/useStore";
import CustomList from "../../common/CustomList";
import { catalogActions } from "../../../context/actions/catalogActions";
import NewCatalogSearch from "./NewCatalogSearch";
import { brandActions } from "../../../context/actions/brandActions";
import React, { useEffect, useState } from "react";
import NbSearchResults from "../../common/NbSearchResults";
import { Link } from "react-router-dom";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import useTVA from "../../../hooks/useInit/useTVA";
import useCatalogSearch from "./useCatalogSearch";

const CatalogResearch = (props) => {
  const [state, dispatch] = useStore();
  let actions = catalogActions(state, dispatch);
  let b_actions = brandActions(state, dispatch);
  const { getTVAByCatalog } = useTVA();

  const { catalogSearch, actions: searchActions } = useCatalogSearch({
    handlePageParamsChange: actions.getCatalogs,
  });

  useEffect(() => {
    b_actions.getAllBrands();
  }, []);

  return (
    <CustomList getAction={actions.getCatalogs}>
      {(activePage, handlePageParamsChange, search) => {
        return (
          <div className="px-3">
            <div className="row">
              <div className="col-12">
                <div className="row  bg-light rounded-lg pb-0  shadow-sm border mb-4 pt-3 px-3">
                  <NewCatalogSearch
                    handlePageParamsChange={searchActions.onTriggerSearch}
                    search={catalogSearch}
                  />
                  <div className="col-4 px-1 text-center  position-relative ">
                    <h4 className="d-flex align-items-baseline justify-content-center ">
                      Résultats{" "}
                      {state.catalog.isLoading ? (
                        <div
                          className="d-flex position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                          style={{
                            background: "rgba(255, 255, 255, 0.67)",
                            zIndex: 9,
                          }}
                        >
                          <Loader size={32} />
                        </div>
                      ) : (
                        <span
                          className="ml-2 font-italic"
                          style={{ fontSize: 16 }}
                        >
                          {`${state.catalog.items.length} / ${state.catalog.total}`}
                        </span>
                      )}
                    </h4>
                    <div
                      className="pt-1"
                      style={{
                        height: "400px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {state.catalog.items.map((catalog, k) => {
                        const catalogCount = props.selectedVehicles
                          .map((v) => v.uuid)
                          .reduce((count, cur) => {
                            if (cur === catalog.uuid) {
                              return count + 1;
                            }
                            return count;
                          }, 0);
                        return (
                          <div className="mb-2">
                            <a
                              key={`catalog_${catalog.uuid}_${k}`}
                              href="#"
                              className={`w-100 btn btn-primary btn-sm`}
                              onClick={() => props.selectVehicles(catalog)}
                            >
                              <div className="row p-2">
                                <div className="col-10 pr-0">
                                  <div className="text-left">
                                    <div className="badge badge-light mb-2">
                                      {catalog.catalog.name}
                                    </div>
                                  </div>
                                  <div
                                    className="text-left"
                                    style={{ fontSize: 14 }}
                                  >
                                    {[
                                      catalog.brand.name,
                                      catalog.brandModel.name,
                                      catalog.type.name,
                                      catalog.brandModelFinish.name,
                                      catalog.optionsNotIncluded?.map(
                                        (o, k) => o.name
                                      ),
                                    ]
                                      .filter(
                                        (item) =>
                                          !Array.isArray(item) ||
                                          item.length > 0
                                      )
                                      .join(" - ")}
                                  </div>
                                </div>
                                <div className="col-2 d-flex align-items-center justify-content-center px-0 pr-2">
                                  <i
                                    style={{ fontSize: "x-large" }}
                                    className="fa fa-plus mr-2"
                                  />
                                  <span style={{ fontSize: 14 }}>
                                    {catalogCount}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                      <button 
                        className="btn btn-outline-primary btn-sm text-dark"
                        onClick={() => searchActions.onChangePage(catalogSearch.page - 1)}
                        disabled={catalogSearch.page <= 1}
                      >
                        Précédente
                      </button>
                      <div className="d-flex align-items-center">
                        <select
                          className="form-control form-control-sm mx-2 mb-0"
                          style={{ width: '80px' }}
                          value={catalogSearch.page}
                          onChange={(e) => searchActions.onChangePage(parseInt(e.target.value))}
                        >
                          {Array.from({ length: Math.ceil(state.catalog.total / 15) }, (_, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                          ))}
                        </select>
                        <span>/ {Math.ceil(state.catalog.total / 15)}</span>
                      </div>
                      <button 
                        className="btn btn-outline-primary btn-sm text-dark"
                        onClick={() => searchActions.onChangePage(catalogSearch.page + 1)}
                        disabled={state.catalog.items.length < 15}
                      >
                        Suivante
                      </button>
                    </div>
                  </div>
                </div>

                <h3>Véhicules sélectionnés</h3>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Catalogue</th>
                      <th>Marque</th>
                      <th>Modèle</th>
                      <th>Type</th>
                      <th>Finition</th>
                      <th>Options</th>
                      <th>Prix public</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedVehicles.map((catalog, k) => {
                      const { TVA, TVA_INVERT } = getTVAByCatalog(
                        catalog.catalog.uuid
                      );

                      let displayPriceTTC = catalog.priceTTC;
                      let displayPriceOptions =
                        catalog.optionsNotIncluded?.reduce(
                          (a, b) =>
                            a + b.priceHT * (b.isInclTVA ? TVA_INVERT : 1),
                          0
                        );
                      displayPriceOptions =
                        Math.round(displayPriceOptions * 100) / 100;

                      return props.modal &&
                        catalog.isHidden &&
                        !state.auth.user.role == "SUPER_ADMIN" ? (
                        <tr
                          key={"brand-row-" + k}
                          style={{ display: "none" }}
                        ></tr>
                      ) : (
                        <tr
                          key={"brand-row-" + k}
                          style={catalog.isHidden ? { opacity: "0.7" } : {}}
                        >
                          <td>{catalog.catalog?.name}</td>
                          <td>
                            {catalog.brand?.name}{" "}
                            {catalog.isHidden ? "(caché)" : ""}
                          </td>
                          <td>{catalog.brandModel?.name}</td>
                          <td>{catalog.type?.name}</td>
                          <td>{catalog.brandModelFinish?.name}</td>
                          <td>
                            {catalog.optionsNotIncluded?.map((o, k) => (
                              <span
                                key={"options_" + catalog.uuid + "_" + k}
                                className="mr-2 badge badge-primary"
                              >
                                {o.name}
                              </span>
                            ))}
                          </td>
                          <td>
                            {Math.round(displayPriceTTC * 100) / 100} € <br />
                            {catalog.optionsNotIncluded?.length > 0 &&
                              "Options: " + displayPriceOptions + " €"}
                          </td>
                          <td>
                            {!props.modal ? (
                              <>
                                <Link
                                  className="btn btn-secondary"
                                  to={"/catalogs/" + catalog.uuid}
                                >
                                  Modifier
                                </Link>
                              </>
                            ) : (
                              <a
                                href="#"
                                className="btn btn-default btn-md"
                                onClick={() => props.removeVehicle(catalog)}
                              >
                                <i className="fa fa-times" />
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end w-100">
                  <a
                    href="#"
                    className="btn btn-primary"
                    onClick={() => props.selectCatalog(props.selectedVehicles)}
                  >
                    <i className="fa fa-plus mr-2" />
                    {`Choisir ${
                      props.selectedVehicles.length
                    } ${tools.getStringPlural(
                      "véhicule",
                      props.selectedVehicles.length > 1
                    )}`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </CustomList>
  );
};

export default CatalogResearch;
