import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Lato Bold",
  src: `/Lato-Bold.ttf`,
});
const styles = StyleSheet.create({
  container: {},
  header: {
    fontSize: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  rowHead: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginTop: 5,
    fontFamily: "Lato Bold",
    borderBottomColor: "black",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    padding: "15px 20px",
  },
  paragraf: {
    marginTop: 10,
  },
  paragrafTitle: {
    fontFamily: "Lato Bold",
  },
  textSmall: {
    fontSize: 10,
  },
  textVerySmall: {
    fontSize: 9,
    textAlign: "justify",
  },
  textBig: {
    fontFamily: "Lato Bold",
  },
  articleBreak: {
    borderBottomWidth: 1,
    borderBottomColor: 1,
    textAlign: "right",
  },
  textBold: {
    fontWeight: "bold",
  },
});
const ClientCgvPdf = ({ client }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
      <Document>
        <Page size="A4" style={{ padding: 30 }}>
          <View style={styles.row}>
            <View style={styles.column}>
              <Image src="/logo.png" style={{ width: "100%", paddingTop: 50 }} />
              <View style={styles.paragraf}>
                <Text
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "20px 15px",
                  }}
                >
                  CONDITIONS GENERALES DE LOCATION LONGUE DUREE DE VEHICULES A MOTEUR
                </Text>
              </View>
              <Text>Les soussignés, ci-après dénommés :</Text>
              <View style={styles.paragraf}>
                <Text>d'une part, le locataire :</Text>
                <Text>{client.name}</Text>
                <Text>{client.address}</Text>
                {client.address2 && <Text>{client.address2}</Text>}
                {client.address3 && <Text>{client.address3}</Text>}
                <Text>
                  {client.postalCode} {client.city}
                </Text>
                <Text>Siret : {client.siret}</Text>
              </View>
              <View style={styles.paragraf}>
                <Text>et d'autre part, le loueur : </Text>
                <Text style={styles.textBold}>LEASECAR RÉUNION</Text>
                <Text>SAS LEASECAR:</Text>
                <Text>au capital de 225 000 €,</Text>
                <Text style={styles.textBold}>dont le siège social est au</Text>
                <Text> 103 Avenue Jacques Prévert</Text>
                <Text>97420 LE PORT</Text>
                <Text>SIRET : 535 143 424 00021 APE : 7711 B</Text>
              </View>

              <View style={styles.paragraf}>
                <Text style={styles.textVerySmall}>
                  ont établi, ci-après, les conditions générales suivant lesquelles LeaseCar louera
                  au locataire des véhicules désignés par avenants séparés portant conditions
                  particulières.
                </Text>
              </View>

              <View style={styles.articleBreak}>
                <Text>ARTICLE 1.</Text>
              </View>

              <View style={styles.paragraf}>
                <Text style={styles.paragrafTitle}>OBJET DE LA LOCATION.</Text>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    1.1. Le contrat a pour objet la location longue durée de véhicules appartenant à
                    LeaseCar.
                  </Text>
                </View>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    1.2. Titulaire exclusif de la garde des véhicules loués, le locataire en
                    assumera la responsabilité conformément aux dispositions de l'article 1384 du
                    Code Civil.
                  </Text>
                </View>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    1.3. Le locataire en aura la jouissance dans les termes et modalités des
                    présentes conditions générales.
                  </Text>
                </View>
              </View>

              <View style={styles.articleBreak}>
                <Text>ARTICLE 2.</Text>
              </View>

              <View style={styles.paragraf}>
                <Text style={styles.paragrafTitle}>CHOIX DES VEHICULES.</Text>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    Le locataire fait le choix du (ou des) véhicule(s) dont il aura souhaité
                    souscrire la location, en déterminant le cas échéant les options.
                  </Text>
                </View>
              </View>

              <View style={styles.articleBreak}>
                <Text>ARTICLE 3.</Text>
              </View>

              <View style={styles.paragraf}>
                <Text style={styles.paragrafTitle}>EQUIPEMENT DES VEHICULES.</Text>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    3.1. Les véhicules loués sont livrés selon l'équipement standard dans le type de
                    la marque, munis des accessoires légalement prescrits à la date de mise à
                    disposition, avec leur carte grise, sauf stipulation contraire du locataire.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.column}>
              <View style={styles.paragraf}>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    3.2. L'installation d'accessoires ou équipements supplémentaires quels qu'ils
                    soient, ainsi que plus généralement toutes modifications des véhicules devront
                    être préalablement autorisées par écrit par LeaseCar et seront à la charge du
                    locataire.
                  </Text>
                </View>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    3.3. A l'expiration de la location, seuls pourront être retirés des véhicules
                    les accessoires non obligatoires et montés par le locataire pour son compte,
                    sous réserve cependant que ce retrait ne laisse subsister aucune trace
                    permanente, auquel cas ces accessoires demeureront en place sans aucune
                    indemnité pour le locataire.
                  </Text>
                </View>
                <View style={styles.paragraf}>
                  <Text style={styles.textVerySmall}>
                    3.4. L'apposition de peintures publicitaires ou d'auto-collants publicitaires
                    devra être de la même façon préalablement autorisée par écrit, la remise en état
                    d'origine du chef de celle-ci ou de celui des accessoires ou équipements
                    supplémentaires, le cas échéant, restant à la charge du locataire.
                  </Text>
                </View>
              </View>
              <View style={styles.paragraf}>
                <View style={styles.articleBreak}>
                  <Text>ARTICLE 4.</Text>
                </View>

                <View style={styles.paragraf}>
                  <Text style={styles.paragrafTitle}>PROPRIETE DU VEHICULE,</Text>
                  <Text style={styles.paragrafTitle}>SAISIE ET ACTION DES TIERS.</Text>
                  <View style={styles.paragraf}>
                    <Text style={styles.textVerySmall}>
                      <Text>
                        4.1. Les véhicules loués demeurent la propriété exclusive de LeaseCar et ne
                        peuvent d'aucune façon faire l'objet de cession au locataire à l'issue de la
                        location.
                      </Text>
                      <Text>
                        A la demande expresse du locataire, LeaseCar pourra exceptionnellement
                        accepter de céder un véhicule loué à l'utilisateur habituel.
                      </Text>
                      <Text>Cette vente se fera alors exclusivement au prix du marché.</Text>
                    </Text>
                  </View>
                  <View style={styles.paragraf}>
                    <Text style={styles.textVerySmall}>
                      4.2. Le locataire s'engage à faire respecter le droit de propriété de
                      LeaseCar, en toutes circonstances, par tous moyens légaux et à ses frais. En
                      cas de saisie, de réquisition ou de confiscation du véhicule, il s'engage à en
                      aviser LeaseCar sans délai, à élever toute protestation et à prendre toutes
                      dispositions pour faire connaître le droit de propriété de celle-ci et à
                      rapporter mainlevée de toutes saisies dans les huit jours de leur
                      dénonciation, le tout à ses frais et sans préjudice de l'action en
                      revendication restant appartenir au loueur.
                    </Text>
                  </View>
                  <View style={styles.paragraf}>
                    <Text style={styles.textVerySmall}>
                      4.3. A défaut pour le locataire de respecter ses obligations pour la
                      conservation de la propriété de LeaseCar face à l'action des tiers, la
                      location sera de plein droit résiliée sans aucune formalité judiciaire et sur
                      l'envoi d'une lettre recommandée avec accusé de réception ordonnant la
                      restitution des véhicules loués, semblable résiliation entraînant mise en
                      oeuvre des dispositions stipulées aux articles 17. et 23.
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.paragraf}>
                <View style={styles.articleBreak}>
                  <Text>ARTICLE 5.</Text>
                </View>

                <View style={styles.paragraf}>
                  <Text style={styles.paragrafTitle}>MISE A DISPOSITION, PRISE EN CHARGE.</Text>
                  <View style={styles.paragraf}>
                    <Text style={styles.textVerySmall}>
                      5.1. Au cas où le locataire souhaite annuler une commande de véhicule dûment
                      enregistrée et acceptée par le fournisseur mandaté, le locataire devra verser
                      immédiatement à LeaseCar une indemnité égale à six mois de loyer TTC sauf
                      accord plus favorable avec le fournisseur.
                    </Text>
                  </View>
                  <View style={styles.paragraf}>
                    <Text style={styles.textVerySmall}>
                      5.2. La signature par le locataire ou son représentant de l'avis de mise à
                      disposition selon imprimé de LeaseCar vaut prise en charge, celle-ci
                      impliquant reconnaissance par le locataire de la conformité des véhicules à sa
                      désignation contractuelle. A défaut de régularisation de l'avis de mise à
                      disposition et en cas de désaccord sur la date de la prise en charge, les
                      parties conviennent de fixer celle-ci à la date de l'immatriculation
                      provisoire ou définitive du (des) véhicule(s) concerné(s).
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default ClientCgvPdf;
