const userAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return { ...data, role: data.role.id, userGroup: data.userGroup.uuid };
  },
};

export default userAdapters;
