import React from "react";
import useCatalogSearch from "./useCatalogSearch";
import Control from "../../common/Control";
import CatalogSelector from "../../common/CatalogSelector";

const CatalogSearch = ({ handlePageParamsChange, openPresearch = false }) => {
  const { state, catalogSearch, actions } = useCatalogSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col-12">
          <CatalogSelector
            label="Rechercher dans les catalogues"
            name="catalogs"
            change={actions.onChangeSearch}
            value={catalogSearch.catalogs}
          />
        </div>
        <div className="col">
          <Control
            label="Rechercher par marque"
            id="brand"
            name="brand"
            type="select"
            value={catalogSearch.brand}
            change={actions.onChangeSearch}
            datas={state.brand.items}
            dataIndex="uuid"
          />
        </div>
        <div className="col">
          <Control
            label="Par modèle"
            name="brandModel"
            id="brandModel"
            type="select"
            value={catalogSearch.brandModel}
            dataIndex="uuid"
            dataLabel="name"
            change={actions.onChangeSearch}
            datas={state.brandModel.items.filter((i) => {
              if (catalogSearch.brand == "") return true;
              return i.brand.uuid == catalogSearch.brand;
            })}
          />
        </div>
        <div className="col">
          <Control
            label="Par finition"
            name="brandModelFinish"
            id="brandModelFinish"
            type="select"
            value={catalogSearch.brandModelFinish}
            dataIndex="uuid"
            dataLabel="name"
            change={actions.onChangeSearch}
            datas={state.finish.items}
          />
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default CatalogSearch;
