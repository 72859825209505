export const CATALOG_TYPES = {
  LOAD_CATALOG: "LOAD_CATALOG",
  GET_CATALOGS: "GET_CATALOGS",
  GET_CATALOG: "GET_CATALOG",
  UPDATE_CATALOG_DATA: "UPDATE_CATALOG_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    catalog: "",
    immat: "",
    serialNumber: "",
    inroadDate: "",
    brand: "",
    brandModel: "",
    finish: "",
    type: "",
    priceHT: "0",
    options: [],
    optionsNotIncluded: [],
    isInclVR: false,
    inroadCost: "0",
    greyCardCost: "0",
    malusBonusEco: "0",
    discount: "0",
    discountPct: "0",
    co2: "0",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATALOG_TYPES.LOAD_CATALOG:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CATALOG_TYPES.GET_CATALOGS:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.meta.total,
        item: initialState.item,
        isLoading: false,
      };

    case CATALOG_TYPES.GET_CATALOG:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case CATALOG_TYPES.UPDATE_CATALOG_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
