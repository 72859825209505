import { tireActions } from "../../../context/actions/tireActions";
import { TIRE_TYPES } from "../../../context/reducers/tireReducers";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";

export const TireForm = () => {
  const [state, dispatch] = useStore();
  var actions = tireActions(state, dispatch);
  var tireItem = state.tire.item;

  function inputChange(e) {
    tools.inputChange(e, TIRE_TYPES.UPDATE_TIRE_DATA, dispatch);
  }

  return (
    <div className="card  mb-4">
      <div className="card-body">
        <h5>Ajouter un pneu</h5>
        <div className="row">
          <div className="col-12 col-md-4">
            <Control
              label="Nom"
              name="name"
              className="mr-2"
              value={tireItem.name}
              change={inputChange}
              error={state.error}
              required={true}
            />
          </div>
          <div className="col-12 col-md-4">
            <Control
              label="Prix"
              name="price"
              type="number"
              suffix="€"
              value={tireItem.price}
              change={inputChange}
              error={state.error}
              required={true}
            />
          </div>
          <div className="col-12 col-md-4 d-flex">
            <button
              className="btn btn-success flex-fill"
              onClick={() => actions.createTire(tireItem)}
            >
              <i className="fa fa-plus mr-2" />
              Ajouter ce pneu
            </button>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
