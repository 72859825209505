import React from "react";
import useVehicleSearch from "./useVehicleSearch";
import Control from "../../common/Control";

const VehicleSearch = ({ handlePageParamsChange }) => {
  const { state, vehicleSearch, actions } = useVehicleSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col">
          <Control
            label="Rechercher par marque"
            id="brand"
            name="brand"
            type="select"
            value={vehicleSearch.brand}
            change={actions.onChangeSearch}
            datas={state.brand.items}
            dataIndex="uuid"
          />
        </div>
        <div className="col">
          <Control
            label="Par modèle"
            name="brandModel"
            id="brandModel"
            type="select"
            value={vehicleSearch.brandModel}
            dataIndex="uuid"
            dataLabel="name"
            change={actions.onChangeSearch}
            datas={state.brandModel.items.filter((i) => {
              if (vehicleSearch.brand == "") return true;
              return i.brand.uuid == vehicleSearch.brand;
            })}
          />
        </div>
        <div className="col">
          <Control
            label="Par finition"
            name="brandModelFinish"
            id="brandModelFinish"
            type="select"
            value={vehicleSearch.brandModelFinish}
            dataIndex="uuid"
            dataLabel="name"
            change={actions.onChangeSearch}
            datas={state.finish.items}
          />
        </div>
        <div className="col">
          <Control
            label="Par immatriculation"
            name="immat"
            id="immat"
            value={vehicleSearch.immat}
            change={actions.onChangeSearch}
          />
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default VehicleSearch;
