import { useHistory, useLocation } from "react-router-dom";
import useStore from "../../../context/useStore";
import { useState } from "react";
import queryString from "query-string";
import tools from "../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const clientSearchAtom = atom({
  search: "",
  name: "",
});

const useClientSearch = ({ handlePageParamsChange }) => {
  const [state] = useStore();
  const location = useLocation();
  const history = useHistory();

  const [clientSearch, setClientSearch] = useAtom(clientSearchAtom);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(clientSearch);
    },

    onResetSearch: () => {
      setClientSearch({
        search: "",
        name: "",
      });
      const newSearchParams = {
        search: "",
        name: "",
      };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      const newSearchParams = { ...clientSearch, [name]: value };

      setClientSearch({ ...newSearchParams, page: "1" });
    },
  };

  return {
    state,
    clientSearch,
    actions,
  };
};

export default useClientSearch;
