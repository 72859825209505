import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { UPKEEP_TYPES } from "../reducers/upkeepReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const upkeepActions = (state, dispatch, props) => {
  function getUpkeeps(search = "") {
    dispatch({ type: UPKEEP_TYPES.LOAD_UPKEEP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "upkeeps" + search)
      .then(res => {
        dispatch({ type: UPKEEP_TYPES.GET_UPKEEPS, payload: res.data });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          UPKEEP_TYPES.LOAD_UPKEEP
        );
      });
  }
  function getVehicleUpkeeps(vid) {
    dispatch({ type: UPKEEP_TYPES.LOAD_UPKEEP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "upkeeps/vehicle/" + vid)
      .then(res => {
        dispatch({ type: UPKEEP_TYPES.GET_UPKEEPS, payload: res.data });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          UPKEEP_TYPES.LOAD_UPKEEP
        );
      });
  }
  function getUpkeep(id) {
    dispatch({ type: UPKEEP_TYPES.LOAD_UPKEEP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "upkeeps/" + id)
      .then(res => {
        dispatch({
          type: UPKEEP_TYPES.GET_UPKEEP,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          UPKEEP_TYPES.LOAD_UPKEEP
        );
      });
  }
  function createUpkeep(upkeepData) {
    dispatch({ type: UPKEEP_TYPES.LOAD_UPKEEP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "upkeeps", upkeepData)
      .then(res => {
        dispatch({
          type: UPKEEP_TYPES.GET_UPKEEP,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          "Le kilométrage a été correctement ajouté."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          UPKEEP_TYPES.LOAD_UPKEEP
        );
      });
  }
  function updateUpkeep(upkeepData) {
    dispatch({ type: UPKEEP_TYPES.LOAD_UPKEEP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "upkeeps/" + upkeepData.id, upkeepData)
      .then(res => {
        dispatch({
          type: UPKEEP_TYPES.GET_UPKEEP,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          upkeepData.name + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          UPKEEP_TYPES.LOAD_UPKEEP
        );
      });
  }

  return {
    createUpkeep,
    updateUpkeep,
    getUpkeeps,
    getUpkeep,
    getVehicleUpkeeps
  };
};
