import { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { catalogActions } from "../../../context/actions/catalogActions";
import { tireActions } from "../../../context/actions/tireActions";
import { catalogStoreActions } from "../../../context/actions/catalogStoreActions";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import { CATALOG_TYPES } from "../../../context/reducers/catalogReducers";
import vehicleTools from "../../../helpers/vehicleTools";
import tools from "../../../helpers/tools";
import { finishActions } from "../../../context/actions/finishActions";
import useTVA from "../../../hooks/useInit/useTVA";

const useCatalogDetail = (props) => {
  const [state, dispatch] = useStore();
  const [tire, setTire] = useState("");
  const [isInit, setIsInit] = useState(false);

  var catalogStoreData = state.catalogStore.items;
  var catalogData = state.catalog.item;
  var brandModelData = state.brandModel.items;
  var finishData = state.finish.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;
  var tireData = state.tire.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var fin_actions = finishActions(state, dispatch, props);
  var v_actions = catalogActions(state, dispatch, props);
  var t_actions = tireActions(state, dispatch, props);

  const { getTVAByCatalog } = useTVA();

  let { TVA, TVA_INVERT } = catalogData.catalog
    ? getTVAByCatalog(catalogData.catalog)
    : { TVA: -1, TVA_INVERT: -1 };

  var rmOptions = catalogData.optionsNotIncluded.reduce((a, b) => {
    if (b.isInclRM) {
      return a + parseFloat(b.priceHT);
    } else {
      return a;
    }
  }, 0);

  useEffect(() => {
    b_actions.getAllBrands();
    t_actions.getTires();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
      },
    });
    if (props.match.params.uuid != "create" && !props.modal) {
      v_actions.getCatalog(props.match.params.uuid);
    }
  }, []);

  useEffect(() => {
    if (!isInit && catalogData) {
      if (catalogData.discount > 0) {
        setIsInit(true);
        var discountTTC =
          Math.round(
            (parseFloat(catalogData.discount) +
              parseFloat(catalogData.discount) *
                (catalogData.type == 1 ? TVA : 0)) *
              100
          ) / 100;

        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: discountTTC,
          },
        });
      }

      if (catalogData.inroadCost > 0) {
        var inroadCostTTC =
          parseFloat(catalogData.inroadCost) *
          (catalogData.type == "1" ? TVA_INVERT : 1);
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "inroadCostTTC",
            value: inroadCostTTC,
          },
        });
      }
    }
  }, [catalogData]);

  useEffect(() => {
    if (tireData.length > 0 && catalogData) {
      if (catalogData.tire) {
        if (!tireData.find((t) => t.id == catalogData.tire)) {
          alert("Le type de pneu de ce véhicule a été supprimé");
          return false;
        }
        setTire(tireData.find((t) => t.id == catalogData.tire).name);
      }
    }
  }, [tireData]);

  var { priceTTC, priceTotalHT, priceTotalTTC, discountPct } =
    vehicleTools.calcVehiclePrices(catalogData, TVA, TVA_INVERT);
  if (
    brandModelData.length == 0 &&
    catalogData.brand != "" &&
    !state.brandModel.isLoading &&
    catalogData.brand
  ) {
    bm_actions.getBrandModelByBrand(catalogData.brand);
  }
  if (
    brandModelData.length == 0 &&
    catalogData.brandModel != "" &&
    catalogData.catalog != "" &&
    !state.finish.isLoading
  ) {
    fin_actions.getFinishesByBrandModel(catalogData.brandModel, [
      catalogData.catalog,
    ]);
  }

  useEffect(() => {
    inputChange({
      target: {
        name: "discountPct",
        value: catalogData.discountPct,
      },
    });
  }, [rmOptions]);

  function inputChange(e) {
    if (e.target.name == "brand" && e.target.value != "") {
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "brandModel",
          value: "",
        },
      });
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "brandModelFinish",
          value: "",
        },
      });
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "fuelType",
          value: "",
        },
      });
      bm_actions.getBrandModelByBrand(e.target.value);
    }
    if (e.target.name == "brandModel" && e.target.value != "") {
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "brandModelFinish",
          value: "",
        },
      });
      fin_actions.getFinishesByBrandModel(e.target.value, [
        catalogData.catalog,
      ]);
    }
    if (e.target.name == "catalog") {
      fin_actions.getFinishesByBrandModel(catalogData.brandModel, [
        e.target.value,
      ]);
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "brandModelFinish",
          value: "",
        },
      });
    }
    if (e.target.name == "discount") {
      tools.recalculateDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }
    if (e.target.name == "discountPct") {
      tools.recalculateDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }

    if (e.target.name == "discountTTC") {
      tools.recalculateDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }
    if (e.target.name == "priceHT") {
      var cat = { ...catalogData, priceHT: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat, TVA);
      tools.recalculateDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );

      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }
    if (e.target.name == "priceTTC") {
      var cat = { ...catalogData, priceTTC: e.target.value };
      var priceHT = vehicleTools.calcHTFromTTC(cat, TVA_INVERT);
      tools.recalculateDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceHT",
          value: priceHT,
        },
      });
    }
    if (e.target.name == "type") {
      var cat = { ...catalogData, type: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat, TVA);
      if (e.target.value == 1) {
        var opts = JSON.parse(JSON.stringify(catalogData.optionsNotIncluded));
        opts = opts.map((op) => {
          op.isInclTVA = true;
          return op;
        });
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "optionsNotIncluded",
            value: opts,
          },
        });
      }

      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
      if (e.target.value == 2) {
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: catalogData.discount,
          },
        });
      } else {
        var discountTTC = catalogData.discount + catalogData.discount * TVA;
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: discountTTC,
          },
        });
      }
    }
    if (e.target.type == "checkbox") {
      e.target.value = e.target.checked;
    }
    if (e.target.name == "inroadCost") {
      var irTTC =
        Math.round(
          (parseFloat(e.target.value) +
            (catalogData.type == "1" ? parseFloat(e.target.value) * TVA : 0)) *
            100
        ) / 100;
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "inroadCostTTC",
          value: irTTC,
        },
      });
    }
    if (e.target.name == "inroadCostTTC") {
      var irHT =
        Math.round((parseFloat(e.target.value) / TVA_INVERT) * 100) / 100;
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "inroadCost",
          value: irHT,
        },
      });
    }
    tools.inputChange(e, CATALOG_TYPES.UPDATE_CATALOG_DATA, dispatch);
  }
  function addOptionNotIncluded() {
    var { optionsNotIncluded } = catalogData;
    optionsNotIncluded.push({
      name: "",
      priceHT: 0,
      isInclVR: false,
      isInclRM: false,
      isInclTVA: true,
    });
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }
  function changeOptionNotIncluded(e, k) {
    var opt = JSON.parse(JSON.stringify(catalogData.optionsNotIncluded));
    if (e.target.type == "checkbox") {
      opt[k][e.target.name.split("-")[1]] = e.target.checked ? true : false;
    } else {
      opt[k][e.target.name.split("-")[1]] = e.target.value;
    }
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: opt,
      },
    });
  }
  function deleteOptionNotIncluded(k) {
    var { optionsNotIncluded } = catalogData;

    optionsNotIncluded = optionsNotIncluded.filter((option, key) => key !== k);
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  return {
    state,
    catalogStoreData,
    catalogData,
    brandModelData,
    brandData,
    finishData,
    constantData,
    tireData,
    v_actions,
    priceTotalHT,
    priceTotalTTC,
    inputChange,
    addOptionNotIncluded,
    changeOptionNotIncluded,
    deleteOptionNotIncluded,
  };
};

export default useCatalogDetail;
