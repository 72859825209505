import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { quotationActions } from "../../../context/actions/quotationActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";
import { api_url } from "../../../config/config";
import Axios from "axios";
import QuotationSearch from "./QuotationSearch/QuotationSearch";
import tools from "../../../helpers/tools";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import StatusBadge from "../../common/StatusBadge/StatusBadge";
import BrandLogo from "../Brand/BrandLogo";
import { useAtom } from "jotai";
import { quotationSearchAtom } from "./QuotationSearch/useQuotationSearch";

const QuotationList = (props) => {
  const [state, dispatch] = useStore();

  var quotationData = state.quotation.items;
  var constantData = state.constant.items;

  var actions = quotationActions(state, dispatch, props);
  const [quotationSearch, setQuotationSearch] = useAtom(quotationSearchAtom);

  function removeQuotation(id) {
    if (
      window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")
    ) {
      Axios.delete(api_url + "quotations/" + id)
        .then((res) => {
          actions.getQuotations();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            alert(err.response.data.message);
          }
        });
    }
  }

  useEffect(() => {
    actions.getQuotations(quotationSearch);
  }, []);

  return (
    <CustomList getAction={actions.getQuotations}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Dossiers</h1>
              {state.quotation.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_DOSSIER}>
                  <Link className="btn btn-primary" to="/quotations/create">
                    <i className="fa fa-plus mr-2" /> Créer un dossier
                  </Link>
                </Can>
              )}
              <QuotationSearch handlePageParamsChange={actions.getQuotations} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Lignes</th>
                    <th>Informations</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {quotationData.map((quotation, k) => {
                    const quotationsByStatus = quotation.quotations.reduce(
                      (acc, q) => {
                        if (!acc[q.status.id]) {
                          acc[q.status.id] = [];
                        }
                        acc[q.status.id].push(q);
                        return acc;
                      },
                      {}
                    );

                    return (
                      <tr key={"brand-row-" + k}>
                        <td>
                          <Link
                            className="text-orange p-0 border-0"
                            to={"/quotations/" + quotation.uuid}
                          >
                            {quotation.reference}{" "}
                          </Link>
                          <div className="font-italic font-weight-lighter text-black-50  font-weight-light">
                            {quotation.quotations.length}{" "}
                            {tools.getStringPlural(
                              "ligne",
                              quotation.quotations.length > 1
                            )}
                          </div>
                        </td>

                        <td>
                          {Object.keys(quotationsByStatus).map((key) => {
                            const quotation = quotationsByStatus[key];
                            const lastQuotation =
                              quotation[quotation.length - 1];
                            return (
                              <div>
                                <StatusBadge
                                  statusId={parseInt(key)}
                                  statusName={`${
                                    tools.findIn(
                                      state.constant.items.QUOTATION_STATUS,
                                      "id",
                                      key
                                    ).name
                                  }`}
                                />
                                <BrandLogo
                                  brandName={lastQuotation.vehicle.brand.name}
                                />
                                {lastQuotation.vehicle.brand.name}{" "}
                                {lastQuotation.vehicle.brandModel.name}
                                <details style={{ color: "#aeaeae" }}>
                                  <summary>
                                    <span className="font-weight-light font-italic">
                                      + {quotation.length} autres
                                    </span>
                                  </summary>
                                  <div>
                                    {quotation?.map((q, kk) => (
                                      <QuotationItem kk={kk} q={q} />
                                    ))}
                                  </div>
                                </details>
                              </div>
                            );
                          })}
                          {/* {quotationsByStatus[1]?.map((q, kk) => (
                            <QuotationItem kk={kk} q={q} />
                          ))} */}
                        </td>
                        <td>
                          <div>
                            <span className="text-black-50 font-italic">
                              Client :{" "}
                            </span>
                            {quotation.client.name}
                          </div>
                          <div>
                            {" "}
                            <span className="text-black-50 font-italic">
                              Groupe :{" "}
                            </span>
                            {quotation.userGroup?.name}
                          </div>
                          <div>
                            {quotation.assignedTo ? (
                              <>
                                <span className="text-black-50 font-italic">
                                  Assigné à :{" "}
                                </span>{" "}
                                {quotation.assignedTo && (
                                  <>
                                    {tools.getFullname(quotation.assignedTo)}{" "}
                                    <RoleBadge
                                      roleName={quotation.assignedTo?.role.name}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <span className="text-black-50 font-italic">
                                Non assigné
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="text-black-50 font-italic">
                              Créé par :{" "}
                            </span>{" "}
                            {tools.getFullname(quotation.createdBy)}{" "}
                            <RoleBadge
                              roleName={quotation.createdBy?.role.name}
                            />{" "}
                            <span className="text-black-50 font-italic">
                              le :{" "}
                            </span>
                            {tools.formatDate(quotation.createdAt)}
                          </div>
                          {quotation.updatedBy && (
                            <div>
                              <span className="text-black-50 font-italic">
                                Modifié par :{" "}
                              </span>
                              {tools.getFullname(quotation.updatedBy)}{" "}
                              <RoleBadge
                                roleName={quotation.updatedBy?.role.name}
                              />{" "}
                              <span className="text-black-50 font-italic">
                                le :{" "}
                              </span>
                              {tools.formatDate(quotation.updatedAt)}
                            </div>
                          )}
                        </td>

                        <td>
                          <div className="d-flex">
                            <Can I={userAccessEnum.MODIFIER_DOSSIER}>
                              <Link
                                className="btn btn-primary mr-1"
                                to={"/quotations/" + quotation.uuid}
                              >
                                Ouvrir
                              </Link>
                            </Can>
                            <Can I={userAccessEnum.SUPPRIMER_DOSSIER}>
                              <button
                                title="Supprimer ce véhicule"
                                className="btn btn-danger  btn-sm"
                                onClick={() => removeQuotation(quotation.uuid)}
                              >
                                <i className="fa fa-times" />
                              </button>
                            </Can>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <CustomPagination
                total={state.quotation.total}
                activePage={quotationSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getQuotations({ ...quotationSearch, ...data });
                  setQuotationSearch({ ...quotationSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

const QuotationItem = ({ kk, q }) => {
  return (
    <div key={"qn" + kk}>
      <StatusBadge statusId={q.status.id} statusName={q.status.name} />
      {q.vehicle.brand.name} {q.vehicle.brandModel.name}
    </div>
  );
};

export const RoleBadge = ({ roleName }) => {
  return (
    <span className="badge font-weight-light badge-light">{roleName}</span>
  );
};

export default QuotationList;
