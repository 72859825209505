import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="display-1">404</h1>
        <h2 className="mb-4">Page non trouvée</h2>
        <p className="lead mb-4">
          Désolé, la page que vous recherchez n'existe pas ou a été déplacée.
        </p>
        <Link to="/" className="btn btn-primary">
          <i className="fa fa-home mr-2"></i>
          Retourner à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
