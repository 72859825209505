import React, { useEffect } from "react";
import CustomList from "../../common/CustomList";
import useStore from "../../../context/useStore";
import { financierActions } from "../../../context/actions/financierActions";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import CustomSearch from "../../common/CustomSearch";
import CustomPagination from "../../common/CustomPagination";
import NbSearchResults from "../../common/NbSearchResults";

const FinancierList = () => {
  const [state, dispatch] = useStore();
  var actions = financierActions(state, dispatch);

  useEffect(() => {
    actions.getFinanciers();
  }, []);

  return (
    <CustomList getAction={actions.getFinanciers}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Financeurs</h1>
              {state.financier.isLoading ? (
                <Loader />
              ) : (
                <Link className="btn btn-primary" to="/financiers/create">
                  <i className="fa fa-plus mr-2" /> Ajouter un financeur
                </Link>
              )}
              <CustomSearch handlePageParamsChange={handlePageParamsChange} />
              <NbSearchResults total={state.financier.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.financier.items.map((financier, k) => (
                    <tr key={"financier-row-" + k}>
                      <td>{financier.name}</td>
                      <td>
                        <Link
                          className="btn btn-secondary"
                          to={"/financiers/" + financier.uuid}
                        >
                          Modifier
                        </Link>
                        <button
                          className="btn btn-danger ml-2"
                          onClick={() =>
                            actions.deleteFinancier(financier.uuid)
                          }
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <CustomPagination
                total={state.financier.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default FinancierList;
