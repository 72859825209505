const ListSelector = ({
  name,
  required,
  label,
  value,
  change,
  data,
  multipleSelect = false,
}) => {
  const onSelectItem = (model) => {
    let newValue;

    if (multipleSelect) {
      value = [...value];
      newValue = value.includes(model.uuid)
        ? value.filter((uuid) => uuid !== model.uuid)
        : [...value, model.uuid];
    } else {
      newValue = value === model.uuid ? "" : model.uuid;
    }

    change({
      target: {
        name,
        value: newValue,
      },
    });
  };

  return (
    <div
      className="form-group mb-0 overflow-auto px-1"
      style={{ height: "400px" }}
    >
      <label>
        {label}
        {required && <span className="text-danger ml-1">*</span>}
      </label>
      {data.map((model, k) => {
        const isSelected = multipleSelect
          ? value.includes(model.uuid)
          : value === model.uuid;
        return (
          <button
            key={`brandModel${model.uuid}`}
            type="button"
            className={`col btn btn-sm ${
              isSelected ? "btn-primary" : "btn-default bg-white"
            }`}
            onClick={() => onSelectItem(model)}
          >
            {model.name}
          </button>
        );
      })}
    </div>
  );
};

export default ListSelector;
