import React from "react";
import { HashLoader } from "react-spinners";

const Loader = ({ text = "", size = 36 }) => (
  <div className="d-flex justify-content-center align-items-center flex-column w-100">
    <HashLoader size={size} color={"#ff8a00"} />
    <div>{text}</div>
  </div>
);

export default Loader;
