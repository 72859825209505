import React from "react";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import OptionsAndLayoutsNotIncluded from "./OptionsAndLayoutsNotIncluded";
import useCatalogDetail from "./useCatalogDetail";

const CatalogDetail = (props) => {
  const {
    state,
    catalogStoreData,
    catalogData,
    brandModelData,
    brandData,
    constantData,
    finishData,
    tireData,
    v_actions,
    priceTotalHT,
    priceTotalTTC,
    inputChange,
    addOptionNotIncluded,
    changeOptionNotIncluded,
    deleteOptionNotIncluded,
  } = useCatalogDetail(props);

  let isDisabled = false;
  if (catalogData.catalog) {
    const catalogRef = tools.findIn(
      state.catalogStore.items,
      "uuid",
      catalogData.catalog
    );
    isDisabled = catalogRef?.updatedByApi;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {!props.modal && <h1>Edition d'un véhicule du catalogue</h1>}
          <div className="row">
            <div className="col-4">
              <div className="card p-4">
                <h2>Identification</h2>
                <Control
                  label="Est caché ?"
                  type="checkbox"
                  name="isHidden"
                  change={inputChange}
                  checked={catalogData.isHidden}
                  className="mb-3"
                  disabled={isDisabled}
                />

                <Control
                  label="Catalogue"
                  type="select"
                  name="catalog"
                  value={catalogData.catalog}
                  change={inputChange}
                  error={state.error}
                  datas={catalogStoreData}
                  dataIndex="uuid"
                  required={true}
                />
                {isDisabled && (
                  <div className="alert alert-danger">
                    Les véhicules de ce catalogue sont importés depuis une
                    source externe et mis à jour quotidiennement. Il n'est donc
                    pas possible de créer ou de modifier les véhicules de ce
                    catalogue directement depuis l'application.
                  </div>
                )}
                {state.brand.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Marque"
                    type="select"
                    name="brand"
                    value={catalogData.brand}
                    change={inputChange}
                    error={state.error}
                    datas={brandData}
                    dataIndex="uuid"
                    required={true}
                    disabled={
                      isDisabled ||
                      state.brand.isLoading ||
                      state.brandModel.isLoading
                        ? "disabled"
                        : false
                    }
                  />
                )}

                {state.brandModel.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Modèle"
                    type="select"
                    name="brandModel"
                    required={true}
                    value={catalogData.brandModel}
                    dataIndex="uuid"
                    change={inputChange}
                    error={state.error}
                    datas={brandModelData}
                    disabled={isDisabled}
                  />
                )}

                <Control
                  label="Finition"
                  type="select"
                  name="brandModelFinish"
                  required={true}
                  value={catalogData.brandModelFinish}
                  dataIndex="uuid"
                  change={inputChange}
                  error={state.error}
                  datas={finishData}
                  disabled={isDisabled}
                />

                <div className="form-group">
                  <label htmlFor="finish">
                    Carburant :{" "}
                    {catalogData.brandModelFinish &&
                      tools.findIn(
                        finishData,
                        "uuid",
                        catalogData.brandModelFinish
                      ).fuelType?.name}
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="finish">
                    Type<span className="text-danger ml-1">*</span>
                  </label>
                  {constantData.VEHICLE_TYPES.map((type, k) => (
                    <div className="form-check" key={"typev-" + k}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        id={"type" + type.id}
                        value={type.id}
                        onChange={inputChange}
                        checked={
                          catalogData.type == type.id ? "checked" : false
                        }
                        disabled={isDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"type" + type.id}
                      >
                        {type.name}
                      </label>
                    </div>
                  ))}
                  <small className="form-text text-danger">
                    {state.error.type}
                  </small>
                </div>

                <Control
                  name="tire"
                  type="select"
                  value={catalogData.tire}
                  datas={tireData}
                  dataIndex="uuid"
                  change={inputChange}
                  required={true}
                  error={state.error}
                  label="Pneus"
                  disabled={isDisabled}
                />
              </div>
            </div>

            <div
              className={
                "col-8 " +
                (catalogData.brand != "" &&
                catalogData.brandModel != "" &&
                catalogData.brandModelFinish != ""
                  ? ""
                  : "d-none")
              }
            >
              <div className="card p-4">
                <h2>Prix</h2>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceHT">
                        Prix public HT{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                        <span className="text-danger ml-1">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="priceHT"
                          name="priceHT"
                          value={catalogData.priceHT}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <small className="form-text text-danger">
                          {state.error.priceHT}
                        </small>
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceTTC">
                        Prix public TTC{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="priceTTC"
                        name="priceTTC"
                        value={catalogData.priceTTC}
                        onChange={inputChange}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <OptionsAndLayoutsNotIncluded
                      vehicleData={catalogData}
                      addOptionNotIncluded={addOptionNotIncluded}
                      changeOptionNotIncluded={changeOptionNotIncluded}
                      deleteOptionNotIncluded={deleteOptionNotIncluded}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Remise HT</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="discountPct"
                          id="discountPct"
                          value={catalogData.discountPct}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="discount"
                          id="discount"
                          value={catalogData.discount}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Remise TTC</label>
                      <div className="input-group">
                        {/* <input
                          type="number"
                          className="form-control"
                          name="discountPctTTC"
                          id="discountPctTTC"
                          value={catalogData.discountPctTTC}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div> */}
                        <input
                          type="number"
                          className="form-control"
                          name="discountTTC"
                          id="discountTTC"
                          value={catalogData.discountTTC}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ TTC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="inroadCost">Mise à la route HT</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="inroadCost"
                          id="inroadCost"
                          value={catalogData.inroadCost}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="inroadCostTTC">Mise à la route TTC</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="inroadCostTTC"
                          id="inroadCostTTC"
                          value={catalogData.inroadCostTTC}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ TTC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="greyCardCost">
                        Carte grise + Carburant
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="greyCardCost"
                          id="greyCardCost"
                          value={catalogData.greyCardCost}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="malusBonusEco">
                        Bonus/Malus écologique (mettre une valeur négative pour un bonus)
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="malusBonusEco"
                          id="malusBonusEco"
                          value={catalogData.malusBonusEco}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="co2">Taux d'émission de CO2</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="co2"
                          id="co2"
                          value={catalogData.co2}
                          onChange={inputChange}
                          disabled={isDisabled}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">gr/km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 offset-6">Prix d'achat HT</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(Math.round(priceTotalHT * 100) / 100)}{" "}
                    €
                  </div>
                  <div className="col-3 offset-6">Prix d'achat TTC</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(
                      Math.round(priceTotalTTC * 100) / 100
                    )}{" "}
                    €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isDisabled && (
        <div className="row mt-3">
          <div className="col-12">
            {state.catalog.isLoading ? (
              <ClipLoader />
            ) : props.match.params.uuid == "create" || props.modal ? (
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={() => v_actions.createCatalog(catalogData)}
              >
                Créer {props.modal && "et choisir"}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-success mr-2"
                  onClick={() => v_actions.updateCatalog(catalogData)}
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  className="btn btn-dark mr-2"
                  onClick={() => v_actions.createCatalog(catalogData, true)}
                >
                  Dupliquer
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(CatalogDetail);
