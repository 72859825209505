import React, { useEffect } from "react";
import useStore from "../../context/useStore";
import { maintenanceActions } from "../../context/actions/maintenanceActions";
import vehicleTools from "../../helpers/vehicleTools";
import Control from "./Control";
import InputGroupWithResetValue from "./InputGroupWithResetValue";
import tools from "../../helpers/tools";

const CalcMaintenanceBox = ({ quotation, rlChange, num, disabled }) => {
  const [state, dispatch] = useStore();

  var {
    vehicle,
    dur,
    km,
    discountMaintenance,
    maintenance,
    maintenance_data,
    rateMaintenance,
    maintenanceValueFinal,
  } = quotation;

  var actions = maintenanceActions(state, dispatch);

  useEffect(() => {
    if (maintenance_data.length == 0 && !state.maintenance.isLoading) {
      actions.getMaintenancesFromVehicle(
        vehicle.brand.uuid,
        vehicle.brandModel.uuid,
        vehicle.brandModelFinish.fuelType.id
      );
    }
  }, [vehicle.uuid]);

  var maintenanceValueFinalCalc = "0";
  var rateMaintenanceCalc = "0";
  var rateMaintenanceInfo = "";

  if (
    maintenance_data.length == 0 &&
    !state.maintenance.isLoading &&
    state.maintenance.items.find(
      (r) =>
        r.brand.uuid == vehicle.brand.uuid &&
        r.brandModels.find((b) => b.uuid == vehicle.brandModel.uuid)
    )
  ) {
    rlChange({
      target: {
        name: "maintenance_data",
        value: JSON.parse(JSON.stringify(state.maintenance.items)),
      },
    });
  }

  if (maintenance) {
    let maintenanceRef = maintenance_data.find((r) => r.uuid === maintenance);
    var rmr = vehicleTools.getRateMaintenance(
      dur,
      km,
      maintenanceRef.tableValues,
      num
    );
    rateMaintenanceCalc = rmr.value;
    rateMaintenanceInfo = `${rmr.info} - ${maintenanceRef.catalog?.name}`;
  } else {
    if (maintenance_data.length > 0 && maintenance_data[0]) {
      rlChange({
        target: { name: "maintenance", value: maintenance_data[0].uuid },
      });
    }
  }
  if (rateMaintenance > 0) {
    maintenanceValueFinalCalc =
      Math.round(
        (parseFloat(rateMaintenance) -
          tools.getPercentOf(rateMaintenance, discountMaintenance)) *
          100
      ) / 100;
  }

  if (maintenance && rateMaintenance === "0") {
    rlChange({
      target: { name: "rateMaintenance", value: rateMaintenanceCalc },
    });
  }

  if (
    maintenance &&
    maintenanceValueFinal === "-1" &&
    maintenanceValueFinalCalc != "0"
  ) {
    rlChange({
      target: {
        name: "maintenanceValueFinal",
        value: maintenanceValueFinalCalc,
      },
    });
  }

  return (
    <>
      <Control
        label={rateMaintenanceInfo || "Maintenance choisie"}
        type="select"
        name="maintenance"
        dataIndex="uuid"
        dataLabel="name"
        value={maintenance}
        change={rlChange}
        datas={maintenance_data.map((m) => ({
          ...m,
          name: `${m.catalog?.name} - ${m.name}`,
        }))}
        disabled={disabled}
      />
      {/* <p className="text-info" style={{ fontSize: 12 }}>
        {rateMaintenanceInfo}
      </p> */}
      <>
        <InputGroupWithResetValue
          props={{
            name: "rateMaintenance",
            symbol: "€/m",
            label: "Prix de la maintenance",
            defaultValue: rateMaintenanceCalc,
          }}
          data={{ rateMaintenance }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
          disabled={disabled}
        />
        <Control
          label="Remise"
          name="discountMaintenance"
          value={discountMaintenance}
          change={rlChange}
          suffix="%"
          disabled={disabled}
        />

        <InputGroupWithResetValue
          props={{
            name: "maintenanceValueFinal",
            symbol: "€",
            label: "Prix maintenance retenu",
            defaultValue: maintenanceValueFinalCalc,
          }}
          data={{ maintenanceValueFinal }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
          disabled={disabled}
        />
      </>
    </>
  );
};

export default CalcMaintenanceBox;
