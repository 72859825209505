import React from "react";

const OverlayContent = ({ children, close }) => {
  return (
    <div className="overlay-content">
      <button
        className="btn btn-default"
        style={{
          width: "100%",
          margin: 0,
          border: 0,
          textDecoration: "underline"
        }}
        onClick={close}
      >
        <i className="fa fa-times mr-2"></i>Fermer
      </button>
      {children}
    </div>
  );
};

export default OverlayContent;
