import useCatalogSearch from "./useCatalogSearch";
import ListSelector from "../../common/ListSelector";
import React from "react";

const NewCatalogSearch = ({ handlePageParamsChange }) => {
  const { state, catalogSearch, actions } = useCatalogSearch({
    handlePageParamsChange,
  });

  return (
    <>
      <div className="col-2 px-1 text-center  ">
        <h5 className="text-black-50">Catalogue</h5>
        <ListSelector
          label=""
          name="catalogs"
          change={actions.onChangeSearch}
          value={catalogSearch.catalogs}
          data={state.catalogStore.items}
          multipleSelect={true}
        />
      </div>
      <div className="col-2 px-1 text-center  ">
        <h5 className="text-black-50">Marque</h5>
        <ListSelector
          label=""
          name="brand"
          change={actions.onChangeSearch}
          value={catalogSearch.brand}
          data={state.brand.items}
        />
      </div>
      <div className="col-2 px-1 text-center  ">
        <h5 className="text-black-50">Modèle</h5>
        <ListSelector
          label=""
          name="brandModel"
          change={actions.onChangeSearch}
          value={catalogSearch.brandModel}
          data={state.brandModel.items.filter((i) => {
            if (catalogSearch.brand === "") return true;
            return i.brand.uuid === catalogSearch.brand;
          })}
        />
      </div>
      <div className="col-2 px-1 text-center  ">
        <h5 className="text-black-50">Finition</h5>
        <ListSelector
          label=""
          name="brandModelFinish"
          change={actions.onChangeSearch}
          value={catalogSearch.brandModelFinish}
          data={state.finish.items}
        />
      </div>
    </>
  );
};

export default NewCatalogSearch;
