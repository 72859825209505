const constantAdapters = {
  transformForAPI: (data) => {
    let transformedData = [];
    Object.keys(data).forEach((key) => {
      transformedData.push({
        type: key,
        value: data[key],
      });
    });
    return {
      dynamicsConstants: transformedData,
    };
  },
  transformDynamicForBO: (data) => {
    let cst = {};
    data.forEach((item) => {
      const { type, ...rest } = item;
      cst[item.type] = rest.value;
    });
    return cst;
  },
  transformStaticForBO: (data) => {
    return data;
  },
  getLabelsFromDynamic: (data) => {
    const labels = {};
    Object.entries(data).forEach(([key, value]) => {
      labels[value.type] = value.label;
    });
    return labels;
  },
};

export default constantAdapters;
