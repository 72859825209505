const contractAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return data;
  },
};

export default contractAdapters;
