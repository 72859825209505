import React from "react";
import { getBrandLogoByName } from "../../../helpers/brandLogos";

const BrandLogo = ({ brandName }) => {
  const brandLogoKey = getBrandLogoByName(brandName);

  return <i className={`car-${brandLogoKey} brand-logo`}></i>;
};

export default BrandLogo;
