import React from "react";
import useRepurchaseSearch from "./useRepurchaseSearch";

const RepurchaseSearch = ({ handlePageParamsChange }) => {
  const { state, repurchaseSearch, actions } = useRepurchaseSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col-12 col-md-4">
          <label htmlFor="brandModel" className="mr-2">
            Rechercher par marque
          </label>
          <select
            className="form-control"
            name="brand"
            id="brand"
            onChange={actions.onChangeSearch}
            value={repurchaseSearch.brand}
          >
            <option value="">...</option>
            {state.brand.items.map((brand, k) => (
              <option key={"brand-" + brand.uuid} value={brand.uuid}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-4">
          <label htmlFor="brandModel" className="mr-2">
            Rechercher par modèle
          </label>
          <select
            className="form-control"
            name="brandModel"
            id="brandModel"
            onChange={actions.onChangeSearch}
            value={repurchaseSearch.brandModel}
          >
            <option value="">...</option>
            {state.brandModel.items
              .filter((i) => {
                if (repurchaseSearch.brand == "") return true;
                return i.brand.uuid == repurchaseSearch.brand;
              })
              .map((brandModel, k) => (
                <option
                  key={"brandModel-" + brandModel.uuid}
                  value={brandModel.uuid}
                >
                  {brandModel.name}
                </option>
              ))}
          </select>
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default RepurchaseSearch;
