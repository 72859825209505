import React from "react";
import Control from "../../common/Control";

const QuotationSort = ({ sort, setSort }) => {
  function changeSort(type) {
    var fil = JSON.parse(JSON.stringify(sort));
    if (sort.includes(type)) {
      fil = fil.filter((s) => s !== type);
    } else {
      fil.push(type);
    }
    setSort(fil);
  }

  return (
    <>
      <Control
        label="Marque"
        name="brand"
        type="checkbox"
        checked={sort.includes("brand")}
        change={() => changeSort("brand")}
      />
      <Control
        label="Modèle"
        name="brandModel"
        type="checkbox"
        checked={sort.includes("brandModel")}
        change={() => changeSort("brandModel")}
      />
      <Control
        label="Finition"
        name="finish"
        type="checkbox"
        checked={sort.includes("finish")}
        change={() => changeSort("finish")}
      />
      <Control
        label="Loi de roulage"
        name="rollingLaw"
        type="checkbox"
        checked={sort.includes("rollingLaw")}
        change={() => changeSort("rollingLaw")}
      />
    </>
  );
};

export default QuotationSort;
