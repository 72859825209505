import { useHistory, useLocation } from "react-router-dom";
import useStore from "../../../context/useStore";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { brandActions } from "../../../context/actions/brandActions";
import tools from "../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const brandModelSearchAtom = atom({
  search: "",
  name: "",
  brand: "",
});

const useBrandModelSearch = ({ handlePageParamsChange }) => {
  const [state, dispatch] = useStore();
  const brand_actions = brandActions(state, dispatch);
  const location = useLocation();
  const history = useHistory();

  const [brandModelSearch, setBrandModelSearch] = useAtom(brandModelSearchAtom);

  useEffect(() => {
    brand_actions.getAllBrands();
  }, []);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(brandModelSearch);
    },

    onResetSearch: () => {
      setBrandModelSearch({
        search: "",
        brand: "",
        name: "",
      });
      const newSearchParams = {
        search: "",
        brand: "",
        name: "",
      };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      const newSearchParams = { ...brandModelSearch, [name]: value };

      setBrandModelSearch({ ...newSearchParams, page: "1" });
    },
  };

  return {
    state,
    brandModelSearch,
    actions,
  };
};

export default useBrandModelSearch;
