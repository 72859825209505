import { withRouter } from "react-router-dom";
import useCustomList from "./useCustomList";

const CustomList = (props) => {
  const { activePage, handlePageParamsChange, search } = useCustomList(props);

  return props.children(activePage, handlePageParamsChange, search);
};

export default withRouter(CustomList);
