const quotationAdapters = {
  transformForAPI: (data) => {
    return {
      ...data,
      client: typeof data.client == "string" ? data.client : data.client?.uuid,
      assignedTo:
        typeof data.assignedTo == "string"
          ? data.assignedTo
          : data.assignedTo?.uuid,
      quotations: data.quotations.map((q, index) => ({
        ...q,
        order: parseInt(index) + 1,
        status: typeof q.status == "object" ? q.status.id : q.status,
      })),
    };
  },
  transformForBO: (data) => {
    const transformed = {
      ...data,
      quotations: data.quotations.map((q) => ({
        ...q,
        maintenance_data: q.maintenance_data ? q.maintenance_data : [],
        repurchase_data: q.repurchase_data ? q.repurchase_data : [],
        contracts: q.contracts || [],
      })),
    };
    return transformed;
  },
};

export default quotationAdapters;
