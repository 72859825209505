import React, { useState } from "react";

const AddRowModal = (props) => {
  var [value, setValue] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    props.addRow(value);
    setValue("");
  }

  return (
    <div
      className="modal fade"
      id="addRowModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <form onSubmit={onSubmit} className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Ajouter une ligne
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="periodDate">Entrez la durée de la ligne</label>
              <span className="text-danger">
                Entrez uniquement un nombre sans espace (ex : 36)
              </span>
              <input
                type="number"
                className="form-control"
                name="colValue"
                id="colValue"
                value={value}
                onChange={(e) => setValue(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button type="submit" className="btn btn-success">
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRowModal;
