import React from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import ExcelCopyModal from "../../common/ExcelCopyModal";
import { DateTime } from "luxon";
import AddColumnModal from "./AddColumnModal";
import AddRowModal from "./AddRowModal";
import Control from "../../common/Control";
import useRepurchaseDetail from "./hooks/useRepurchaseDetail";

const RepurchaseDetail = (props) => {
  const [state, dispatch] = useStore();
  const {
    repurchaseData,
    brandModelData,
    brandData,
    constantData,
    userRole,
    r_actions,
    repurchaseDetailActions,
  } = useRepurchaseDetail(props);

  const {
    inputChange,
    toggleBrandModels,
    toggleFuelTypes,
    tableValueChange,
    deleteCol,
    deleteRow,
    addColumn,
    addRow,
    formatExcelData,
  } = repurchaseDetailActions;

  var tableRows = [];
  for (let key in repurchaseData.tableValues.values) {
    var row = repurchaseData.tableValues.values[key];
    var tds = [];
    row.forEach((r, k) => {
      tds.push(
        <td key={"tdr-" + key + "-" + k}>
          <input
            type="text"
            className="form-control px-1 text-center"
            name={"val"}
            onChange={(e) => tableValueChange(e, key, k)}
            value={r}
          />
        </td>
      );
    });
    tableRows.push(
      <tr key={"trr-" + key}>
        <td className="font-weight-bold">
          <button
            className="btn btn-default px-1 py-0 mr-1 btn-round"
            onClick={() => deleteRow(key)}
          >
            <i style={{ color: "red" }} className="fa fa-minus" />
          </button>
          {key}
        </td>
        {tds}
      </tr>
    );
  }
  tableRows.push(
    <tr key={"trmodal-666666"}>
      <td className="text-center">
        <button
          className="btn btn-secondary"
          data-toggle="modal"
          data-target="#addRowModal"
        >
          <i className="fa fa-plus" />
        </button>
      </td>
    </tr>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'une valeur de rachat</h1>
          <h4>{repurchaseData.name || ""}</h4>
          <div className="row">
            <div className="col-12">
              {/* <Control
                label="Nom"
                type="text"
                name="name"
                disabled
                value={repurchaseData.name}
                change={inputChange}
                error={state.error}
              /> */}
              <Control
                label="Marque"
                type="select"
                name="brand"
                change={inputChange}
                value={repurchaseData.brand}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
                datas={brandData}
                dataIndex="uuid"
                dataLabel="name"
                error={state.error}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="models">Modèles</label>
                    {brandModelData.map((brandModel, k) => (
                      <Control
                        key={brandModel.uuid}
                        label={brandModel.name}
                        type="checkbox"
                        name="brandModel"
                        change={toggleBrandModels}
                        checked={
                          repurchaseData.brandModels.find(
                            (bm) => bm == brandModel.uuid
                          )
                            ? true
                            : false
                        }
                        disabled={
                          (repurchaseData.nextPeriod ||
                            repurchaseData.previousPeriod) &&
                          userRole != "SUPER_ADMIN"
                            ? true
                            : false
                        }
                        k={brandModel.uuid}
                      />
                    ))}
                    <small className="form-text text-danger">
                      {state.error.brandModels}
                    </small>
                  </div>

                  <Control
                    label="Prix du kilomètre supplémentaire"
                    type="text"
                    name="kmPrice"
                    value={repurchaseData.kmPrice}
                    change={inputChange}
                    error={state.error}
                    suffix="€"
                  />
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="fuelType">Carburant</label>
                    {constantData.FUEL_TYPES.map((fuelType, k) => (
                      <Control
                        key={fuelType.id}
                        label={fuelType.name}
                        type="checkbox"
                        name="brandModel"
                        change={toggleFuelTypes}
                        checked={
                          repurchaseData.fuelTypes.find(
                            (ft) => ft == fuelType.id
                          )
                            ? true
                            : false
                        }
                        disabled={
                          (repurchaseData.nextPeriod ||
                            repurchaseData.previousPeriod) &&
                          userRole != "SUPER_ADMIN"
                            ? true
                            : false
                        }
                        k={fuelType.id}
                      />
                    ))}
                    <small className="form-text text-danger">
                      {state.error.fuelTypes}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <Control
                label="Valide du"
                type="date"
                name="validFrom"
                value={DateTime.fromJSDate(
                  new Date(repurchaseData.validFrom)
                ).toFormat("yyyy-MM-dd")}
                change={inputChange}
                error={state.error.validFrom}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
              />
              <Control
                label="Au"
                type="date"
                name="validTo"
                value={
                  repurchaseData.validTo
                    ? DateTime.fromJSDate(
                        new Date(repurchaseData.validTo)
                      ).toFormat("yyyy-MM-dd")
                    : ""
                }
                change={inputChange}
                error={state.error}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
              />

              <div className="row">
                <div className="col-6 text-left">
                  {repurchaseData.previousPeriod && (
                    <a
                      className="btn btn-secondary"
                      href={"/repurchases/" + repurchaseData.previousPeriod}
                    >
                      <i className="fa fa-chevron-left mr-2" />
                      Période précédente
                    </a>
                  )}
                </div>
                <div className="col-6 text-right">
                  {repurchaseData.nextPeriod && (
                    <a
                      className="btn btn-secondary"
                      href={"/repurchases/" + repurchaseData.nextPeriod}
                    >
                      Période suivante
                      <i className="fa fa-chevron-right ml-2" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <h3>Tableau des valeurs</h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Durée/Km</th>
                    {repurchaseData.tableValues.km.map((km, k) => (
                      <th className="text-center" key={"km-" + km}>
                        <button
                          className="btn btn-default px-1 py-0 mr-1 btn-round"
                          onClick={() => deleteCol(k)}
                        >
                          <i style={{ color: "red" }} className="fa fa-minus" />
                        </button>
                        {km}
                      </th>
                    ))}
                    <th className="text-center">
                      <button
                        className="btn btn-secondary"
                        data-toggle="modal"
                        data-target="#addColumnModal"
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#excelCopyModal"
          >
            Copier depuis excel
          </button>
          {state.repurchase.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success mr-2"
              onClick={() => r_actions.saveRepurchase(repurchaseData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
      <ExcelCopyModal formatExcelData={formatExcelData} />
      <AddColumnModal addColumn={addColumn} />
      <AddRowModal addRow={addRow} />
    </div>
  );
};

export default withRouter(RepurchaseDetail);
