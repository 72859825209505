import axios from "axios";
import { api_url } from "../config/config";
import finishAdapters from "../adapters/finishAdapters";
import tools from "../helpers/tools";

export const finishService = {
  getFinishes: (search) => {
    return axios.get(`${api_url}brand-model-finishes/search${search}`);
  },
  getFinish: (uuid) => {
    return axios.get(`${api_url}brand-model-finishes/${uuid}`);
  },
  getFinishesByBrandModel: (brandModelUuid, catalogs = []) => {
    const searchString = tools.getSearchString({
      brandModel: brandModelUuid,
      catalogs: catalogs,
      paginate: "0",
    });
    return axios.get(`${api_url}brand-model-finishes/search${searchString}`);
  },
  createFinish: (data) => {
    return axios.post(
      `${api_url}brand-model-finishes`,
      finishAdapters.transformForAPI(data)
    );
  },
  updateFinish: (data) => {
    return axios.put(
      `${api_url}brand-model-finishes/${data.uuid}`,
      finishAdapters.transformForAPI(data)
    );
  },
};
