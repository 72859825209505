import tools from "../../helpers/tools";
import { TIRE_TYPES } from "../reducers/tireReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { tireService } from "../../services/tireService";

export const tireActions = (state, dispatch, props) => {
  function getTires(search = "") {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    tireService
      .getTires()
      .then((res) => {
        dispatch({
          type: TIRE_TYPES.GET_TIRES,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function getTire(uuid) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    tireService
      .getTire(uuid)
      .then((res) => {
        dispatch({
          type: TIRE_TYPES.GET_TIRE,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function createTire(tireData) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    tireService
      .createTire(tireData)
      .then((res) => {
        dispatch({
          type: TIRE_TYPES.GET_TIRE,
          payload: res.data,
        });
        tools.filterAlert(
          "success",
          tireData.name + " a été correctement créé."
        );
        getTires();
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function updateTire(tireData) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    tireService
      .updateTire(tireData)
      .then((res) => {
        tools.filterAlert(
          "success",
          tireData.name + " a été correctement mis à jour."
        );
        getTires();
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function deleteTire(uuid) {
    if (
      !window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")
    )
      return false;
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    tireService
      .deleteTire(uuid)
      .then((res) => {
        getTires();
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }

  return {
    createTire,
    updateTire,
    getTires,
    getTire,
    deleteTire,
  };
};
