export const FINANCIER_TYPES = {
  LOAD_FINANCIER: "LOAD_FINANCIER",
  GET_FINANCIERS: "GET_FINANCIERS",
  GET_FINANCIER: "GET_FINANCIER",
  UPDATE_FINANCIER_DATA: "UPDATE_FINANCIER_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    name: "",
    envelop: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FINANCIER_TYPES.LOAD_FINANCIER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case FINANCIER_TYPES.GET_FINANCIERS:
      return {
        ...state,
        items: action.payload.data,
        item: initialState.item,
        total: action.payload.meta.total,
        isLoading: false,
      };

    case FINANCIER_TYPES.GET_FINANCIER:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case FINANCIER_TYPES.UPDATE_FINANCIER_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
