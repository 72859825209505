import axios from "axios";
import { api_url } from "../config/config";
import vehicleAdapters from "../adapters/vehicleAdapters";

export const vehicleService = {
  getVehicles: (search) => {
    return axios.get(`${api_url}fleet-vehicles/search${search}`);
  },
  getVehicle: (uuid) => {
    return axios.get(`${api_url}fleet-vehicles/${uuid}`);
  },
  createVehicle: (data) => {
    return axios.post(
      `${api_url}fleet-vehicles`,
      vehicleAdapters.transformForAPI(data)
    );
  },
  updateVehicle: (data) => {
    return axios.put(
      `${api_url}fleet-vehicles/${data.uuid}`,
      vehicleAdapters.transformForAPI(data)
    );
  },
};
