import React, { useEffect } from "react";
import Loader from "../../common/Loader";

import Control from "../../common/Control";
import tools from "../../../helpers/tools";

const ValidQuotationsList = ({
  changeQuotation,
  quotations,
  quotationData,
  disabled,
}) => {
  return (
    <div className="row">
      <div
        className="col-12   "
        style={{
          maxHeight: 500,
          overflowY: "scroll",
        }}
      >
        <div className="row mx-0 bg-light border rounded  ">
          {quotationData ? (
            quotationData
              .sort((a, b) => (a.reference > b.reference ? 1 : -1))
              .map((s, k) => {
                const validQuotationDetails = s.quotations.filter(
                  (q) => q.status == 2
                );
                return validQuotationDetails.length <= 0 ? null : (
                  <div className="col-12" key={"vql-" + k}>
                    <details className="list-unstyled" key={"sim" + k}>
                      <summary>
                        <strong className="text-orange">{s.reference}</strong>{" "}
                        <small className="font-italic mr-2">
                          lignes :{" "}
                          <strong>{validQuotationDetails.length}</strong>
                        </small>
                        <small className="font-italic">
                          modifié :{" "}
                          <strong>{tools.formatDate(s.updatedAt)}</strong>
                        </small>
                      </summary>
                      {validQuotationDetails.map((q, kk) => {
                        return (
                          <li key={"liq" + kk}>
                            <div className="pl-3">
                              <Control
                                type="checkbox"
                                label={q.reference}
                                name={q.uuid}
                                value={q.uuid}
                                change={(e) => changeQuotation(e, q)}
                                disabled={disabled}
                                checked={
                                  quotations.find(
                                    (qs) => qs.quotation == q.uuid
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </li>
                        );
                      })}
                    </details>
                  </div>
                );
              })
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidQuotationsList;
