const catalogAdapters = {
  transformForAPI: (data) => {
    const { uuid, createdAt, updatedAt, isHidden, catalog, ...rest } = data;
    return {
      uuid,
      createdAt,
      updatedAt,
      catalog,
      vehicle: rest,
    };
  },
  transformForBO: (data) => {
    return {
      ...data,
      brand: data.brand?.uuid,
      brandModel: data.brandModel?.uuid,
      brandModelFinish: data.brandModelFinish?.uuid,
      tire: data.tire?.uuid,
      type: data.type?.id,
      catalog: data.catalog?.uuid,
    };
  },
};

export default catalogAdapters;
