import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { tireActions } from "../../../context/actions/tireActions";
import Control from "../../common/Control";
import { TireForm } from "./TireForm";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";

const TireDetail = (props) => {
  const [state, dispatch] = useStore();

  const [tireEdit, setTireEdit] = useState(null);

  var actions = tireActions(state, dispatch, props);
  var tireData = state.tire.items;

  useEffect(() => {
    actions.getTires();
  }, []);

  const onSaveTire = () => {
    actions.updateTire(tireEdit);
    setTireEdit(null);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Pneus</h1>
          <Can I={userAccessEnum.CREER_PNEU}>
            <TireForm />
          </Can>
          <NbSearchResults total={state.tire.total} />
          <table className="table">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prix unitaire</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tireData.map((t, k) => (
                <tr>
                  {tireEdit && tireEdit.uuid == t.uuid ? (
                    <>
                      <td>
                        <Control
                          name="name"
                          value={tireEdit.name}
                          change={(e) =>
                            setTireEdit({ ...tireEdit, name: e.target.value })
                          }
                        />
                      </td>
                      <td>
                        <Control
                          type="number"
                          name="price"
                          value={tireEdit.price}
                          change={(e) =>
                            setTireEdit({ ...tireEdit, price: e.target.value })
                          }
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{t.name}</td>
                      <td>{t.price}</td>
                    </>
                  )}

                  <td>
                    {tireEdit && tireEdit.uuid == t.uuid ? (
                      <button
                        className="btn btn-success mr-2"
                        onClick={onSaveTire}
                      >
                        Enregistrer
                      </button>
                    ) : (
                      <Can I={userAccessEnum.MODIFIER_PNEU}>
                        <button
                          className="btn btn-primary mr-2"
                          onClick={() => setTireEdit(t)}
                        >
                          Modifier
                        </button>
                      </Can>
                    )}
                    <Can I={userAccessEnum.SUPPRIMER_PNEU}>
                      <button
                        className="btn btn-danger"
                        onClick={() => actions.deleteTire(t.uuid)}
                      >
                        Supprimer
                      </button>
                    </Can>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TireDetail;
