import React from "react";
import UserList from "../pages/User/UserList";
import tools from "../../helpers/tools";
import useStore from "../../context/useStore";

const UserModal = (props) => {
  const [state] = useStore();
  const currentUser = state.auth.user;

  function selectUser(user) {
    document.getElementById("close-user-modal").click();
    props.selectUser(user);
  }

  function assignSelf() {
    document.getElementById("close-user-modal").click();
    props.selectUser(currentUser);
  }

  var content = <UserList modal={true} selectUser={selectUser} />;

  return (
    <>
      <div className="d-flex">
        <button
          type="button"
          className={`btn ${
            props.selectedUser ? "btn-primary" : "btn-default bg-white"
          }`}
          data-toggle="modal"
          data-target="#userModal"
        >
          {props.selectedUser
            ? `${tools.getFullname(props.selectedUser)} ${
                props.selectedUser.role?.name
                  ? `(${props.selectedUser.role?.name})`
                  : ""
              }`
            : "Choisir un utilisateur"}
        </button>
        {!props.selectedUser && (
          <button
            type="button"
            className="btn btn-success ml-2"
            onClick={assignSelf}
            title="M'assigner ce dossier"
          >
            <i className="fa fa-user-check"></i> M'assigner
          </button>
        )}
      </div>
      <div
        className="modal fade"
        id="userModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sélection de l'utilisateur
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mt-4">{content}</div>
            </div>
            <div className="modal-footer">
              <button
                id="close-user-modal"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserModal;
