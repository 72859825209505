export const VEHICLE_TYPES = {
  LOAD_VEHICLE: "LOAD_VEHICLE",
  GET_VEHICLES: "GET_VEHICLES",
  GET_VEHICLE: "GET_VEHICLE",
  UPDATE_VEHICLE_DATA: "UPDATE_VEHICLE_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    immat: "",
    serialNumber: "",
    inroadDate: "",
    brand: "",
    brandModel: "",
    finish: "",
    type: "",
    priceHT: "",
    options: [],
    optionsNotIncluded: [],
    isInclVR: false,
    inroadCost: "",
    greyCardCost: "",
    malusBonusEco: "",
    discount: "",
    discountPct: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLE_TYPES.LOAD_VEHICLE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case VEHICLE_TYPES.GET_VEHICLES:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.meta.total,
        item: initialState.item,
        isLoading: false,
      };

    case VEHICLE_TYPES.GET_VEHICLE:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case VEHICLE_TYPES.UPDATE_VEHICLE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
