import { USER_TYPES } from "../reducers/userReducers";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { userService } from "../../services/userService";
import userAdapters from "../../adapters/userAdapters";

export const userActions = (state, dispatch, props) => {
  function getUsers() {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    userService
      .getAllUsers()
      .then((res) => {
        dispatch({
          type: USER_TYPES.GET_USERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER);
      });
  }
  function getUser(uuid) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    userService
      .getUser(uuid)
      .then((res) => {
        dispatch({
          type: USER_TYPES.GET_USER,
          payload: userAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER);
      });
  }
  function createUser(userData) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    userService
      .createUser(userData)
      .then((res) => {
        dispatch({
          type: USER_TYPES.GET_USER,
          payload: userAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          userData.firstname +
            " " +
            userData.lastname +
            " a été correctement créé."
        );
        props.history.push("/users/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER);
      });
  }
  function updateUser(userData) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    userService
      .updateUser(userData)
      .then((res) => {
        dispatch({
          type: USER_TYPES.GET_USER,
          payload: userAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          userData.firstname +
            " " +
            userData.lastname +
            " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER);
      });
  }
  function deleteUser(uuid) {
    if (
      !window.confirm(
        "Êtes-vous certain(e) de vouloir supprimer cette entrée ?"
      )
    )
      return false;
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    userService
      .deleteUser(uuid)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER);
      });
  }

  return {
    createUser,
    updateUser,
    getUsers,
    getUser,
    deleteUser,
  };
};
