import { toast } from "react-toastify";
import useStore from "../../context/useStore";
import tools from "../../helpers/tools";

const useTVA = () => {
  const [state] = useStore();

  const getTVAByCatalog = (catalogUuid) => {
    if (!catalogUuid) {
      toast.error("TVA could not be found because catalogUuid is not defined");
      return { TVA: -1, TVA_INVERT: -1 };
    }
    const catalogRef = tools.findIn(
      state.catalogStore.items,
      "uuid",
      catalogUuid
    );
    if (!catalogRef?.geographicZone) {
      toast.error(
        "TVA could not be found because catalogRef.geographicZone is not defined"
      );
      return {
        TVA: -1,
        TVA_INVERT: -1,
      };
    }
    return {
      TVA: catalogRef?.geographicZone?.tva,
      TVA_INVERT: catalogRef?.geographicZone?.tva + 1,
    };
  };
  return { getTVAByCatalog };
};

export default useTVA;
