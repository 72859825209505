import { useHistory, useLocation } from "react-router-dom";
import useStore from "../../../../context/useStore";
import { useState } from "react";
import queryString from "query-string";
import tools from "../../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const quotationSearchAtom = atom({
  search: "",
  clientName: "",
  client: "",
  reference: "",
  userGroups: [],
  catalogs: [],
});

const useQuotationSearch = ({ handlePageParamsChange }) => {
  const [state] = useStore();
  const location = useLocation();
  const history = useHistory();

  const [quotationSearch, setQuotationSearch] = useAtom(quotationSearchAtom);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(quotationSearch);
    },

    onResetSearch: () => {
      const newSearchParams = {
        search: "",
        clientName: "",
        client: "",
        reference: "",
        userGroups: [],
        catalogs: [],
      };
      setQuotationSearch(newSearchParams);
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      setQuotationSearch({ ...quotationSearch, [name]: value, page: "1" });
    },
  };

  return {
    state,
    quotationSearch,
    actions,
  };
};

export default useQuotationSearch;
