const vehicleAdapter = {
  transformForAPI: (data) => {
    const {
      uuid,
      createdAt,
      updatedAt,
      isHidden,
      immat,
      serialNumber,
      inroadDate,
      status,
      ...rest
    } = data;
    return {
      uuid,
      createdAt,
      updatedAt,
      isHidden,
      immat,
      serialNumber,
      inroadDate,
      status,
      vehicle: rest,
    };
  },
  transformForBO: (data) => {
    return {
      ...data,
      brand: data.brand?.uuid,
      brandModel: data.brandModel?.uuid,
      brandModelFinish: data.brandModelFinish?.uuid,
      tire: data.tire?.uuid,
      type: data.type?.id,
      status: data.status?.id,
    };
  },
};

export default vehicleAdapter;
