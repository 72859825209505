import axios from "axios";
import { api_url } from "../config/config";
import repurchaseAdapters from "../adapters/repurchaseAdapters";

export const repurchaseService = {
  getRepurchases: (search) => {
    return axios.get(`${api_url}repurchases/search${search}`);
  },
  getRepurchase: (uuid) => {
    return axios.get(`${api_url}repurchases/${uuid}`);
  },
  getRepurchasesFromVehicle: (brandUuid, brandModelUuid, fuelTypeId) => {
    const search = `?paginate=0&brand=${brandUuid}&brandModel=${brandModelUuid}&fuelType=${fuelTypeId}`;
    return axios.get(`${api_url}repurchases/search${search}`);
  },
  createRepurchase: (data) => {
    return axios.post(
      `${api_url}repurchases`,
      repurchaseAdapters.transformForAPI(data)
    );
  },
  updateRepurchase: (data) => {
    return axios.put(
      `${api_url}repurchases/${data.uuid}`,
      repurchaseAdapters.transformForAPI(data)
    );
  },
  addPeriodRepurchase: (uuid, date) => {
    return axios.post(`${api_url}repurchases/${uuid}/add-period`, {
      date: date,
    });
  },
};
