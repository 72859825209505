import axios from "axios";
import { api_url } from "../config/config";
import contractAdapters from "../adapters/contractAdapters";

export const contractService = {
  getAllContracts: () => {
    return axios.get(`${api_url}contracts/search?paginate=0`);
  },
  getContracts: (search) => {
    return axios.get(`${api_url}contracts/search${search}`);
  },
  getContract: (uuid) => {
    return axios.get(`${api_url}contracts/${uuid}`);
  },
  createContract: (data) => {
    return axios.post(
      `${api_url}contracts`,
      contractAdapters.transformForAPI(data)
    );
  },
  updateContract: (data) => {
    return axios.put(
      `${api_url}contracts/${data.uuid}`,
      contractAdapters.transformForAPI(data)
    );
  },
};
