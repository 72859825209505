export const GROUP_TYPES = {
  LOAD_GROUP: "LOAD_GROUP",
  GET_GROUPS: "GET_GROUPS",
  GET_GROUP: "GET_GROUP",
  UPDATE_GROUP_DATA: "UPDATE_GROUP_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    name: "",
    catalogs: "",
    isAdmin: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GROUP_TYPES.LOAD_GROUP:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GROUP_TYPES.GET_GROUPS:
      return {
        ...state,
        items: action.payload.data,
        item: initialState.item,
        total: action.payload.meta.total,
        isLoading: false,
      };

    case GROUP_TYPES.GET_GROUP:
      return {
        ...state,
        item: action.payload,
        // items: initialState.items,
        isLoading: false,
      };

    case GROUP_TYPES.UPDATE_GROUP_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
