export const FINISH_TYPES = {
  LOAD_FINISH: "LOAD_FINISH",
  GET_FINISHES: "GET_FINISHES",
  UPDATE_FINISHES: "UPDATE_FINISHES",
  GET_FINISH: "GET_FINISH",
  UPDATE_FINISH_DATA: "UPDATE_FINISH_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    name: "",
    brandModel: "",
    fuelType: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FINISH_TYPES.LOAD_FINISH:
      return {
        ...state,
        isLoading: action.payload,
      };
    case FINISH_TYPES.GET_FINISHES:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.meta.total,
        item: initialState.item,
        isLoading: false,
      };
    case FINISH_TYPES.UPDATE_FINISHES:
      return {
        ...state,
        items: action.payload.data,
        item: initialState.item,
        isLoading: false,
      };

    case FINISH_TYPES.GET_FINISH:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case FINISH_TYPES.UPDATE_FINISH_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
