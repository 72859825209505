import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { VEHICLE_TYPES } from "../reducers/vehicleReducers";
import { vehicleService } from "../../services/vehicleService";
import vehicleAdapter from "../../adapters/vehicleAdapters";

export const vehicleActions = (state, dispatch, props) => {
  function getVehicles(search = { search: null }) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      brand: search.brand,
      brandModel: search.brandModel,
      brandModelFinish: search.brandModelFinish,
      immat: search.immat,
      page: search.page,
    });

    vehicleService
      .getVehicles(search)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }
  function getVehicle(uuid) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    vehicleService
      .getVehicle(uuid)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: vehicleAdapter.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }
  function createVehicle(vehicleData, isDuplicate = false) {
    if (isDuplicate) {
      if (
        !window.confirm(
          "Êtes-vous certain(e) de vouloir dupliquer ce véhicule ?"
        )
      ) {
        return false;
      }
      delete vehicleData.id;
      vehicleData.optionsNotIncluded = vehicleData.optionsNotIncluded.map(
        (opt) => ({ ...opt, uuid: null })
      );
      vehicleData.immat = "Copie de " + vehicleData.immat;
    }
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    vehicleService
      .createVehicle(vehicleData)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: vehicleAdapter.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          vehicleData.immat + " a été correctement créé."
        );
        if (props.modal) {
          props.selectVehicle(res.data);
        } else {
          props.history.push("/vehicles/" + res.data.uuid);
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }

  function updateVehicle(vehicleData) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    vehicleService
      .updateVehicle(vehicleData)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: vehicleAdapter.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          "Ce véhicule a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }

  return {
    createVehicle,
    updateVehicle,
    getVehicles,
    getVehicle,
  };
};
