import React from "react";
import useContractSearch from "./useContractSearch";
import Control from "../../common/Control";

const ContractSearch = ({ handlePageParamsChange }) => {
  const { state, contractSearch, actions } = useContractSearch({
    handlePageParamsChange,
  });

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col">
          <Control
            label="Chercher par nom"
            name="name"
            id="name"
            type="text"
            value={contractSearch.name}
            change={actions.onChangeSearch}
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContractSearch;
