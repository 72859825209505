import React from "react";
import Control from "../../common/Control";
import { DateTime } from "luxon";

const PeriodSelector = ({
  period,
  k,
  inputPeriodChange,
  removePeriod,
  disabled,
  totalPeriods,
  quotation,
}) => {
  const controlClassName = "mb-2";

  return (
    <div className="row">
      <div className="col-12">
        <h4>
          Période {period.num}
          <button className="btn btn-danger btn-sm ml-2" onClick={removePeriod}>
            <i className="fa fa-times" />
          </button>
        </h4>
        <div className="row">
          <div className="col-12">
            <Control
              label="Du"
              type="date"
              name="dBegin"
              disabled={disabled}
              value={
                period.dBegin
                  ? DateTime.fromJSDate(new Date(period.dBegin)).toFormat(
                      "yyyy-MM-dd"
                    )
                  : period.dBegin
              }
              change={inputPeriodChange}
              className={controlClassName}
              k={k}
            />
            <Control
              label="Au"
              type="date"
              name="dEnd"
              disabled={true}
              value={
                period.dEnd
                  ? DateTime.fromJSDate(new Date(period.dEnd)).toFormat(
                      "yyyy-MM-dd"
                    )
                  : period.dEnd
              }
              change={inputPeriodChange}
              className={controlClassName}
              k={k}
            />
            <Control
              label="Durée"
              type="number"
              name="duration"
              value={period.duration}
              change={inputPeriodChange}
              disabled={disabled}
              className={controlClassName}
              k={k}
              suffix="mois"
            />

            <Control
              label="Mensualité"
              type="number"
              name="monthPayment"
              disabled={disabled}
              value={period.monthPayment}
              change={inputPeriodChange}
              className={controlClassName}
              k={k}
              suffix="€"
            />

            <Control
              label="Mensualité retenue"
              type="number"
              name="monthPaymentReal"
              value={period.monthPaymentReal}
              change={inputPeriodChange}
              className={controlClassName}
              k={k}
              suffix="€"
            />
            {(k == 0 || k < totalPeriods - 1) && (
              <Control
                label="VR"
                type="number"
                name="vr"
                value={period.vr}
                change={inputPeriodChange}
                className={controlClassName}
                k={k}
                suffix="€"
              />
            )}
            {k > 0 && k == totalPeriods - 1 && (
              <Control
                label="Valeur résiduelle"
                type="number"
                name="residualValue"
                value={period.residualValue}
                change={inputPeriodChange}
                className={controlClassName}
                k={k}
                suffix="€"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodSelector;
