import React from "react";

import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import NotFound from "./components/pages/NotFound/NotFound";

import { routeConfig } from "./config/routeConfig";
import Axios from "axios";
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";

const AppRoutes = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status == 401 && state.auth.isAuthenticated) {
        actions.logoutUser();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Switch>
      {routeConfig.map((route) => {
        return route.private ? (
          <PrivateRoute
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ) : (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
