import tools from "../../helpers/tools";
import { QUOTATION_TYPES } from "../reducers/quotationReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { quotationService } from "../../services/quotationService";
import quotationAdapters from "../../adapters/quotationAdapters";

export const quotationActions = (state, dispatch, props) => {
  function getQuotations(search = { search: null }) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      clientName: search.clientName,
      client: search.client,
      catalogs: search.catalogs,
      reference: search.reference,
      userGroups: search.userGroups,
      page: search.page,
    });

    quotationService
      .getQuotations(search)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function getValidQuotations(search = { search: null }) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      clientName: search.clientName,
      client: search.client,
      catalogs: search.catalogs,
      reference: search.reference,
      userGroups: search.userGroups,
      quotationDetails: search.quotationDetails,
      page: search.page,
    });
    quotationService
      .getValidQuotations(search)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_VALID_QUOTATIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function getQuotation(uuid) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    quotationService
      .getQuotation(uuid)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: quotationAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function createQuotation(quotationData) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    quotationService
      .createQuotation(quotationData)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: quotationAdapters.transformForBO(res.data),
        });
        tools.filterAlert("success", "Nouveau devis créé.");
        props.history.push("/quotations/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  function updateQuotation(quotationData) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    quotationService
      .updateQuotation(quotationData)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: quotationAdapters.transformForBO(res.data),
        });
        tools.filterAlert("success", "Devis enregistré.");
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  function updateQuotationStatus(sid, qid, status, quantity = 1, caracs = []) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    quotationService
      .updateQuotationStatus(sid, qid, status, quantity, caracs)
      .then((res) => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: quotationAdapters.transformForBO(res.data),
        });
        tools.filterAlert("success", "Statut du devis mis à jour.");
      })
      .catch((err) => {
        console.log(err.response);
        alert(err.response);
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  return {
    createQuotation,
    updateQuotation,
    getQuotations,
    getQuotation,
    updateQuotationStatus,
    getValidQuotations,
  };
};
