export const CONTRACT_TYPES = {
  LOAD_CONTRACT: "LOAD_CONTRACT",
  GET_CONTRACTS: "GET_CONTRACTS",
  GET_CONTRACT: "GET_CONTRACT",
  UPDATE_CONTRACT_DATA: "UPDATE_CONTRACT_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    status: 1,
    type: 1,
    dBegin: "",
    dEnd: "",
    client: null,
    vehicle: null,
    reference: "",
    insuranceRate: null,
    firstRentInput: "0",
    mileage: "20000",
    nbTires: "4",
    replacementVehicleCost: "0",
    marginTTC: null,
    discount: "0",
    breakPadsCost: "0",
    airFilterCost: "0",
    oilFilterCost: "0",
    dieselFilterCost: "0",
    clutchCost: "0",
    tirePrice: "0",
    financeRate: null,
    applicationFee: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONTRACT_TYPES.LOAD_CONTRACT:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CONTRACT_TYPES.GET_CONTRACTS:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.meta.total,
        item: initialState.item,
        isLoading: false,
      };

    case CONTRACT_TYPES.GET_CONTRACT:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false,
      };

    case CONTRACT_TYPES.UPDATE_CONTRACT_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
