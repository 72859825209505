import axios from "axios";
import { api_url } from "../config/config";
import groupAdapters from "../adapters/groupAdapters";

export const groupService = {
  getAllGroups: () => {
    return axios.get(`${api_url}user-groups/search?paginate=0`);
  },
  getGroups: (search) => {
    return axios.get(`${api_url}user-groups/search${search}`);
  },
  getGroup: (uuid) => {
    return axios.get(`${api_url}user-groups/${uuid}`);
  },
  createGroup: (data) => {
    return axios.post(
      `${api_url}user-groups`,
      groupAdapters.transformForAPI(data)
    );
  },
  updateGroup: (data) => {
    return axios.put(
      `${api_url}user-groups/${data.uuid}`,
      groupAdapters.transformForAPI(data)
    );
  },
};
