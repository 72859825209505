import { useEffect, useState } from "react";
import { VEHICLE_TYPES } from "../../../context/reducers/vehicleReducers";
import useStore from "../../../context/useStore";
import vehicleTools from "../../../helpers/vehicleTools";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { vehicleActions } from "../../../context/actions/vehicleActions";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import tools from "../../../helpers/tools";
import { finishActions } from "../../../context/actions/finishActions";
import useTVA from "../../../hooks/useInit/useTVA";

const useVehicleDetail = (props) => {
  const [state, dispatch] = useStore();
  const [hasLoaded, setHasLoaded] = useState(false);

  var vehicleData = state.vehicle.item;
  var brandModelData = state.brandModel.items;
  var finishData = state.finish.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var fin_actions = finishActions(state, dispatch, props);

  var v_actions = vehicleActions(state, dispatch, props);
  const { getTVAByCatalog } = useTVA();
  let { TVA, TVA_INVERT } = vehicleData.catalog
    ? getTVAByCatalog(vehicleData.catalog)
    : { TVA: -1, TVA_INVERT: -1 };

  var rmOptions = vehicleData.optionsNotIncluded.reduce((a, b) => {
    if (b.isInclRM) {
      return a + parseFloat(b.priceHT);
    } else {
      return a;
    }
  }, 0);

  useEffect(() => {
    b_actions.getAllBrands();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
      },
    });
    if (props.match.params.uuid != "create" && !props.modal) {
      v_actions.getVehicle(props.match.params.uuid);
    }
  }, []);

  var { priceTTC, priceTotalHT, priceTotalTTC, discountPct } =
    vehicleTools.calcVehiclePrices(vehicleData, TVA, TVA_INVERT);

  if (
    brandModelData.length == 0 &&
    vehicleData.brand != "" &&
    !state.brandModel.isLoading &&
    vehicleData.brand &&
    !hasLoaded
  ) {
    setHasLoaded(true);
    bm_actions.getBrandModelByBrand(vehicleData.brand);
  }
  if (
    brandModelData.length == 0 &&
    vehicleData.brandModel != "" &&
    !state.finish.isLoading
  ) {
    fin_actions.getFinishesByBrandModel(vehicleData.brandModel);
  }

  function inputChange(e) {
    if (e.target.name == "brand" && e.target.value != "") {
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "brandModel",
          value: "",
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "brandModelFinish",
          value: "",
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "fuelType",
          value: "",
        },
      });
      bm_actions.getBrandModelByBrand(e.target.value);
    }
    if (e.target.name == "brandModel" && e.target.value != "") {
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "brandModelFinish",
          value: "",
        },
      });
      fin_actions.getFinishesByBrandModel(e.target.value);
    }
    if (e.target.name == "discount") {
      tools.recalculateVehicleDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        vehicleData,
        rmOptions
      );
    }
    if (e.target.name == "discountPct") {
      tools.recalculateVehicleDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        vehicleData,
        rmOptions
      );
    }
    if (e.target.name == "priceHT") {
      var cat = { ...vehicleData, priceHT: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat, TVA);
      tools.recalculateVehicleDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        vehicleData,
        rmOptions
      );

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }
    if (e.target.name == "priceTTC") {
      var cat = { ...vehicleData, priceTTC: e.target.value };
      var priceHT = vehicleTools.calcHTFromTTC(cat, TVA_INVERT);
      tools.recalculateVehicleDiscount(
        TVA,
        TVA_INVERT,
        e.target.name,
        e.target.value,
        dispatch,
        vehicleData,
        rmOptions
      );
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceHT",
          value: priceHT,
        },
      });
    }
    if (e.target.name == "type") {
      var cat = { ...vehicleData, type: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat, TVA);
      if (e.target.value == 1) {
        var opts = JSON.parse(JSON.stringify(vehicleData.optionsNotIncluded));
        opts = opts.map((op) => {
          op.isInclTVA = true;
          return op;
        });
        dispatch({
          type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
          payload: {
            key: "optionsNotIncluded",
            value: opts,
          },
        });
      }

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }

    tools.inputChange(e, VEHICLE_TYPES.UPDATE_VEHICLE_DATA, dispatch);
  }
  function addOptionNotIncluded() {
    var { optionsNotIncluded } = vehicleData;
    optionsNotIncluded.push({
      name: "",
      priceHT: 0,
      isInclVR: false,
      isInclTVA: true,
    });
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  function changeOptionNotIncluded(e, k) {
    var opt = JSON.parse(JSON.stringify(vehicleData.optionsNotIncluded));
    if (e.target.type == "checkbox") {
      opt[k][e.target.name.split("-")[1]] = e.target.checked ? true : false;
    } else {
      opt[k][e.target.name.split("-")[1]] = e.target.value;
    }
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: opt,
      },
    });
  }
  function deleteOptionNotIncluded(k) {
    var { optionsNotIncluded } = vehicleData;

    optionsNotIncluded = optionsNotIncluded.filter((option, key) => key !== k);
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  return {
    state,
    dispatch,
    hasLoaded,
    vehicleData,
    brandModelData,
    finishData,
    brandData,
    constantData,
    b_actions,
    bm_actions,
    v_actions,
    priceTTC,
    priceTotalHT,
    priceTotalTTC,
    discountPct,
    inputChange,
    addOptionNotIncluded,
    changeOptionNotIncluded,
    deleteOptionNotIncluded,
  };
};

export default useVehicleDetail;
