import tools from "../../helpers/tools";
import { FINANCIER_TYPES } from "../reducers/financierReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import financierAdapters from "../../adapters/financierAdapters";
import { financierService } from "../../services/financierService";
import { toast } from "react-toastify";

export const financierActions = (state, dispatch, props) => {
  function getAllFinanciers() {
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financierService
      .getAllFinanciers()
      .then((res) => {
        dispatch({
          type: FINANCIER_TYPES.GET_FINANCIERS,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }
  function getFinanciers(search = { search: null }) {
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    financierService
      .getFinanciers(search)
      .then((res) => {
        dispatch({ type: FINANCIER_TYPES.GET_FINANCIERS, payload: res.data });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }
  function getFinancier(uuid) {
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financierService
      .getFinancier(uuid)
      .then((res) => {
        dispatch({
          type: FINANCIER_TYPES.GET_FINANCIER,
          payload: financierAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }
  function saveFinancier(financierData) {
    if (financierData.uuid) {
      updateFinancier(financierData);
    } else {
      createFinancier(financierData);
    }
  }
  function createFinancier(financierData) {
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financierService
      .createFinancier(financierData)
      .then((res) => {
        dispatch({
          type: FINANCIER_TYPES.GET_FINANCIER,
          payload: financierAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          financierData.name + " a été correctement créé."
        );
        props.history.push("/financiers/" + res.data.uuid);
      })
      .catch((err) => {
        toast.error(
          `Une erreur est survenue lors de la création du financeur ${err.response?.data?.message}`
        );

        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }
  function updateFinancier(financierData) {
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financierService
      .updateFinancier(financierData)
      .then((res) => {
        dispatch({
          type: FINANCIER_TYPES.GET_FINANCIER,
          payload: financierAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          financierData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        toast.error(
          `Une erreur est survenue lors de la mise à jour du financeur ${err.response?.data?.message}`
        );

        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }
  function deleteFinancier(uuid) {
    if (
      !window.confirm(
        "Êtes-vous certain(e) de vouloir supprimer cette entrée ?"
      )
    )
      return false;
    dispatch({ type: FINANCIER_TYPES.LOAD_FINANCIER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    financierService
      .deleteFinancier(uuid)
      .then((res) => {
        getFinanciers();
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCIER_TYPES.LOAD_FINANCIER
        );
      });
  }

  return {
    saveFinancier,
    createFinancier,
    updateFinancier,
    getFinanciers,
    getFinancier,
    getAllFinanciers,
    deleteFinancier,
  };
};
