import { useHistory, useLocation } from "react-router-dom";
import { brandModelActions } from "../../../../context/actions/brandModelActions";
import useStore from "../../../../context/useStore";
import { useEffect, useState } from "react";
import queryString from "query-string";
import tools from "../../../../helpers/tools";
import { atom, useAtom } from "jotai";

export const finishSearchAtom = atom({
  search: "",
  brandModel: "",
  catalogs: [],
});

const useFinishSearch = ({ handlePageParamsChange }) => {
  const [state, dispatch] = useStore();
  const brand_model_actions = brandModelActions(state, dispatch);
  const location = useLocation();
  const history = useHistory();

  const [finishSearch, setFinishSearch] = useAtom(finishSearchAtom);

  useEffect(() => {
    brand_model_actions.getAllBrandModels();
  }, []);

  const actions = {
    onTriggerSearch: (params) => {
      handlePageParamsChange(params);
      tools.updateQueryParams(history, location, params);
    },

    onSubmit: (e) => {
      e.preventDefault();
      actions.onTriggerSearch(finishSearch);
    },

    onResetSearch: () => {
      setFinishSearch({ search: "", brandModel: "", catalogs: [] });
      const newSearchParams = { search: "", brandModel: "", catalogs: [] };
      actions.onTriggerSearch(newSearchParams);
    },

    onChangeSearch: (e) => {
      const { name, value } = e.target;
      setFinishSearch({ ...finishSearch, [name]: value, page: "1" });
    },
  };

  return {
    state,
    finishSearch,
    actions,
  };
};

export default useFinishSearch;
