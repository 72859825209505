import React from "react";
import vehicleTools from "../../../helpers/vehicleTools";
import tools from "../../../helpers/tools";
import useTVA from "../../../hooks/useInit/useTVA";

const OptionsAndLayoutsNotIncluded = ({
  vehicleData,
  addOptionNotIncluded,
  changeOptionNotIncluded,
  deleteOptionNotIncluded,
}) => {
  var { optionsNotIncluded } = vehicleData;

  const { getTVAByCatalog } = useTVA();

  let { TVA, TVA_INVERT } = vehicleData?.catalog
    ? getTVAByCatalog(vehicleData.catalog)
    : { TVA: -1, TVA_INVERT: -1 };

  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Options et aménagements non inclus</th>
            <th>Prix HT</th>
            <th>Prix TTC</th>
            <th>Calcul V.R</th>
            {vehicleData.type == 2 && <th>Appliquer TVA ?</th>}
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {optionsNotIncluded.map((option, k) => (
            <tr key={"option-" + k}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name={"option-name-" + k}
                  value={option.name}
                  onChange={(e) => changeOptionNotIncluded(e, k)}
                />
              </td>
              <td>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    name={"option-priceHT-" + k}
                    value={option.priceHT}
                    onChange={(e) => changeOptionNotIncluded(e, k)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">€</span>
                  </div>
                </div>
              </td>
              <td>
                {tools.beautiFyNumber(
                  option.isInclTVA
                    ? vehicleTools.calcPriceTTC(option.priceHT, TVA)
                    : option.priceHT
                )}{" "}
                €
              </td>
              <td>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name={"option-isInclVR-" + k}
                    id={"option-isInclVR-" + k}
                    onChange={(e) => changeOptionNotIncluded(e, k)}
                    checked={option.isInclVR ? "checked" : false}
                  />
                  <label
                    htmlFor={"option-isInclVR-" + k}
                    className="custom-control-label"
                  >
                    {option.isInclVR ? "Oui" : "Non"}
                  </label>
                </div>
              </td>
              {vehicleData.type == 2 && (
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name={"option-isInclTVA-" + k}
                      id={"option-isInclTVA-" + k}
                      onChange={(e) => changeOptionNotIncluded(e, k)}
                      checked={option.isInclTVA ? "checked" : false}
                    />
                    <label
                      htmlFor={"option-isInclTVA-" + k}
                      className="custom-control-label"
                    >
                      {option.isInclTVA ? "Oui" : "Non"}
                    </label>
                  </div>
                </td>
              )}
              <td>
                <button
                  className="btn btn-default px-1 py-0 mr-1 btn-round"
                  onClick={() => deleteOptionNotIncluded(k)}
                >
                  <i style={{ color: "red" }} className="fa fa-minus" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-secondary" onClick={addOptionNotIncluded}>
        <i className="fa fa-plus mr-2" />
        Ajouter une option non incluse
      </button>
    </>
  );
};

export default OptionsAndLayoutsNotIncluded;
