import React from "react";
import useStore from "../../context/useStore";
import { Route } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import AuthMiddleware from "../../middlewares/AuthMiddleware";
import Login from "../pages/Login/Login";
import ConstantMiddleware from "../../middlewares/ConstantMiddleware";
import CatalogStoreMiddleware from "../../middlewares/CatalogStoreMiddleware";
import GroupMiddleware from "../../middlewares/GroupMiddleware";
import { Link } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [state] = useStore();

  return state.auth.isLoading ? (
    <ClipLoader />
  ) : (
    <AuthMiddleware>
      <ConstantMiddleware>
        <GroupMiddleware>
          <CatalogStoreMiddleware>
            <Route
              {...rest}
              render={(props) =>
                state.auth.isAuthenticated === true ? (
                  <Component {...props} />
                ) : (
                  <Login />
                )
              }
            />
          </CatalogStoreMiddleware>
        </GroupMiddleware>
      </ConstantMiddleware>
    </AuthMiddleware>
  );
};

export default PrivateRoute;
