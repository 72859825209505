import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import CatalogModal from "../../common/CatalogModal";
import ClientModal from "../../common/ClientModal";
import Control from "../../common/Control";
import QuotationFinalValues from "./QuotationFinalValues";
import GenerateContractModal from "./GenerateContractModal";
import QuotationSort from "./QuotationSort";
import QuotationFilter from "./QuotationFilter";
import OverlayContent from "../../common/OverlayContent";
import QuotationPdf from "./QuotationPdf";
import QuotationRecapPdf from "./QuotationRecapPdf";
import ClientQuotationModal from "../../common/ClientQuotationModal";
import useQuotationDetail from "./hooks/useQuotationDetail";
import UserModal from "../../common/UserModal";
import tools from "../../../helpers/tools";

const QuotationDetail = (props) => {
  const {
    state,
    selectedClient,
    setSelectedClient,
    selectedUser,
    setSelectedUser,
    selectedVehicle,
    setSelectedVehicle,
    dur,
    setDur,
    km,
    setKm,
    quotationOpened,
    setQuotationOpened,
    overlayOpened,
    sort,
    setSort,
    filters,
    setFilters,
    hideKeys,
    pdfOpen,
    setPdfOpen,
    recapOpen,
    setRecapOpen,
    quotationSelected,
    setQuotationSelected,
    tableWidth,
    setTableWidth,
    openClientQuotationModal,
    clientQuotations,
    quotationData,
    constantData,
    clientData,
    tireData,
    quotationDetailActions,
    isParametersOpen,
    setIsParametersOpen,
  } = useQuotationDetail(props);

  return (
    <div className="container-fluid" style={{ marginBottom: 800 }}>
      <div className="row">
        <div className="col-12">
          <h1>
            Dossier{" "}
            {quotationData.reference && "(" + quotationData.reference + ")"}
          </h1>

          <div className="row">
            <div className="col-12 ">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0">
                      Pour le client<span className="text-danger ml-1">*</span>
                    </h5>
                    <div className="mx-2">
                      <ClientModal
                        selectedClient={selectedClient}
                        selectClient={setSelectedClient}
                      />
                      <small className="form-text text-danger">
                        {state.error.client}
                      </small>
                      <ClientQuotationModal
                        content={clientQuotations}
                        openClientQuotationModal={openClientQuotationModal}
                      />
                    </div>

                    <h5 className="mb-0">
                      géré par
                      <span className="text-danger ml-1">*</span>
                    </h5>
                    <div className="mx-2">
                      <UserModal
                        selectedUser={selectedUser}
                        selectUser={setSelectedUser}
                      />
                      <small className="form-text text-danger">
                        {state.error.assignedTo}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  no-print">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="btn btn-default btn-md bg-white"
                  onClick={() => setIsParametersOpen((o) => !o)}
                >
                  <i className="fa fa-cog" />
                </button>
                <div className="d-flex align-items-center">
                  <div
                    style={{ fontSize: 14 }}
                    className="d-flex align-items-center mr-2"
                  >
                    <i className="fa fa-arrows-alt-h mr-1" /> {tableWidth}%
                  </div>
                  <input
                    min="100"
                    max="200"
                    step="1"
                    value={tableWidth}
                    onChange={(e) => setTableWidth(e.target.value)}
                    type="range"
                    className="form-control-range"
                    id="formControlRange"
                  />
                </div>
              </div>
              {isParametersOpen && (
                <div
                  className="mb-3"
                  style={{ background: "transparent", border: 0 }}
                >
                  <div className="row">
                    <div className="col-2">
                      <h4>Trier</h4>
                      <QuotationSort sort={sort} setSort={setSort} />
                    </div>
                    <div className="col-10">
                      <h4>Filtrer</h4>
                      <QuotationFilter
                        filters={filters}
                        setFilters={setFilters}
                        constants={constantData}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12">
              <div className="" style={{ overflowX: "scroll" }}>
                <div className="accordion" id="quotation-accordion">
                  <table
                    className="table bg-transparent"
                    id="quotable"
                    style={{ width: tableWidth + "%" }}
                  >
                    <tbody>
                      {quotationData.quotations.length > 0 ? (
                        quotationData.quotations.map((rl, k) => (
                          <QuotationFinalValues
                            key={"qfv" + k}
                            index={k}
                            quotation={rl}
                            setQuotationStatus={
                              quotationDetailActions.setQuotationStatus
                            }
                            rlVehicleChange={(e) =>
                              quotationDetailActions.rlVehicleChange(e, k)
                            }
                            deleteQuotation={() =>
                              quotationDetailActions.deleteQuotation(k)
                            }
                            rlChange={(e) =>
                              quotationDetailActions.rlChange(e, k)
                            }
                            setQuotationOpened={() =>
                              setQuotationOpened({ q: rl, i: k })
                            }
                            toggleOverlay={quotationDetailActions.toggleOverlay}
                            overlayOpened={overlayOpened}
                            navQuotation={quotationDetailActions.navQuotation}
                            totalQuotation={quotationData.quotations.length}
                            hideKeys={hideKeys}
                            tireData={tireData}
                            openPdf={() => quotationDetailActions.openPdf(rl)}
                            setQuotationSelected={setQuotationSelected}
                            isCreate={props.match.params.id == "create"}
                            duplicateQuotation={
                              quotationDetailActions.duplicateQuotation
                            }
                            changeLineOrder={
                              quotationDetailActions.changeLineOrder
                            }
                            tableWidth={tableWidth}
                          />
                        ))
                      ) : state.quotation.isLoading ? (
                        <tr className="text-center col-12">
                          <td colSpan="13">
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <p className="text-muted">
                          Aucune loi de roulage n'a encore été ajoutée
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-12 p-0  no-print"
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 99,
                boxShadow: "grey 0px 0px 20px -6px",
              }}
            >
              <div className="card pt-2">
                <div className="m-auto">
                  <div className="mr-2 d-inline-block">
                    <Control
                      name="dur"
                      id="dur"
                      label="Durée"
                      value={dur}
                      change={(e) => setDur(e.target.value)}
                      onClick={() => {
                        if (dur == "0") setDur("");
                      }}
                      onBlur={() => {
                        if (dur == "") setDur("0");
                      }}
                    />
                  </div>
                  <div className="mr-2 d-inline-block">
                    <Control
                      name="km"
                      id="km"
                      label="Kilométrage"
                      value={km}
                      change={(e) => setKm(e.target.value)}
                      onClick={() => {
                        if (km == "0") setKm("");
                      }}
                      onBlur={() => {
                        if (km == "") setKm("0");
                      }}
                    />
                  </div>
                  <div className="form-group mr-2 d-inline-block">
                    <button
                      type="button"
                      className={`btn btn-${
                        selectedVehicle ? "success" : "default"
                      }`}
                      data-toggle="modal"
                      data-target="#catalogModal"
                    >
                      {selectedVehicle
                        ? `${Object.keys(selectedVehicle).length} 
                          ${tools.getStringPlural(
                            "véhicule",
                            Object.keys(selectedVehicle).length > 1
                          )} 
                          ${tools.getStringPlural(
                            "choisi",
                            Object.keys(selectedVehicle).length > 1
                          )}`
                        : "Choisir des véhicules"}
                    </button>
                    <small className="form-text text-danger">
                      {state.error.vehicle}
                    </small>
                  </div>
                  <button
                    className={`btn btn-primary mr-2 
                      ${
                        dur != "0" && km != "0" && selectedVehicle
                          ? ""
                          : "disabled"
                      }`}
                    onClick={
                      dur != "0" && km != "0" && selectedVehicle
                        ? quotationDetailActions.addRollingLaw
                        : () => {}
                    }
                  >
                    {selectedVehicle ? (
                      <>
                        {" "}
                        <i className="fa fa-plus mr-2" />
                        {`
                    Ajouter ${
                      Object.keys(selectedVehicle).length
                    } loi(s) de roulage
                    `}
                      </>
                    ) : (
                      `Choisissez des véhicules`
                    )}
                  </button>
                  {state.quotation.isLoading ? (
                    <Loader />
                  ) : props.match.params.uuid == "create" ? (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => quotationDetailActions.save("create")}
                    >
                      Créer le devis
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => quotationDetailActions.save("update")}
                    >
                      Enregistrer le devis
                    </button>
                  )}
                  {quotationSelected.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-success d-block "
                      onClick={quotationDetailActions.openPdfQuotationList}
                    >
                      <i className="fa fa-file-pdf mr-2" />
                      Voir les dossiers sélectionnés
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {quotationOpened.q && (
        <GenerateContractModal
          quotation={quotationOpened.q}
          setQuotationStatus={quotationDetailActions.setQuotationStatus}
          index={quotationOpened.i}
        />
      )}
      <CatalogModal
        selectedVehicle={selectedVehicle}
        selectVehicle={setSelectedVehicle}
      />
      {pdfOpen && (
        <OverlayContent close={() => setPdfOpen(false)}>
          <QuotationPdf
            quotation={pdfOpen}
            client={clientData.find(
              (c) =>
                quotationData.client == c.uuid ||
                quotationData.client.uuid == c.uuid
            )}
            date={quotationData.updatedAt}
          />
        </OverlayContent>
      )}
      {recapOpen && (
        <OverlayContent close={() => setRecapOpen(false)}>
          <QuotationRecapPdf
            quotations={recapOpen}
            client={clientData.find(
              (c) =>
                quotationData.client == c.uuid ||
                quotationData.client.uuid == c.uuid
            )}
            date={DateTime.local().toFormat("dd/MM/yyyy")}
            constantData={constantData}
          />
        </OverlayContent>
      )}
    </div>
  );
};

export default withRouter(QuotationDetail);
