import React from "react";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import { constantActions } from "../../../context/actions/constantActions";
import { CONSTANT_TYPES } from "../../../context/reducers/constantReducers";
import DynamicList from "../../common/DynamicList";
import Control from "../../common/Control";

const ConstantDynamic = () => {
  const [state, dispatch] = useStore();
  var constantData = state.constant.dynamicItems;
  var constantLabels = state.constant.labels;
  var actions = constantActions(state, dispatch);

  function inputChange(e) {
    tools.inputChange(e, CONSTANT_TYPES.UPDATE_DYNAMIC_CONSTANT_DATA, dispatch);
  }

  // let constants = [
  //   {
  //     name: "FINANCE_RATE_DEFAULT",
  //     symbol: "%",
  //     label: "Taux de financement par défaut",
  //   },
  //   {
  //     name: "FINANCE_RATE_MIN",
  //     symbol: "%",
  //     label: "Taux de financement minimum",
  //   },
  //   { name: "APPLICATION_FEES", symbol: "%", label: "Frais de dossier" },
  //   {
  //     name: "MARGIN_RATE_DEFAULT",
  //     symbol: "%",
  //     label: "Taux de marge par défaut",
  //   },
  //   { name: "MARGIN_RATE_MIN", symbol: "%", label: "Taux de marge minimum" },
  //   { name: "INSURANCE_RATE", symbol: "%", label: "Taux d'assurance" },
  //   {
  //     name: "INSURANCE_COST_DAY",
  //     symbol: "€",
  //     label: "Coût de l'assurance par jour",
  //   },
  //   {
  //     name: "INSURANCE_COST_VEHICLE",
  //     symbol: "€",
  //     label: "Coût de l'assurance par véhicule",
  //   },
  //   {
  //     name: "INSURANCE_RATE_FINANCIAL_LOSS",
  //     symbol: "%",
  //     label: "Taux d'assurance Option sérénité",
  //   },
  //   {
  //     name: "REPLACEMENT_VEHICULE_COST_DEFAULT",
  //     symbol: "€",
  //     label: "Valeur par défaut pour un véhicule de remplacement",
  //   },
  // ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Constantes dynamiques</h1>
        </div>
        <div className="col-12 col-md-6">
          <div>
            <label>Mentions des devis</label>
            <DynamicList
              uniqueKey={"QUOTATION_MENTIONS"}
              items={constantData.QUOTATION_MENTIONS}
              change={inputChange}
              element="QUOTATION_MENTIONS"
              fields={[{ name: "name", type: "text", label: "Texte" }]}
              btnLabel="Ajouter une mention"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {Object.keys(constantData).map((key) => {
            const entry = constantData[key];
            return ["number", "string"].includes(typeof entry) ? (
              <Control
                key={key}
                name={key}
                value={entry}
                change={inputChange}
                label={constantLabels[key]}
                type="number"
              />
            ) : null;
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => actions.updateConstant(constantData)}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConstantDynamic;
