import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { finishActions } from "../../../context/actions/finishActions";
import Loader from "../../common/Loader";
import { FINISH_TYPES } from "../../../context/reducers/finishReducers";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";

const FinishDetail = (props) => {
  const [state, dispatch] = useStore();

  var finishData = state.finish.item;

  var bm_actions = brandModelActions(state, dispatch, props);
  var fin_actions = finishActions(state, dispatch, props);

  useEffect(() => {
    bm_actions.getAllBrandModels();
    if (props.match.params.uuid != "create") {
      fin_actions.getFinish(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, FINISH_TYPES.UPDATE_FINISH_DATA, dispatch);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'une finition</h1>
          <Control
            label="Catalogue"
            name="catalog"
            id="catalog"
            type="select"
            datas={state.catalogStore.items}
            dataIndex="uuid"
            dataLabel="name"
            change={inputChange}
            value={finishData.catalog}
            error={state.error}
          />
          <Control
            label="Finition"
            name="name"
            id="name"
            change={inputChange}
            value={finishData.name}
            error={state.error.name}
          />
          <Control
            label="Modèle"
            name="brandModel"
            id="brandModel"
            type="select"
            datas={state.brandModel.items}
            dataIndex="uuid"
            dataLabel="name"
            change={inputChange}
            value={finishData.brandModel}
            error={state.error}
          />

          <Control
            label="Carburant"
            name="fuelType"
            id="fuelType"
            type="select"
            datas={state.constant.items.FUEL_TYPES}
            dataIndex="id"
            dataLabel="name"
            change={inputChange}
            value={finishData.fuelType}
            error={state.error}
          />

          {state.finish.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => fin_actions.saveFinish(finishData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FinishDetail);
