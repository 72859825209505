import tools from "../../helpers/tools";
import { CATALOG_STORE_TYPES } from "../reducers/catalogStoreReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import catalogStoreAdapters from "../../adapters/catalogStoreAdapters";
import { catalogStoreService } from "../../services/catalogStoreService";

export const catalogStoreActions = (state, dispatch, props) => {
  function getMyCatalogStores() {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogStoreService
      .getAllCatalogStores()
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORES,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }
  function getAllCatalogStores() {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogStoreService
      .getAllCatalogStores()
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORES,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }
  function getCatalogStores(search = { search: null }) {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    catalogStoreService
      .getCatalogStores(search)
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }
  function getCatalogStore(uuid) {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogStoreService
      .getCatalogStore(uuid)
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORE,
          payload: catalogStoreAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }
  function saveCatalogStore(catalogStoreData) {
    if (catalogStoreData.uuid) {
      updateCatalogStore(catalogStoreData);
    } else {
      createCatalogStore(catalogStoreData);
    }
  }
  function createCatalogStore(catalogStoreData) {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogStoreService
      .createCatalogStore(catalogStoreData)
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORE,
          payload: catalogStoreAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          catalogStoreData.name + " a été correctement créé."
        );
        getAllCatalogStores();
        props.history.push("/catalogstores/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }
  function updateCatalogStore(catalogStoreData) {
    dispatch({ type: CATALOG_STORE_TYPES.LOAD_CATALOG_STORE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    catalogStoreService
      .updateCatalogStore(catalogStoreData)
      .then((res) => {
        dispatch({
          type: CATALOG_STORE_TYPES.GET_CATALOG_STORE,
          payload: catalogStoreAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          catalogStoreData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_STORE_TYPES.LOAD_CATALOG_STORE
        );
      });
  }

  return {
    saveCatalogStore,
    createCatalogStore,
    updateCatalogStore,
    getCatalogStores,
    getCatalogStore,
    getAllCatalogStores,
    getMyCatalogStores,
  };
};
