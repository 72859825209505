import React from "react";
import ClientList from "../pages/Client/CilentList";

const ClientModal = (props) => {
  function selectClient(client) {
    document.getElementById("close-client-modal").click();
    props.selectClient(client);
  }

  var content = <ClientList modal={true} selectClient={selectClient} />;

  return (
    <>
      <button
        type="button"
        className={`btn ${
          props.selectedClient ? "btn-primary" : "btn-default bg-white"
        }`}
        data-toggle="modal"
        data-target="#clientModal"
      >
        {props.selectedClient ? props.selectedClient.name : "Choisir un client"}
      </button>
      <div
        className="modal fade"
        id="clientModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sélection du client
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mt-4">{content}</div>
            </div>
            <div className="modal-footer">
              <button
                id="close-client-modal"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientModal;
