import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { REPURCHASE_TYPES } from "../reducers/repurchaseReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { repurchaseService } from "../../services/repurchaseService";
import repurchaseAdapters from "../../adapters/repurchaseAdapters";

export const repurchaseActions = (state, dispatch, props) => {
  function getRepurchases(search = { search: null }) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    // search = {
    //   repurchase: {
    //     searchString: search.search,
    //   },
    //   page: search.page,
    // };

    search = tools.getSearchString({
      brand: search.brand,
      brandModel: search.brandModel,
      page: search.page,
    });

    repurchaseService
      .getRepurchases(search)
      .then((res) => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function getRepurchasesFromVehicle(brandUuid, brandModelUuid, fuelTypeId) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    repurchaseService
      .getRepurchasesFromVehicle(brandUuid, brandModelUuid, fuelTypeId)
      .then((res) => {
        if (res.data.length == 0) {
          tools.filterError(
            dispatch,
            { other: "Aucune valeur de rachat disponible pour ce véhicule" },
            REPURCHASE_TYPES.LOAD_REPURCHASE
          );
        } else {
          dispatch({
            type: REPURCHASE_TYPES.GET_REPURCHASES_VEHICLE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function getRepurchase(uuid) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    repurchaseService
      .getRepurchase(uuid)
      .then((res) => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: repurchaseAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function saveRepurchase(repurchaseData) {
    if (repurchaseData.uuid) {
      updateRepurchase(repurchaseData);
    } else {
      createRepurchase(repurchaseData);
    }
  }
  function createRepurchase(repurchaseData) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    repurchaseService
      .createRepurchase(repurchaseData)
      .then((res) => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: repurchaseAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          `${repurchaseData.name} a été correctement créé.`
        );
        props.history.push("/repurchases/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function addPeriodRepurchase(uuid, date) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    repurchaseService
      .addPeriodRepurchase(uuid, date)
      .then((res) => {
        window.location.href = "/repurchases/" + res.data.uuid;
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function updateRepurchase(repurchaseData) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    repurchaseService
      .updateRepurchase(repurchaseData)
      .then((res) => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: repurchaseAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          `${repurchaseData.name} a été correctement mis à jour.`
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }

  return {
    saveRepurchase,
    createRepurchase,
    updateRepurchase,
    getRepurchases,
    getRepurchase,
    addPeriodRepurchase,
    getRepurchasesFromVehicle,
  };
};
