import React, { useState } from "react";
import CatalogResearch from "../pages/Catalog/CatalogResearch";

const CatalogModal = (props) => {
  let [selectedVehicles, setSelectedVehicles] = useState([]);

  function selectCatalog(catalog) {
    var v = { ...catalog };

    document.getElementById("close-catalog-modal").click();

    props.selectVehicle(v);
  }

  function selectVehicles(catalog) {
    let v = { ...catalog };

    setSelectedVehicles([...selectedVehicles, v]);
  }

  function removeVehicle(catalog) {
    let v = { ...catalog };

    const indexToRemove = selectedVehicles.findIndex(
      (sv) => sv.uuid === v.uuid
    );
    if (indexToRemove !== -1) {
      const newSelectedVehicles = [...selectedVehicles];
      newSelectedVehicles.splice(indexToRemove, 1);
      setSelectedVehicles(newSelectedVehicles);
    }
  }

  var content = (
    <CatalogResearch
      modal={true}
      selectCatalog={selectCatalog}
      selectVehicles={selectVehicles}
      removeVehicle={removeVehicle}
      selectedVehicles={selectedVehicles}
    />
  );

  return (
    <>
      <div
        className="modal fade p-0"
        id="catalogModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sélection du véhicule
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mt-4">{content}</div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                id="close-catalog-modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogModal;
