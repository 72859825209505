const financeAdapters = {
  transformForAPI: (data) => {
    return { ...data, status: data.status.id };
  },
  transformForBO: (data) => {
    return {
      ...data,
      financier: data.financier?.uuid,
      financeType: data.financeType.id,
    };
  },
};

export default financeAdapters;
