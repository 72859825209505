import React, { useEffect } from "react";
import GroupSelector from "../../common/GroupSelector";
import { groupActions } from "../../../context/actions/groupActions";
import useStore from "../../../context/useStore";
import { atom, useAtom } from "jotai";
import RoleSelector from "../../common/RoleSelector";

export const userFiltersAtom = atom({
  groups: [],
  roles: [],
});

const UserSearch = () => {
  const [state, dispatch] = useStore();
  const [filters, setFilters] = useAtom(userFiltersAtom);
  const group_actions = groupActions(state, dispatch);

  useEffect(() => {
    group_actions.getAllGroups();
  }, []);

  const onChangeFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  return (
    <div className="col-12 py-2 my-2 bg-white border rounded">
      <div className="row">
        <div className="col-12 col-md-6">
          <GroupSelector
            label="Filtrer par groupe :"
            name="groups"
            value={filters.groups}
            change={onChangeFilters}
          />
        </div>
        <div className="col-12 col-md-6">
          <RoleSelector
            label="Filtrer par rôle :"
            name="roles"
            value={filters.roles}
            change={onChangeFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
