const groupAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return { ...data, catalogs: data.catalogs.map((catalog) => catalog.uuid) };
  },
};

export default groupAdapters;
