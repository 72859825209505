import axios from "axios";
import { api_url } from "../config/config";
import maintenanceAdapters from "../adapters/maintenanceAdapters";

export const maintenanceService = {
  getMaintenances: (search) => {
    return axios.get(`${api_url}maintenances/search${search}`);
  },
  getMaintenance: (uuid) => {
    return axios.get(`${api_url}maintenances/${uuid}`);
  },
  getMaintenancesFromVehicle: (brandUuid, brandModelUuid, fuelTypeId) => {
    const search = `?paginate=0&brand=${brandUuid}&brandModel=${brandModelUuid}&fuelType=${fuelTypeId}`;
    return axios.get(`${api_url}maintenances/search${search}`);
  },
  createMaintenance: (data) => {
    return axios.post(
      `${api_url}maintenances`,
      maintenanceAdapters.transformForAPI(data)
    );
  },
  updateMaintenance: (data) => {
    return axios.put(
      `${api_url}maintenances/${data.uuid}`,
      maintenanceAdapters.transformForAPI(data)
    );
  },
};
