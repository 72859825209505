import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { clientActions } from "../../../context/actions/clientActions";
import Loader from "../../common/Loader";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import Axios from "axios";
import { api_url } from "../../../config/config";
import NbSearchResults from "../../common/NbSearchResults";
import ClientSearch from "./ClientSearch";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import tools from "../../../helpers/tools";
import { RoleBadge } from "../Quotation/QuotationList";
import { useAtom } from "jotai";
import { clientSearchAtom } from "./useClientSearch";

const ClientList = (props) => {
  const [state, dispatch] = useStore();
  const [clientSearch, setClientSearch] = useAtom(clientSearchAtom);

  var actions = clientActions(state, dispatch);

  function removeClient(uuid) {
    if (
      window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")
    ) {
      Axios.delete(api_url + "clients/" + uuid)
        .then((res) => {
          actions.getClients();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            alert(err.response.data.message);
          }
        });
    }
  }

  useEffect(() => {
    actions.getClients(clientSearch);
  }, []);

  return (
    <CustomList getAction={actions.getClients}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Clients</h1>}
              {state.client.isLoading ? (
                <Loader />
              ) : props.modal ? null : (
                <Can I={userAccessEnum.CREER_CLIENT}>
                  <Link className="btn btn-primary" to="/clients/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un client
                  </Link>
                </Can>
              )}
              <ClientSearch handlePageParamsChange={actions.getClients} />
              <NbSearchResults total={state.client.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Groupe</th>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th>Ville</th>
                    <th>Rattaché à</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.client.items.map((client, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>
                        <div>{client.userGroup.name}</div>
                      </td>
                      <td>{client.name}</td>
                      <td>{client.email}</td>
                      <td>{client.phone}</td>
                      <td>{client.city}</td>
                      <td>
                        <small className="text-muted">
                          {tools.getFullname(client.user)}
                          <RoleBadge roleName={client.user.role?.name} />
                        </small>
                      </td>
                      <td>
                        {!props.modal ? (
                          <>
                            <Can I={userAccessEnum.MODIFIER_CLIENT}>
                              <Link
                                className="btn btn-secondary"
                                to={"/clients/" + client.uuid}
                              >
                                Modifier
                              </Link>
                            </Can>

                            <Can I={userAccessEnum.SUPPRIMER_CLIENT}>
                              <button
                                title="Supprimer ce client"
                                className="btn btn-danger ml-2  btn-sm"
                                onClick={() => removeClient(client.uuid)}
                              >
                                <i className="fa fa-times" />
                              </button>
                            </Can>
                          </>
                        ) : (
                          <>
                            <a
                              href="#"
                              className="btn btn-secondary"
                              onClick={() => props.selectClient(client)}
                            >
                              Choisir
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.client.total}
                activePage={clientSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getClients({ ...clientSearch, ...data });
                  setClientSearch({ ...clientSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default ClientList;
