import React from "react";
import useStore from "../../context/useStore";

const GroupSelector = ({ name, required, label, value, change }) => {
  const [state] = useStore();
  const groups = state.group.items;

  const onSelectItem = (group) => {
    var newValue = [];
    if (value.includes(group.uuid)) {
      newValue = value.filter((uuid) => uuid !== group.uuid);
    } else {
      newValue = [...value, group.uuid];
    }

    change({
      target: {
        name,
        value: newValue,
      },
    });
  };

  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span className="text-danger ml-1">*</span>}
      </label>
      {groups.map((group, k) => {
        const isSelected = value.includes(group.uuid);
        return (
          <button
            type="button"
            key={`group${group.uuid}`}
            className={`btn mr-1 btn-sm ${
              isSelected ? "btn-primary" : "btn-default"
            }`}
            onClick={() => onSelectItem(group)}
          >
            {group.name}
          </button>
        );
      })}
      <small className="form-text text-danger">{state.error[name]}</small>
    </div>
  );
};

export default GroupSelector;
