import tools from "../../helpers/tools";
import { BRAND_TYPES } from "../reducers/brandReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import brandAdapters from "../../adapters/brandAdapters";
import { brandService } from "../../services/brandService";

export const brandActions = (state, dispatch, props) => {
  function getAllBrands() {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandService
      .getAllBrands()
      .then((res) => {
        dispatch({
          type: BRAND_TYPES.GET_BRANDS,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND);
      });
  }
  function getBrands(search = { search: null }) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    brandService
      .getBrands(search)
      .then((res) => {
        dispatch({ type: BRAND_TYPES.GET_BRANDS, payload: res.data });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND);
      });
  }
  function getBrand(uuid) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandService
      .getBrand(uuid)
      .then((res) => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: brandAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND);
      });
  }
  function saveBrand(brandData) {
    if (brandData.uuid) {
      updateBrand(brandData);
    } else {
      createBrand(brandData);
    }
  }
  function createBrand(brandData) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandService
      .createBrand(brandData)
      .then((res) => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: brandAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          brandData.name + " a été correctement créé."
        );
        props.history.push("/brands/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND);
      });
  }
  function updateBrand(brandData) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandService
      .updateBrand(brandData)
      .then((res) => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: brandAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          brandData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND);
      });
  }

  return {
    saveBrand,
    createBrand,
    updateBrand,
    getBrands,
    getBrand,
    getAllBrands,
  };
};
