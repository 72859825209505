import axios from "axios";
import { api_url } from "../config/config";
import catalogAdapters from "../adapters/catalogAdapters";

export const catalogService = {
  getAllCatalogs: () => {
    return axios.get(`${api_url}catalogs-vehicles/search?paginate=0`);
  },
  getCatalogs: (search) => {
    return axios.get(`${api_url}catalogs-vehicles/search${search}`);
  },
  getCatalog: (uuid) => {
    return axios.get(`${api_url}catalogs-vehicles/${uuid}`);
  },
  createCatalog: (data) => {
    return axios.post(
      `${api_url}catalogs-vehicles`,
      catalogAdapters.transformForAPI(data)
    );
  },
  updateCatalog: (data) => {
    return axios.put(
      `${api_url}catalogs-vehicles/${data.uuid}`,
      catalogAdapters.transformForAPI(data)
    );
  },
};
