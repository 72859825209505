const maintenanceAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return {
      ...data,
      brand: data.brand.uuid,
      brandModels: data.brandModels.map((b) => b.uuid),
      fuelTypes: data.fuelTypes.map((f) => f.id),
      catalog: data.catalog.uuid,
    };
  },
};

export default maintenanceAdapters;
