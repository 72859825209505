import axios from "axios";
import { api_url } from "../config/config";
import clientAdapters from "../adapters/clientAdapters";

export const clientService = {
  getAllClients: () => {
    return axios.get(`${api_url}clients/search?paginate=0`);
  },
  getClients: (search) => {
    return axios.get(`${api_url}clients/search${search}`);
  },
  getClient: (uuid) => {
    return axios.get(`${api_url}clients/${uuid}`);
  },
  createClient: (data) => {
    return axios.post(
      `${api_url}clients`,
      clientAdapters.transformForAPI(data)
    );
  },
  updateClient: (data) => {
    return axios.put(
      `${api_url}clients/${data.uuid}`,
      clientAdapters.transformForAPI(data)
    );
  },
  reassignClient: (clientUuid, userUuid) => {
    return axios.patch(`${api_url}clients/${clientUuid}/reassign`, {
      user: userUuid,
    });
  },
};
