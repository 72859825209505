import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import BrandLogo from "./BrandLogo";

const BrandList = (props) => {
  const [state, dispatch] = useStore();
  var actions = brandActions(state, dispatch);

  return (
    <CustomList getAction={actions.getBrands}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Marques</h1>
              {state.brand.isLoading ? (
                <Loader />
              ) : (
                <Can I={userAccessEnum.CREER_MARQUE_VEHICULE}>
                  <Link className="btn btn-primary" to="/brands/create">
                    <i className="fa fa-plus mr-2" /> Ajouter une marque
                  </Link>
                </Can>
              )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />
              <NbSearchResults total={state.brand.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.brand.items.map((brand, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>
                        <BrandLogo brandName={brand.name} />
                        {brand.name}
                      </td>
                      <td>
                        <Can I={userAccessEnum.MODIFIER_MARQUE_VEHICULE}>
                          <Link
                            className="btn btn-secondary"
                            to={"/brands/" + brand.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <CustomPagination
                total={state.brand.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default BrandList;
