import useAccessSettings from "../../../hooks/useInit/useAccessSettings";

const Can = ({ I = "", children }) => {
  const { accessActions } = useAccessSettings();

  const { granted } = accessActions.canI({ action: I });

  return granted ? children : null;
};

export default Can;
