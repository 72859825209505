import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { userActions } from "../../../context/actions/userActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import UserSearch, { userFiltersAtom } from "./UserSearch";
import { useAtom } from "jotai";
import tools from "../../../helpers/tools";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";

const UserList = (props) => {
  const [state, dispatch] = useStore();
  const actions = userActions(state, dispatch, props);
  const [userFilters] = useAtom(userFiltersAtom);

  var userData = state.user.items;

  useEffect(() => {
    actions.getUsers();
  }, []);

  const filteredUsers = userData.filter((user) => {
    let shouldInclude = true;
    if (userFilters.groups.length > 0) {
      shouldInclude = userFilters.groups.includes(user.userGroup.uuid);
    }
    if (userFilters.roles.length > 0) {
      shouldInclude = userFilters.roles.includes(user.role.id);
    }
    return shouldInclude;
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {!props.modal && <h1>Utilisateurs</h1>}
          {state.user.isLoading ? (
            <Loader />
          ) : (
            <Can I={userAccessEnum.CREER_UTILISATEUR}>
              <Link className="btn btn-primary" to="/users/create">
                <i className="fa fa-plus mr-2" />
                Créer un utilisateur
              </Link>
            </Can>
          )}
          <UserSearch />
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Groupe</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, k) => (
                <tr key={"user-" + k}>
                  <td>{user.userGroup.name}</td>
                  <td>{tools.getFullname(user)}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.role.name}</td>
                  <td>
                    {!props.modal ? (
                      <>
                        <Can I={userAccessEnum.MODIFIER_UTILISATEUR}>
                          <Link
                            className="btn btn-secondary mr-2"
                            to={"/users/" + user.uuid}
                          >
                            Modifier
                          </Link>
                        </Can>
                        {
                          <Can I={userAccessEnum.SUPPRIMER_UTILISATEUR}>
                            <button
                              className="btn btn-danger"
                              onClick={() => actions.deleteUser(user.uuid)}
                            >
                              Supprimer
                            </button>
                          </Can>
                        }
                      </>
                    ) : (
                      <a
                        href="#"
                        className="btn btn-secondary"
                        onClick={() => props.selectUser(user)}
                      >
                        Choisir
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
