import axios from "axios";
import { api_url } from "../config/config";

export const tireService = {
  getTires: () => {
    return axios.get(`${api_url}tires/search?paginate=0`);
  },
  getTire: (uuid) => {
    return axios.get(`${api_url}tires/${uuid}`);
  },
  createTire: (data) => {
    return axios.post(`${api_url}tires`, data);
  },
  updateTire: (data) => {
    return axios.put(`${api_url}tires/${data.uuid}`, data);
  },
  deleteTire: (uuid) => {
    return axios.delete(`${api_url}tires/${uuid}`);
  },
};
