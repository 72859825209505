import tools from "../../helpers/tools";
import { CONTRACT_TYPES } from "../reducers/contractReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { contractService } from "../../services/contractService";
import contractAdapters from "../../adapters/contractAdapters";

export const contractActions = (state, dispatch, props) => {
  function getContracts(search = { search: null }) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    contractService
      .getContracts(search)
      .then((res) => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          tools.filterError(
            dispatch,
            err.response.data,
            CONTRACT_TYPES.LOAD_CONTRACT
          );
        }
      });
  }
  function getContract(uuid) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    contractService
      .getContract(uuid)
      .then((res) => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: contractAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }
  function createContract(contractData) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    contractService
      .createContract(contractData)
      .then((res) => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: contractAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          contractData.name + " a été correctement créé."
        );
        props.history.push("/contracts/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }
  function updateContract(contractData) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    contractService
      .updateContract(contractData)
      .then((res) => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: contractAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          contractData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }

  return {
    createContract,
    updateContract,
    getContracts,
    getContract,
  };
};
