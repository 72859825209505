import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import Loader from "../../common/Loader";
import { BRAND_TYPES } from "../../../context/reducers/brandReducers";
import tools from "../../../helpers/tools";

const BrandDetail = (props) => {
  const [state, dispatch] = useStore();

  var actions = brandActions(state, dispatch, props);

  useEffect(() => {
    if (props.match.params.uuid != "create") {
      actions.getBrand(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, BRAND_TYPES.UPDATE_BRAND_DATA, dispatch);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'une marque</h1>
          <div className="form-group">
            <label htmlFor="name">Marque</label>

            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              onChange={inputChange}
              value={state.brand.item.name}
            />
            <small className="form-text text-danger">{state.error.name}</small>
          </div>
          {state.brand.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.saveBrand(state.brand.item)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BrandDetail);
