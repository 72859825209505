import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { contractActions } from "../../../context/actions/contractActions";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";
import { CSVLink, CSVDownload } from "react-csv";
import vehicleTools from "../../../helpers/vehicleTools";
import QuotationSearch from "../Quotation/QuotationSearch/QuotationSearch";
import StatusBadge from "../../common/StatusBadge/StatusBadge";
import useTVA from "../../../hooks/useInit/useTVA";
import ContractSearch from "./ContractSearch";
import { useAtom } from "jotai";
import { contractSearchAtom } from "./useContractSearch";

const ContractList = (props) => {
  const [state, dispatch] = useStore();

  var contractData = state.contract.items;
  var constantData = state.constant.items;
  var actions = contractActions(state, dispatch, props);
  const [contractSearch, setContractSearch] = useAtom(contractSearchAtom);

  const { getTVAByCatalog } = useTVA();

  useEffect(() => {
    actions.getContracts(contractSearch);
  }, []);

  var csvData = [
    [
      "Référence",
      "Immatriculation",
      "Marque",
      "Modèle",
      "Finition",
      "Prix public HT (hors CG/BM)",
      "Remise sur prix TTC",
      "Prix net TTC",
      "Montant invest HT",
      "Marge TTC",
      "Loyer de base HT",
      "Véhicule relais",
      "Assurance Prévoyance",
      "Option Sérénité",
      "Autres prestations",
      "Total loyer HT",
      "Total loyer TTC",
    ],
  ];
  if (contractData) {
    contractData.forEach((c) => {
      const { TVA, TVA_INVERT } = getTVAByCatalog(c.catalog.uuid);
      var basePrices = vehicleTools.calcVehiclePrices(
        { ...c.vehicle, type: c.vehicle.type.id },
        TVA,
        TVA_INVERT
      );
      var totalBenefits = 0;
      c.benefits.forEach((b) => {
        totalBenefits += parseFloat(b.priceHT);
      });
      var financeRentCalc = vehicleTools.calcFinanceRent(c, TVA_INVERT);
      var baseRentHT =
        Math.round(
          (financeRentCalc +
            parseFloat(c.maintenanceValueFinal) +
            parseFloat(c.marginTTC)) *
            100
        ) / 100;
      csvData.push([
        c.reference,
        c.vehicle.immat,
        c.vehicle.brand.name,
        c.vehicle.brandModel.name,
        c.vehicle.brandModelFinish?.name,
        basePrices.priceTTC,
        c.vehicle.discountPct + "% / " + c.vehicle.discount + "€",
        Math.round(basePrices.priceTotalTTC * 100) / 100,
        c.vehicle.investAmtHT,
        c.marginTTC,
        baseRentHT,

        c.replacementVehicleCost,
        c.insuranceHT,
        c.insuranceFinLoss,
        totalBenefits,
        c.totalRentHT,
        Math.round(c.totalRentTTC * 100) / 100,
      ]);
    });
  }

  return (
    <CustomList getAction={actions.getContracts}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Contrats</h1>
              {!state.quotation.isLoading && (
                <CSVLink
                  data={csvData}
                  separator={";"}
                  enclosingCharacter={`"`}
                  filename={"export_contrats_match.csv"}
                >
                  Télécharger en CSV
                </CSVLink>
              )}
              <ContractSearch handlePageParamsChange={actions.getContracts} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Statut</th>
                    <th>Véhicule</th>
                    <th>Client</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {state.quotation.isLoading ? (
                    <Loader />
                  ) : (
                    contractData.map((contract, k) => {
                      return (
                        <tr key={"brand-row-" + k}>
                          <td>{contract.reference}</td>
                          <td>
                            <StatusBadge
                              statusId={contract.status.id}
                              statusName={`${
                                state.constant.items.QUOTATION_STATUS.find(
                                  (d) => d.id == contract.status.id
                                ).name
                              }`}
                            />
                          </td>
                          <td>
                            {contract.vehicle && contract.vehicle.brand ? (
                              contract.vehicle.brand.name +
                              " - " +
                              contract.vehicle.brandModel.name +
                              (contract.vehicle.immat &&
                                " (" + contract.vehicle.immat + " )")
                            ) : (
                              <span className="text-danger">
                                Erreur, aucune marque pour le véhicule
                              </span>
                            )}
                          </td>
                          <td>{contract.client && contract.client.name}</td>
                          <td>
                            {DateTime.fromJSDate(
                              new Date(contract.dBegin)
                            ).toFormat("dd/MM/yyyy")}
                          </td>
                          <td>
                            {DateTime.fromJSDate(
                              new Date(contract.dEnd)
                            ).toFormat("dd/MM/yyyy")}
                          </td>
                          {/* <td>
                              <Link
                                className="btn btn-secondary mr-2"
                                to={"/contracts/" + contract.id}
                              >
                                Modifier
                            </Link>
                            </td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>

              <CustomPagination
                total={state.contract.total}
                activePage={contractSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getContracts({ ...contractSearch, ...data });
                  setContractSearch({ ...contractSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default ContractList;
