import axios from "axios";
import { api_url } from "../config/config";
import catalogStoreAdapters from "../adapters/catalogStoreAdapters";

export const catalogStoreService = {
  getMyCatalogStores: () => {
    return axios.get(`${api_url}catalogs/search?paginate=0`);
  },
  getAllCatalogStores: () => {
    return axios.get(`${api_url}catalogs/search?paginate=0`);
  },
  getCatalogStores: (search) => {
    return axios.get(`${api_url}catalogs/search${search}`);
  },
  getCatalogStore: (uuid) => {
    return axios.get(`${api_url}catalogs/${uuid}`);
  },
  createCatalogStore: (data) => {
    return axios.post(
      `${api_url}catalogs`,
      catalogStoreAdapters.transformForAPI(data)
    );
  },
  updateCatalogStore: (data) => {
    return axios.put(
      `${api_url}catalogs/${data.uuid}`,
      catalogStoreAdapters.transformForAPI(data)
    );
  },
};
