import { useEffect } from "react";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { maintenanceActions } from "../../../context/actions/maintenanceActions";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import { MAINTENANCE_TYPES } from "../../../context/reducers/maintenanceReducers";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import useTVA from "../../../hooks/useInit/useTVA";

const useMaintenanceDetail = (props) => {
  const [state, dispatch] = useStore();
  var maintenanceData = state.maintenance.item;
  var brandModelData = state.brandModel.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var r_actions = maintenanceActions(state, dispatch, props);

  const { getTVAByCatalog } = useTVA();
  let { TVA, TVA_INVERT } = maintenanceData.catalog
    ? getTVAByCatalog(maintenanceData.catalog)
    : { TVA: -1, TVA_INVERT: -1 };

  useEffect(() => {
    b_actions.getAllBrands();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
        meta: { total: 0 },
      },
    });
    if (props.match.params.uuid != "create") {
      r_actions.getMaintenance(props.match.params.uuid);
    }
  }, []);

  useEffect(() => {
    if (maintenanceData?.brand) {
      bm_actions.getBrandModelByBrand(maintenanceData.brand);
    }
  }, [maintenanceData?.brand]);

  // if (
  //   brandModelData.length == 0 &&
  //   !state.brandModel.isLoading &&
  //   maintenanceData.brand
  // ) {
  //   bm_actions.getBrandModelByBrand(maintenanceData.brand);
  // }

  const maintenanceDetailActions = {
    inputChange: (e) => {
      if (e.target.name == "brand") {
        dispatch({
          type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
          payload: {
            key: "brandModels",
            value: [],
          },
        });
        bm_actions.getBrandModelByBrand(e.target.value);
      }
      tools.inputChange(e, MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA, dispatch);
    },
    toggleBrandModels: (e, id) => {
      var { brandModels } = maintenanceData;
      if (e.target.checked) {
        brandModels.push(id);
      } else {
        var index = brandModels.indexOf(id);
        brandModels.splice(index, 1);
      }
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "brandModels",
          value: brandModels,
        },
      });
    },
    toggleFuelTypes: (e, id) => {
      var { fuelTypes } = maintenanceData;
      if (e.target.checked) {
        fuelTypes.push(id);
      } else {
        var index = fuelTypes.indexOf(id);
        fuelTypes.splice(index, 1);
      }
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "fuelTypes",
          value: fuelTypes,
        },
      });
    },
    updateTable: (excelData) => {
      excelData.dur = excelData.dur.map((d) => parseFloat(d.replace(" ", "")));
      excelData.km = excelData.km.map((d) => parseFloat(d.replace(" ", "")));

      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "tableValues",
          value: excelData,
        },
      });
    },
    tableValueChange: (e, col, row) => {
      var { tableValues } = maintenanceData;
      tableValues.values[col][row] = e.target.value;
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    addColumn: (value) => {
      var { tableValues } = maintenanceData;
      tableValues.km.push(value);
      maintenanceDetailActions.pushTableValues(tableValues);
    },
    addRow: (value) => {
      var { tableValues } = maintenanceData;
      tableValues.dur.push(value);
      maintenanceDetailActions.pushTableValues(tableValues);
    },
    pushTableValues: (tableValues) => {
      !tableValues.values && (tableValues.values = {});
      tableValues.dur.forEach((dur) => {
        !tableValues.values[dur] && (tableValues.values[dur] = []);
        tableValues.km.forEach((km, k) => {
          !tableValues.values[dur][k] && (tableValues.values[dur][k] = "");
        });
      });
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    deleteRow: (key) => {
      var { tableValues } = maintenanceData;
      delete tableValues.values[key];
      tableValues.dur = tableValues.dur.filter((d) => d !== key);
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    deleteCol: (key) => {
      var { tableValues } = maintenanceData;

      for (var k in tableValues.values) {
        tableValues.values[k] = tableValues.values[k].filter(
          (entry, k) => k !== key
        );
      }
      tableValues.km = tableValues.km.filter((d, k) => k !== key);
      dispatch({
        type: MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA,
        payload: {
          key: "tableValues",
          value: tableValues,
        },
      });
    },
    formatExcelData: (excelData, orientation, isTTC) => {
      var table = {
        dur: [],
        km: [],
        values: {},
      };
      var ex = excelData.split("\n");
      var row = "km";
      var col = "dur";
      if (orientation == 2) {
        row = "dur";
        col = "km";
      }
      table[row] = ex[0].split("	");

      table[row].shift();

      for (var key in ex) {
        if (key == 0) continue;
        var colvals = ex[key].split("	");

        table[col].push(colvals[0]);

        table.values[colvals[0]] = colvals.slice(1);
      }

      if (orientation == 2) {
        var newTableValues = {};
        table[row].forEach((c, k) => {
          newTableValues[k] = [];
        });

        for (var key in table.values) {
          table.values[key].forEach((tv, keyy) => {
            newTableValues[keyy].push(tv);
          });
        }
        table.values = {};
        table[row].forEach((c, k) => {
          var altc = parseFloat(c);
          table.values[altc] = newTableValues[k];
        });
      }
      if (isTTC) {
        for (var key in table.values) {
          table.values[key] = table.values[key].map(
            (tb) => Math.round(parseFloat(tb / TVA_INVERT) * 100) / 100
          );
        }
      }
      maintenanceDetailActions.updateTable(table);
    },
  };

  return {
    maintenanceDetailActions,
    maintenanceData,
    brandModelData,
    brandData,
    constantData,
    r_actions,
  };
};

export default useMaintenanceDetail;
