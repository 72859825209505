import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import Loader from "../../common/Loader";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import tools from "../../../helpers/tools";
import BrandModelFinishes from "../../common/BrandModelFinishes/BrandModelFinishes";
import Control from "../../common/Control";

const BrandModelDetail = (props) => {
  const [state, dispatch] = useStore();

  var brandModelData = state.brandModel.item;
  var brandData = state.brand.items;

  // var constantData = state.constant.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  const brandModelId = props.match.params.uuid;
  const isCreation = brandModelId === "create";

  useEffect(() => {
    b_actions.getAllBrands();
    if (!isCreation) {
      bm_actions.getBrandModel(props.match.params.uuid);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA, dispatch);
  }

  // function addFinish() {
  //   var finishes = brandModelData.finishes;
  //   finishes.push({
  //     name: "",
  //     fuelType: "",
  //   });
  //   dispatch({
  //     type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
  //     payload: { key: "finishes", value: finishes },
  //   });
  // }

  // function changeFinish(e, k) {
  //   var finishes = brandModelData.finishes;
  //   finishes[k][e.target.name] = e.target.value;
  //   dispatch({
  //     type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
  //     payload: { key: "finishes", value: finishes },
  //   });
  // }

  // function removeFinish(k) {
  //   var finishes = brandModelData.finishes;
  //   finishes.splice(k, 1);
  //   dispatch({
  //     type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
  //     payload: { key: "finishes", value: finishes },
  //   });
  // }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un modèle</h1>

          <Control
            label="Marque"
            name="brand"
            id="brand"
            type="select"
            datas={state.brand.items}
            dataIndex="uuid"
            dataLabel="name"
            change={inputChange}
            value={brandModelData.brand}
            error={state.error}
          />

          <Control
            label="Modèle"
            name="name"
            id="name"
            change={inputChange}
            value={brandModelData.name}
            error={state.error}
          />

          {state.brandModel.isLoading ? (
            <Loader />
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => bm_actions.saveBrandModel(brandModelData)}
            >
              Enregistrer
            </button>
          )}

          {!isCreation && <BrandModelFinishes brandModelUuid={brandModelId} />}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BrandModelDetail);
