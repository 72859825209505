exports.metas = [
  {
    pathname: "vehicles",
    title: "Flotte",
    child: "Édition d'un véhicule de flotte",
  },
  {
    pathname: "brands",
    title: "Marques",
    child: "Édition d'une marque",
  },
  {
    pathname: "brandmodels",
    title: "Modèles",
    child: "Édition d'un modèle",
  },
  {
    pathname: "finishes",
    title: "Finitions",
    child: "Édition d'une finition",
  },
  {
    pathname: "repurchases",
    title: "Valeurs de rachat",
    child: "Édition d'une valeur de rachat",
  },
  {
    pathname: "users",
    title: "Utilisateurs",
    child: "Édition d'un utilisateur",
  },
  {
    pathname: "constants",
    title: "Édition des constantes dynamiques",
    child: "",
  },
  {
    pathname: "clients",
    title: "Clients",
    child: "Édition d'un client",
  },
  {
    pathname: "quotations",
    title: "Devis",
    child: "Édition d'un devis",
  },
  {
    pathname: "contracts",
    title: "Contrats",
    child: "Édition d'un contrat",
  },
  {
    pathname: "maintenances",
    title: "Entretiens",
    child: "Édition d'un tableau d'entretien",
  },
  {
    pathname: "finances",
    title: "Financements",
    child: "Édition d'un financement",
  },
  {
    pathname: "catalogs",
    title: "Véhicules de catalogue",
    child: "Édition d'un véhicule catalogue",
  },
  {
    pathname: "tires",
    title: "Pneus",
    child: "Liste des pneus",
  },
  {
    pathname: "financiers",
    title: "Financeurs",
    child: "Édition d'un financeur",
  },
  {
    pathname: "catalogstores",
    title: "Catalogues",
    child: "Édition d'un catalogue",
  },
  {
    pathname: "groups",
    title: "Groupes",
    child: "Édition d'un groupe",
  },
];
