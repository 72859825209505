import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { vehicleActions } from "../../../context/actions/vehicleActions";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import Loader from "../../common/Loader";
import { brandActions } from "../../../context/actions/brandActions";
import VehicleSearch from "./VehicleSearch";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";
import { useAtom } from "jotai";
import { vehicleSearchAtom } from "./useVehicleSearch";

const VehicleList = (props) => {
  const [state, dispatch] = useStore();
  const [vehicleSearch, setVehicleSearch] = useAtom(vehicleSearchAtom);

  var actions = vehicleActions(state, dispatch);
  var b_actions = brandActions(state, dispatch);

  useEffect(() => {
    b_actions.getAllBrands();
    actions.getVehicles(vehicleSearch);
  }, []);

  return (
    <CustomList getAction={actions.getVehicles}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Flotte</h1>}
              {state.vehicle.isLoading && <Loader />}
              <VehicleSearch handlePageParamsChange={actions.getVehicles} />
              <NbSearchResults total={state.vehicle.total} />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Affectation</th>
                    <th>Immatriculation</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Finition</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.vehicle.items.map((vehicle, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{vehicle.status?.name}</td>
                      <td>{vehicle.immat}</td>
                      <td>
                        {vehicle.brand ? (
                          vehicle.brand.name
                        ) : (
                          <span className="text-danger">
                            Aucune marque pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>
                        {vehicle.brandModel ? (
                          vehicle.brandModel.name
                        ) : (
                          <span className="text-danger">
                            Aucun modèle pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>
                        {vehicle.brandModelFinish?.name || (
                          <span className="text-danger">
                            Aucune finition pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>{vehicle.type?.name}</td>
                      <td>
                        {!props.modal ? (
                          <Can I={userAccessEnum.MODIFIER_FLOTTE_VEHICULE}>
                            <Link
                              className="btn btn-secondary"
                              to={"/vehicles/" + vehicle.uuid}
                            >
                              Modifier
                            </Link>
                          </Can>
                        ) : (
                          <a
                            href="#"
                            className="btn btn-secondary"
                            onClick={() => props.selectVehicle(vehicle)}
                          >
                            Choisir
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.vehicle.total}
                activePage={vehicleSearch.page || "1"}
                handlePageParamsChange={(data) => {
                  actions.getVehicles({ ...vehicleSearch, ...data });
                  setVehicleSearch({ ...vehicleSearch, ...data });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default VehicleList;
