import React from "react";
import useStore from "../../context/useStore";

const RoleSelector = ({ name, required, label, value, change }) => {
  const [state] = useStore();
  const { ROLES } = state.constant.items;

  const onSelectItem = (role) => {
    var newValue = [];
    if (value.includes(role.id)) {
      newValue = value.filter((id) => id !== role.id);
    } else {
      newValue = [...value, role.id];
    }

    change({
      target: {
        name,
        value: newValue,
      },
    });
  };

  return (
    <div className="form-role">
      <label>
        {label}
        {required && <span className="text-danger ml-1">*</span>}
      </label>
      {ROLES.map((role, k) => {
        const isSelected = value.includes(role.id);
        return (
          <button
            type="button"
            key={`role${role.id}`}
            className={`btn mr-1 btn-sm ${
              isSelected ? "btn-primary" : "btn-default"
            }`}
            onClick={() => onSelectItem(role)}
          >
            {role.name}
          </button>
        );
      })}
      <small className="form-text text-danger">{state.error[name]}</small>
    </div>
  );
};

export default RoleSelector;
