import React, { useEffect } from "react";
import useStore from "../context/useStore";
import Loader from "../components/common/Loader";
import { constantActions } from "../context/actions/constantActions";

const ConstantMiddleware = ({ children }) => {
  const [state, dispatch] = useStore();
  var constant_actions = constantActions(state, dispatch);
  const constantsLoaded = state.constant?.items;
  const shouldLoadConstants = state.auth.isAuthenticated && !constantsLoaded;
  useEffect(() => {
    if (shouldLoadConstants) constant_actions.getConstants();
  }, []);

  if (!shouldLoadConstants) return children;

  if (!constantsLoaded) {
    return <Loader text="Chargement des constantes" />;
  }
};

export default ConstantMiddleware;
