import React from "react";
import tools from "../../../helpers/tools";
const StatusBadge = ({ statusId, statusName }) => {
  return (
    <span
      className={"mr-2 badge badge-" + tools.getQuotationStatusColor(statusId)}
    >
      {statusName}
    </span>
  );
};

export default StatusBadge;
