import React from "react";

const NbSearchResults = ({ total }) => {
  return (
    <div className="col-12 d-flex justify-content-end">
      {total} résultat(s) trouvé(s)
    </div>
  );
};

export default NbSearchResults;
