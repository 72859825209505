import axios from "axios";
import { api_url } from "../config/config";
import financeAdapters from "../adapters/financeAdapters";

export const financeService = {
  getAllFinances: () => {
    return axios.get(`${api_url}finances/search?paginate=0`);
  },
  getFinances: (search) => {
    return axios.get(`${api_url}finances/search${search}`);
  },
  getFinance: (uuid) => {
    return axios.get(`${api_url}finances/${uuid}`);
  },
  createFinance: (data) => {
    return axios.post(
      `${api_url}finances`,
      financeAdapters.transformForAPI(data)
    );
  },
  updateFinance: (data) => {
    return axios.put(
      `${api_url}finances/${data.uuid}`,
      financeAdapters.transformForAPI(data)
    );
  },
};
