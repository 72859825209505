import tools from "../../helpers/tools";
import { GROUP_TYPES } from "../reducers/groupReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import groupAdapters from "../../adapters/groupAdapters";
import { groupService } from "../../services/groupService";

export const groupActions = (state, dispatch, props) => {
  function getAllGroups() {
    dispatch({ type: GROUP_TYPES.LOAD_GROUP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    groupService
      .getAllGroups()
      .then((res) => {
        dispatch({
          type: GROUP_TYPES.GET_GROUPS,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, GROUP_TYPES.LOAD_GROUP);
      });
  }
  function getGroups(search = { search: null }) {
    dispatch({ type: GROUP_TYPES.LOAD_GROUP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      page: search.page,
    });

    groupService
      .getGroups(search)
      .then((res) => {
        dispatch({ type: GROUP_TYPES.GET_GROUPS, payload: res.data });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, GROUP_TYPES.LOAD_GROUP);
      });
  }
  function getGroup(uuid) {
    dispatch({ type: GROUP_TYPES.LOAD_GROUP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    groupService
      .getGroup(uuid)
      .then((res) => {
        dispatch({
          type: GROUP_TYPES.GET_GROUP,
          payload: groupAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, GROUP_TYPES.LOAD_GROUP);
      });
  }
  function saveGroup(groupData) {
    if (groupData.uuid) {
      updateGroup(groupData);
    } else {
      createGroup(groupData);
    }
  }
  function createGroup(groupData) {
    dispatch({ type: GROUP_TYPES.LOAD_GROUP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    groupService
      .createGroup(groupData)
      .then((res) => {
        dispatch({
          type: GROUP_TYPES.GET_GROUP,
          payload: groupAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          groupData.name + " a été correctement créé."
        );
        props.history.push("/groups/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, GROUP_TYPES.LOAD_GROUP);
      });
  }
  function updateGroup(groupData) {
    dispatch({ type: GROUP_TYPES.LOAD_GROUP, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    groupService
      .updateGroup(groupData)
      .then((res) => {
        dispatch({
          type: GROUP_TYPES.GET_GROUP,
          payload: groupAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          groupData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(dispatch, err.response.data, GROUP_TYPES.LOAD_GROUP);
      });
  }

  return {
    saveGroup,
    createGroup,
    updateGroup,
    getGroups,
    getGroup,
    getAllGroups,
  };
};
