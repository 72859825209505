import { useEffect, useState } from "react";
import queryString from "query-string";

const useCustomList = (props) => {
  const { getAction } = props;
  const [activePage, setActivePage] = useState(
    parseInt(queryString.parse(props.location.search).page) || 1
  );
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);

  // useEffect(() => {
  //   if (!props.noFirstLoad) {
  //     handlePageParamsChange(true);
  //   }
  // }, []);

  function handlePageParamsChange(params, isFirstLoad = false) {
    var searchObj = queryString.parse(props.location.search);

    let pageNumber = params.page;
    let newSearch = params.search;

    if (!params.page) pageNumber = searchObj.page;

    if (newSearch || newSearch === "") {
      searchObj.search = newSearch;

      pageNumber = 1;
    } else {
      if (!searchObj.search) {
        delete searchObj.search;

        newSearch = "";
      } else {
        newSearch = searchObj.search;
      }
    }

    searchObj.page = pageNumber;

    const query = "?" + queryString.stringify(searchObj);
    props.history.push(query);
    setActivePage(pageNumber);
    setSearch(newSearch);

    getAction(searchObj);
  }
  return { activePage, handlePageParamsChange, search };
};

export default useCustomList;
