import { DateTime } from "luxon";
import vehicleTools from "./vehicleTools";
import { toast } from "react-toastify";

var financeTools = {
  dateEndFromStart: (start, duration) => {
    try {
      return DateTime.fromJSDate(new Date(start))
        .plus({ months: duration })
        .toFormat("yyyy-MM-dd");
    } catch (err) {
      return "";
    }
  },
  calcVRAtPeriod(q, k) {
    var totalPeriods = 0;
    for (var key in q.periods) {
      if (key > k) continue;
      totalPeriods +=
        parseInt(q.periods[key].duration) > 0
          ? parseInt(q.periods[key].duration)
          : 0;
    }
    var p = q.periods[k];
    if (!q.repurchase_data?.find((r) => r.uuid === q.repurchase)) {
      toast.error(
        "Erreur : aucun tableau de VR pour cette entrée. La VR ne pourra être affichée que pour la loi de roulage pré-entregistrée (" +
          q.dur +
          " mois)"
      );
      return q.repurchaseValueFinal;
    }
    var rateVR = vehicleTools.getRateVR(
      totalPeriods,
      q.km,
      q.repurchase_data.find((r) => r.uuid === q.repurchase).tableValues
    ).value;

    return (
      Math.round(
        ((q.vehicle.pricePublicTTC * parseFloat(rateVR)) / 100) * 100
      ) / 100
    );
  },
  calcFinanceRent(q, TVA_INVERT) {
    var AA4 = parseFloat(q.vehicle.investAmtHT);
    var AE4 = parseFloat(q.repurchaseValueFinal);
    var AI4 = parseFloat(q.firstRentInput);
    var AH4 = parseFloat(q.financeRate) / 100;
    var M4 = parseFloat(q.dur);
    var AJ4 = parseFloat(q.applicationFee);

    var res =
      ((AA4 - AE4 / TVA_INVERT - AI4 / TVA_INVERT) * (AH4 / 12)) /
        (1 - 1 / Math.pow(1 + AH4 / 12, M4)) +
      ((AE4 / TVA_INVERT) * AH4) / 12 +
      AJ4 / M4;

    if (isNaN(res)) {
      return 0;
    } else {
      return Math.round(res * 100) / 100;
    }
  },
  getQuotationObj(id, qd) {
    var quotationObj = null;
    qd.forEach((qs) => {
      if (qs.quotations.find((qsq) => qsq.uuid == id)) {
        quotationObj = JSON.parse(
          JSON.stringify(qs.quotations.find((qsq) => qsq.uuid == id))
        );
      }
    });
    return quotationObj;
  },
  calcEnvelop(fq, qd) {
    var totalEnvelop = 0;
    fq.forEach((q) => {
      var quotationObj = financeTools.getQuotationObj(q.quotation, qd);
      if (!quotationObj) {
        toast.error("Un devis n'a pas été trouvé !");
        return 0;
      } else {
        totalEnvelop += parseFloat(quotationObj.vehicle.investAmtHT);
      }
    });
    return Math.round(totalEnvelop * 100) / 100;
  },
  recalcPeriods(fq, qd) {
    fq.forEach((q) => {
      var quotationObj = null;
      qd.forEach((qs) => {
        if (qs.quotations.find((qsq) => qsq.uuid == q.quotation)) {
          quotationObj = {
            ...JSON.parse(
              JSON.stringify(
                qs.quotations.find((qsq) => qsq.uuid == q.quotation)
              )
            ),
            periods: q.periods,
          };
        }
      });

      q.periods.forEach((p, k) => {
        //pour dernière période (avec la valeur résiduelle)

        if (k > 0) {
          var vr = financeTools.calcVRAtPeriod(quotationObj, k - 1);

          quotationObj.vehicle.investAmtHT = vr;
          quotationObj.repurchaseValueFinal =
            k == q.periods.length - 1 ? p.residualValue : 0;
          var mens = financeTools.calcFinanceRent(quotationObj, 1.085);
          p.monthPayment = mens;
        }

        //seulement si on a 3 périodes
        if (k < q.periods.length - 1 && k > 0) {
        }
      });
    });

    return fq;
  },
};

export default financeTools;
