import { DateTime } from "luxon";

const clientAdapters = {
  transformForAPI: (data) => {
    const { address, address2, address3, city, postalCode } = data;
    return {
      ...data,
      address: {
        address,
        address2,
        address3,
        city,
        postalCode,
      },
      eulerHermesDate: DateTime.fromISO(data.eulerHermesDate).toFormat(
        "yyyy-MM-dd"
      ),
    };
  },
  transformForBO: (data) => {
    return {
      ...data,
      address: data.address.address,
      address2: data.address.address2,
      address3: data.address.address3,
      city: data.address.city,
      postalCode: data.address.postalCode,
      legalForm: data.legalForm?.id,
    };
  },
};

export default clientAdapters;
