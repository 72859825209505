import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { FINISH_TYPES } from "../reducers/finishReducers";
import finishAdapters from "../../adapters/finishAdapters";
import { finishService } from "../../services/finishService";

export const finishActions = (state, dispatch, props) => {
  function getFinishes(search = { search: null }) {
    dispatch({ type: FINISH_TYPES.LOAD_FINISH, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.search,
      brandModel: search.brandModel,
      page: search.page,
      catalogs: search.catalogs,
    });

    finishService
      .getFinishes(search)
      .then((res) => {
        dispatch({
          type: FINISH_TYPES.GET_FINISHES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINISH_TYPES.LOAD_FINISH
        );
      });
  }
  function getFinishesByBrandModel(brandModelUuid, catalogs = []) {
    dispatch({ type: FINISH_TYPES.LOAD_FINISH, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    finishService
      .getFinishesByBrandModel(brandModelUuid, catalogs)
      .then((res) => {
        dispatch({
          type: FINISH_TYPES.GET_FINISHES,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINISH_TYPES.LOAD_FINISH
        );
      });
  }
  function getFinish(uuid) {
    dispatch({ type: FINISH_TYPES.LOAD_FINISH, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    finishService
      .getFinish(uuid)
      .then((res) => {
        dispatch({
          type: FINISH_TYPES.GET_FINISH,
          payload: finishAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINISH_TYPES.LOAD_FINISH
        );
      });
  }

  function saveFinish(finishData) {
    if (finishData.uuid) {
      updateFinish(finishData);
    } else {
      createFinish(finishData);
    }
  }
  function createFinish(finishData) {
    dispatch({ type: FINISH_TYPES.LOAD_FINISH, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    finishService
      .createFinish(finishData)
      .then((res) => {
        dispatch({
          type: FINISH_TYPES.GET_FINISH,
          payload: finishAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          finishData.name + " a été correctement créé."
        );
        props.history.push("/finishes/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINISH_TYPES.LOAD_FINISH
        );
      });
  }
  function updateFinish(finishData) {
    dispatch({ type: FINISH_TYPES.LOAD_FINISH, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    finishService
      .updateFinish(finishData)
      .then((res) => {
        dispatch({
          type: FINISH_TYPES.GET_FINISH,
          payload: finishAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          finishData.name + " a été correctement mis à jour."
        );
        // if (finishData.finishes.length < res.data.finishes.length) {
        //   tools.filterError(
        //     dispatch,
        //     {
        //       other:
        //         "Une ou plusieurs finitions n'ont pas pu être supprimées car elles sont utilisées dans des véhicules de flotte ou catalogue.",
        //     },
        //     FINISH_TYPES.LOAD_FINISH
        //   );
        // }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINISH_TYPES.LOAD_FINISH
        );
      });
  }

  return {
    getFinishes,
    getFinish,
    saveFinish,
    getFinishesByBrandModel,
  };
};
