import React from "react";

const InputGroupWithResetValue = ({
  props,
  data,
  resetDefault,
  inputChange,
  error,
  disabled = false,
  noBtn = false,
}) => {
  if (data[props.name] === null) resetDefault(props.name, props.defaultValue);

  return (
    <>
      <div className="form-group">
        {props.label && (
          <label htmlFor={props.name}>
            {props.label}
            {props.required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            type="text"
            className={
              "form-control " +
              (props.inputClassName ? props.inputClassName : "")
            }
            name={props.name}
            id={props.name}
            value={data[props.name] || props.defaultValue}
            onChange={inputChange}
            aria-label={props.label}
            disabled={disabled ? "disabled" : false}
          />
          {props.symbol && (
            <div className="input-group-append">
              <span className="input-group-text">{props.symbol}</span>
              {props.defaultValue && (
                <button
                  className={"btn btn-outline-info " + (noBtn ? "d-none" : "")}
                  type="button"
                  onClick={() => resetDefault(props.name, props.defaultValue)}
                  disabled={disabled}
                >
                  D : {props.defaultValue}
                  {props.defaultValue > data[props.name] ? (
                    <i
                      style={{ color: "green" }}
                      className="fa fa-greater-than ml-2"
                    />
                  ) : props.defaultValue < data[props.name] ? (
                    <i
                      style={{ color: "red" }}
                      className="fa fa-less-than ml-2"
                    />
                  ) : (
                    <i
                      style={{ color: "grey" }}
                      className="fa fa-equals ml-2"
                    />
                  )}
                </button>
              )}
            </div>
          )}
        </div>
        <small className="form-text text-danger">{error}</small>
      </div>
    </>
  );
};

export default InputGroupWithResetValue;
