import React, { useState } from "react";
import useQuotationSearch from "./useQuotationSearch";
import Control from "../../../common/Control";
import AutocompleteInput from "../../../common/AutocompleteInput";
import { clientService } from "../../../../services/clientService";
import GroupSelector from "../../../common/GroupSelector";

let clientSearchTimeout = null;

const QuotationSearch = ({ handlePageParamsChange }) => {
  const { quotationSearch, actions } = useQuotationSearch({
    handlePageParamsChange,
  });
  const [clientSearchData, setClientSearchData] = useState([]);

  const onSearchStringChange = async (searchString) => {
    clearTimeout(clientSearchTimeout);
    clientSearchTimeout = setTimeout(async () => {
      await clientService
        .getClients(`?name=${searchString}`)
        .then((clients) => {
          setClientSearchData(clients?.data?.data || []);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }, 500);
  };

  return (
    <form onSubmit={actions.onSubmit} className="col-12 py-3">
      <div className="row">
        <div className="col-12">
          <GroupSelector
            label="Rechercher dans les groupes"
            name="userGroups"
            change={actions.onChangeSearch}
            value={quotationSearch.userGroups}
          />
        </div>

        <div className="col-12 col-md-6">
          {/* <Control
            label="Rechercher par client"
            name="clientName"
            change={actions.onChangeSearch}
            value={quotationSearch.clientName}
          /> */}
          <AutocompleteInput
            label="Rechercher par client"
            value={quotationSearch.client}
            change={actions.onChangeSearch}
            autoCompleteHasApiDatas
            onSearchStringChange={onSearchStringChange}
            name="client"
            dataIndex="uuid"
            dataLabel="name"
            dataLabel2="firstname"
            dataLabel3="lastname"
            datas={clientSearchData}
            autoCompleteDisplayIndex="name"
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Rechercher par référence"
            name="reference"
            change={actions.onChangeSearch}
            value={quotationSearch.reference}
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-default mr-2"
            onClick={actions.onResetSearch}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-primary" type="submit">
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default QuotationSearch;
