const brandModelAdapters = {
  transformForAPI: (data) => {
    return data;
  },
  transformForBO: (data) => {
    return {
      ...data,
      brand: data.brand.uuid,
    };
  },
};

export default brandModelAdapters;
