import axios from "axios";
import { api_url } from "../config/config";
import quotationAdapters from "../adapters/quotationAdapters";

export const quotationService = {
  getAllQuotations: () => {
    return axios.get(`${api_url}quotations/search?paginate=0`);
  },
  getValidQuotations: (search) => {
    return axios.get(`${api_url}quotations/valid${search}`);
  },
  getQuotations: (search) => {
    return axios.get(`${api_url}quotations/search${search}`);
  },
  getClientQuotations: (clientUuid) => {
    return axios.get(
      `${api_url}quotations/search?paginate=0&client=${clientUuid}`
    );
  },
  getQuotation: (uuid) => {
    return axios.get(`${api_url}quotations/${uuid}`);
  },
  createQuotation: (data) => {
    return axios.post(
      `${api_url}quotations`,
      quotationAdapters.transformForAPI(data)
    );
  },
  updateQuotation: (data) => {
    return axios.put(
      `${api_url}quotations/${data.uuid}`,
      quotationAdapters.transformForAPI(data)
    );
  },
  updateQuotationStatus: (quotationUuid, rlId, status, quantity, caracs) => {
    return axios.patch(
      `${api_url}quotations/${quotationUuid}/quotation/${rlId}/update-status`,
      { quantity, caracs, status }
    );
  },
};
