export const CATALOG_STORE_TYPES = {
  LOAD_CATALOG_STORE: "LOAD_CATALOG_STORE",
  GET_CATALOG_STORES: "GET_CATALOG_STORES",
  GET_CATALOG_STORE: "GET_CATALOG_STORE",
  UPDATE_CATALOG_STORE_DATA: "UPDATE_CATALOG_STORE_DATA",
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    name: "",
    isActive: true,
    geographicZone: "",
    updatedByApi: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATALOG_STORE_TYPES.LOAD_CATALOG_STORE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CATALOG_STORE_TYPES.GET_CATALOG_STORES:
      return {
        ...state,
        items: action.payload.data,
        //item: initialState.item,
        total: action.payload.meta.total,
        isLoading: false,
      };

    case CATALOG_STORE_TYPES.GET_CATALOG_STORE:
      return {
        ...state,
        item: action.payload,
        // items: initialState.items,
        isLoading: false,
      };

    case CATALOG_STORE_TYPES.UPDATE_CATALOG_STORE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false,
      };

    default:
      return state;
  }
}
