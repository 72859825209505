import axios from "axios";
import { api_url } from "../config/config";
import brandModelAdapters from "../adapters/brandModelAdapters";

export const brandModelService = {
  getAllBrandModels: () => {
    return axios.get(`${api_url}brand-models/search?paginate=0`);
  },
  getBrandModels: (search) => {
    return axios.get(`${api_url}brand-models/search${search}`);
  },
  getBrandModelByBrand: (brandUuid) => {
    return axios.get(
      `${api_url}brand-models/search?paginate=0&brand=${brandUuid}`
    );
  },
  getBrandModel: (uuid) => {
    return axios.get(`${api_url}brand-models/${uuid}`);
  },
  createBrandModel: (data) => {
    return axios.post(
      `${api_url}brand-models`,
      brandModelAdapters.transformForAPI(data)
    );
  },
  updateBrandModel: (data) => {
    return axios.put(
      `${api_url}brand-models/${data.uuid}`,
      brandModelAdapters.transformForAPI(data)
    );
  },
};
