import { toast } from "react-toastify";

var vehicleTools = {
  calcHTFromTTC: (vehicle, TVA_INVERT) => {
    let vehicleType = "";
    if (typeof vehicle.type == "number" || typeof vehicle.type == "string") {
      vehicleType = vehicle.type;
    }
    if (typeof vehicle.type == "object") {
      vehicleType = vehicle.type.id;
    }
    var priceTTC = parseFloat(vehicle.priceTTC);

    var tvaInvert = vehicleType == 2 ? 1 : TVA_INVERT;
    return Math.round((priceTTC / tvaInvert) * 100) / 100;
  },
  calcTTCFromHT: (vehicle, TVA) => {
    let vehicleType = "";
    if (typeof vehicle.type == "number" || typeof vehicle.type == "string") {
      vehicleType = vehicle.type;
    }
    if (typeof vehicle.type == "object") {
      vehicleType = vehicle.type.id;
    }
    var priceHT = parseFloat(vehicle.priceHT);
    var tva = vehicleType == 2 ? 0 : TVA;
    return Math.round((priceHT + priceHT * tva) * 100) / 100;
  },
  calcVehiclePrices: (vehicle, TVA, TVA_INVERT, isDiscountTTC = false) => {
    let vehicleType = "";
    if (typeof vehicle.type == "number" || typeof vehicle.type == "string") {
      vehicleType = vehicle.type;
    }
    if (typeof vehicle.type == "object") {
      vehicleType = vehicle.type.id;
    }
    var priceHT = parseFloat(vehicle.priceHT);
    var tva = vehicleType == 2 ? 0 : TVA;
    var tvaInvert = vehicleType == 2 ? 1 : TVA_INVERT;

    let discount = isDiscountTTC
      ? vehicle.discount / tvaInvert
      : vehicle.discount;

    var discountEur = parseFloat(discount) > 0 ? parseFloat(discount) : 0;
    var discountPct = Math.round((discountEur / priceHT) * 100 * 100) / 100;

    priceHT = priceHT - discountEur;

    var priceTTC = priceHT + priceHT * tva;

    var optionsCostTTC = 0;
    var optionsCostHT = 0;
    vehicle.optionsNotIncluded.forEach((option) => {
      var price = option.priceHT == "" ? 0 : parseFloat(option.priceHT);
      optionsCostTTC +=
        parseFloat(price) + parseFloat(price) * (option.isInclTVA ? TVA : 0);
      optionsCostHT += parseFloat(price);
    });

    var inroadCostHT =
      parseFloat(vehicle.inroadCost) > 0 ? parseFloat(vehicle.inroadCost) : 0;
    var inroadCostTTC = inroadCostHT + inroadCostHT * TVA;
    var greyCardCost =
      parseFloat(vehicle.greyCardCost) > 0
        ? parseFloat(vehicle.greyCardCost)
        : 0;
    var malusBonusEco = isNaN(parseFloat(vehicle.malusBonusEco))
      ? 0
      : parseFloat(vehicle.malusBonusEco);

    var priceTotalTTC =
      priceTTC + optionsCostTTC + inroadCostTTC + greyCardCost + malusBonusEco;
    var priceTotalHT =
      priceHT + optionsCostHT + inroadCostHT + greyCardCost + malusBonusEco;

    return {
      priceTTC,
      priceTotalHT,
      priceTotalTTC,
      discountPct,
      priceHT,
    };
  },
  calcMarOpt: (contract, TVA) => {
    // let vehicleType = "";
    // if (typeof contract.vehicle.type == "number" || typeof contract.vehicle.type == "string") {
    //   vehicleType = contract.vehicle.type;
    // }
    // if (typeof contract.vehicle.type == "object") {
    //   vehicleType = contract.vehicle.type.id;
    // }
    // var tva = vehicleType == 2 ? 0 : TVA;
    var optionsCost = 0;
    contract.vehicle.optionsNotIncluded.forEach((option) => {
      var price = option.priceHT == "" ? 0 : parseFloat(option.priceHT);
      optionsCost +=
        parseFloat(price) + parseFloat(price) * (option.isInclTVA ? TVA : 0);
    });
    var inroadCostTTC = parseFloat(contract.vehicle.inroadCost);

    return Math.round((optionsCost + inroadCostTTC) * 100) / 100;
  },
  calcTvaMarOpt: (contract, TVA, TVA_INVERT) => {
    // var tva = contract.vehicle.type == 2 ? 0 : TVA;
    var optionsCost = 0;
    var sumTva = 0;
    contract.vehicle.optionsNotIncluded.forEach((option) => {
      var price = option.priceHT == "" ? 0 : parseFloat(option.priceHT);
      optionsCost +=
        parseFloat(price) + parseFloat(price) * (option.isInclTVA ? TVA : 0);
      sumTva += parseFloat(price) * (option.isInclTVA ? TVA : 0);
    });
    var inroadCostTTC =
      Math.round(parseFloat(contract.vehicle.inroadCost) * TVA_INVERT * 100) /
      100;
    var inroadCostHT = contract.vehicle.inroadCost;

    sumTva += inroadCostTTC - inroadCostHT;

    return Math.round(sumTva * 100) / 100;
  },
  calcOptHT: (contract, TVA) => {
    // var tva = contract.vehicle.type == 2 ? 0 : TVA;
    var optionsCost = 0;
    contract.vehicle.optionsNotIncluded.forEach((option) => {
      var price = option.priceHT == "" ? 0 : parseFloat(option.priceHT);
      optionsCost += parseFloat(price);
    });
    var inroadCostTTC = parseFloat(contract.vehicle.inroadCost);

    return Math.round(optionsCost * 100) / 100;
  },
  calcMarHT: (contract, TVA_INVERT) => {
    var inroadCostHT =
      Math.round((parseFloat(contract.vehicle.inroadCost) / TVA_INVERT) * 100) /
      100;
    return inroadCostHT;
  },
  calcPriceTTC: (priceHT, TVA, type = 1) => {
    if (type == 2) return parseFloat(priceHT);
    priceHT = priceHT == "" ? 0 : parseFloat(priceHT);
    var res = Math.round((priceHT + priceHT * TVA) * 100) / 100;
    if (isNaN(res)) return 0;
    else return res;
  },
  calcPriceHT: (priceTTC, TVA_INVERT) => {
    return Math.round((priceTTC / TVA_INVERT) * 100) / 100;
  },

  calcPct: (amt, pct) => {
    var res = (parseFloat(amt) * parseFloat(pct)) / 100;
    res = Math.round(res * 100) / 100;
    if (isNaN(res)) return 0;
    else return res > 0 ? res : "";
  },
  calcInvestAmtHT: (contract, TVA, TVA_INVERT) => {
    // Prix d'achat HT tout compris (options + MAR + BM + CG)
    // on doit recalculer ici le discount en HT. Le discount du vehicule depuis le devis est exprimé en TTC
    let prices = vehicleTools.calcVehiclePrices(
      contract.vehicle,
      TVA,
      TVA_INVERT,
      true
    );

    let res = prices.priceTotalHT;
    if (isNaN(res)) return 0;
    else return Math.round(res * 100) / 100;
  },
  getRateVR: (duration, mileage, repurchase, num = null) => {
    var kmkey = 0;
    var durkey = repurchase.dur[0];
    var kmchoice = "";
    for (var key in repurchase.km) {
      var km = parseInt(repurchase.km[key]);
      if (parseInt(mileage) >= km) {
        kmkey = key;
        kmchoice = km;
      }
    }
    for (var key in repurchase.dur) {
      var dur = parseInt(repurchase.dur[key]);
      if (parseInt(duration) >= dur) {
        durkey = dur;
      }
    }
    var res = 0;
    var info = "VR choisie : " + durkey + "mois - " + kmchoice + " km";

    if (durkey && kmkey) {
      res = parseInt(repurchase.values[durkey][kmkey]);
    }

    if (isNaN(res)) return { value: 0, info };
    else return { value: Math.round(res * 100) / 100, info };
  },
  getRateMaintenance: (duration, mileage, maintenance, num = null) => {
    if (!maintenance) {
      return { value: 0, info: "La maintenance n'a pas été trouvée !" };
    }
    var kmkey = 0;
    var durkey = maintenance.dur[0];
    var kmchoice = "";
    for (var key in maintenance.km) {
      var km = parseInt(maintenance.km[key]);
      if (parseInt(mileage) >= km) {
        kmkey = key;
        kmchoice = km;
      }
    }
    for (var key in maintenance.dur) {
      var dur = parseInt(maintenance.dur[key]);
      if (parseInt(duration) >= dur) {
        durkey = dur;
      }
    }

    var info = `Maintenance choisie : ${durkey} mois - ${kmchoice} km`;

    if (
      !maintenance.values ||
      !maintenance.values[durkey] ||
      !maintenance.values[durkey][kmkey]
    ) {
      return { value: 0, info: "La maintenance n'a pas été trouvée" };
    }
    var res = parseInt(maintenance.values[durkey][kmkey]) / duration;
    if (isNaN(res)) return { value: 0, info };
    else return { value: Math.round(res * 100) / 100, info };
  },
  calcFinanceRent(q, TVA_INVERT) {
    let vehicleType = "";
    if (
      typeof q.vehicle.type == "number" ||
      typeof q.vehicle.type == "string"
    ) {
      vehicleType = q.vehicle.type;
    }
    if (typeof q.vehicle.type == "object") {
      vehicleType = q.vehicle.type.id;
    }

    var AA4 = vehicleTools.calcInvestAmtHT(
      q,
      Math.round((TVA_INVERT - 1) * 1000) / 1000,
      TVA_INVERT
    );
    var AE4 = parseFloat(q.repurchaseValueFinal);
    var AI4 = parseFloat(q.firstRentInput);
    var AH4 = parseFloat(q.financeRate) / 100;
    var M4 = parseFloat(q.dur);
    var AJ4 = parseFloat(q.applicationFee);

    if (vehicleType == 2) {
      var res =
        ((AA4 - AE4 - AI4 / TVA_INVERT) * (AH4 / 12)) /
          (1 - 1 / Math.pow(1 + AH4 / 12, M4)) +
        (AE4 * AH4) / 12 +
        AJ4 / M4;
    } else {
      var res =
        ((AA4 - AE4 / TVA_INVERT - AI4 / TVA_INVERT) * (AH4 / 12)) /
          (1 - 1 / Math.pow(1 + AH4 / 12, M4)) +
        ((AE4 / TVA_INVERT) * AH4) / 12 +
        AJ4 / M4;
    }

    if (isNaN(res)) {
      return 0;
    } else {
      return Math.round(res * 100) / 100;
    }
  },
  calcApplicationFee(investAmtHT, pct) {
    return (
      Math.round(((parseFloat(investAmtHT) * parseFloat(pct)) / 100) * 100) /
      100
    );
  },
  calcMargin(investAmtHT, margin) {
    return (
      Math.round(((parseFloat(investAmtHT) * parseFloat(margin)) / 100) * 100) /
      100
    );
  },
  calcMarginRate(investAmtHT, margin) {
    return (
      Math.round((parseFloat(margin) / parseFloat(investAmtHT)) * 100 * 100) /
      100
    );
  },
  calcInsuranceHT(q) {
    var res = 0;

    var AO4 = parseFloat(q.marginTTC);
    var AN4 = AO4;
    var AK4 = parseFloat(q.financeRent);
    var AP4 = AO4 + AK4;

    var res =
      ((AP4 - AN4) * 0.0442 +
        (AP4 - AN4) * 0.0442 * 0.0927 +
        4 * 1.2533 +
        0.12 * 30 * 1.2533) *
      2.125;

    if (isNaN(res)) {
      return 0;
    } else {
      return Math.round(res * 100) / 100;
    }
  },
  calcInsuranceFinLoss(q) {
    var X3 = q.vehicle.priceNetTTC;
    var res = (X3 * 0.00696) / 12;
    if (isNaN(res)) {
      return 0;
    } else {
      return Math.round(res * 100) / 100;
    }
  },
  calcTotalRentHTCalc(q) {
    var res =
      parseFloat(q.financeRent) +
      parseFloat(q.marginTTC) +
      parseFloat(q.insuranceHT);
    res += parseFloat(q.maintenanceValueFinal);
    res += parseFloat(q.replacementVehicleCost);
    res += parseFloat(q.insuranceFinLoss);

    q.benefits.forEach((b) => {
      if (parseFloat(b.priceHT) > 0) res += parseFloat(b.priceHT);
    });

    if (isNaN(res)) {
      return 0;
    } else {
      return Math.round(res * 100) / 100;
    }
  },
};

export default vehicleTools;
