import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { BRANDMODEL_TYPES } from "../reducers/brandModelReducers";
import brandModelAdapters from "../../adapters/brandModelAdapters";
import { brandModelService } from "../../services/brandModelService";

export const brandModelActions = (state, dispatch, props) => {
  function getAllBrandModels() {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandModelService
      .getAllBrandModels()
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODELS,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function getBrandModels(search = { search: null }) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = tools.getSearchString({
      name: search.name,
      brand: search.brand,
      page: search.page,
    });

    brandModelService
      .getBrandModels(search)
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODELS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function getBrandModel(uuid) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandModelService
      .getBrandModel(uuid)
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: brandModelAdapters.transformForBO(res.data),
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function getBrandModelByBrand(uuid) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandModelService
      .getBrandModelByBrand(uuid)
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODELS,
          payload: { data: res.data, meta: { total: res.data.length } },
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function saveBrandModel(brandModelData) {
    if (brandModelData.uuid) {
      updateBrandModel(brandModelData);
    } else {
      createBrandModel(brandModelData);
    }
  }
  function createBrandModel(brandModelData) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandModelService
      .createBrandModel(brandModelData)
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: brandModelAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          brandModelData.name + " a été correctement créé."
        );
        props.history.push("/brandmodels/" + res.data.uuid);
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function updateBrandModel(brandModelData) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    brandModelService
      .updateBrandModel(brandModelData)
      .then((res) => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: brandModelAdapters.transformForBO(res.data),
        });
        tools.filterAlert(
          "success",
          brandModelData.name + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }

  return {
    getAllBrandModels,
    saveBrandModel,
    createBrandModel,
    updateBrandModel,
    getBrandModels,
    getBrandModel,
    getBrandModelByBrand,
  };
};
