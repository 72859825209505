import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import Loader from "../../Loader";
import Control from "../../Control";

const FinishForm = ({ finishInitialData, onSaveFinish }) => {
  const [state] = useStore();

  const [finishData, setFinishData] = useState({
    catalog: "",
    name: "",
    fuelType: "",
    ...finishInitialData,
  });

  const inputChange = (e) => {
    setFinishData({ ...finishData, [e.target.name]: e.target.value });
  };

  const onSave = () => {
    onSaveFinish(finishData);
    if (!finishData.uuid) {
      setFinishData({
        name: "",
        fuelType: "",
        catalog: "",
        ...finishInitialData,
      });
    }
  };

  return (
    <div className="row border-bottom mb-2">
      <div className="col-12 col-md-3">
        <Control
          label="Catalogue"
          name="catalog"
          id="catalog"
          type="select"
          datas={state.catalogStore.items}
          dataIndex="uuid"
          dataLabel="name"
          change={inputChange}
          value={finishData.catalog}
          error={state.error}
        />
      </div>
      <div className="col-12 col-md-3">
        <Control
          label="Finition"
          name="name"
          id="name"
          change={inputChange}
          value={finishData.name}
          error={state.error}
        />
      </div>
      <div className="col-12 col-md-3">
        <Control
          label="Carburant"
          name="fuelType"
          id="fuelType"
          type="select"
          datas={state.constant.items.FUEL_TYPES}
          dataIndex="id"
          dataLabel="name"
          change={inputChange}
          value={finishData.fuelType}
          error={state.error}
        />
      </div>

      <div className="col-12 col-md-3 d-flex">
        {state.finish.isLoading ? (
          <Loader />
        ) : (
          <button type="button" className="btn btn-success" onClick={onSave}>
            <i className="fas fa-save"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default FinishForm;
