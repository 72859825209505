import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { groupActions } from "../../../context/actions/groupActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";
import NbSearchResults from "../../common/NbSearchResults";
import Can from "../../common/Can/Can";
import { userAccessEnum } from "../../../config/accessEnum";

const GroupList = (props) => {
  const [state, dispatch] = useStore();
  var actions = groupActions(state, dispatch);

  return (
    <CustomList getAction={actions.getGroups}>
      {(activePage, handlePageParamsChange, search) => {
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Groupes d'utilisateurs</h1>
                {state.group.isLoading ? (
                  <Loader />
                ) : (
                  <Can I={userAccessEnum.CREER_GROUPE_UTILISATEUR}>
                    <Link className="btn btn-primary" to="/groups/create">
                      <i className="fa fa-plus mr-2" /> Ajouter un groupe
                    </Link>
                  </Can>
                )}
                <CustomSearch
                  search={search}
                  handlePageParamsChange={handlePageParamsChange}
                />
                <NbSearchResults total={state.group.total} />

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Catalogues</th>
                      <th>Est admin ?</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.group.items.map((group, k) => (
                      <tr key={"group-row-" + k}>
                        <td>{group.name}</td>
                        <td>
                          {group.catalogs.map((c) => (
                            <div className="badge badge-primary mr-1">
                              {c.name}
                            </div>
                          ))}
                        </td>
                        <td>
                          <div
                            className={`badge badge-${
                              group.isAdmin ? "success" : "secondary"
                            }`}
                          >
                            {group.isAdmin ? "Oui" : "Non"}
                          </div>
                        </td>

                        <td>
                          <Can I={userAccessEnum.MODIFIER_GROUPE_UTILISATEUR}>
                            <Link
                              className="btn btn-secondary"
                              to={"/groups/" + group.uuid}
                            >
                              Modifier
                            </Link>
                          </Can>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <CustomPagination
                  total={state.group.total}
                  activePage={activePage}
                  handlePageParamsChange={handlePageParamsChange}
                />
              </div>
            </div>
          </div>
        );
      }}
    </CustomList>
  );
};

export default GroupList;
